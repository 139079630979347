import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";

export const validDocumentsApi = createApi({
  reducerPath: "validDocumentsApi",
  baseQuery,
  endpoints: (builder) => ({
    getValidDocuments: builder.query({
      query: () => "validation-documents",
    }),
    register: builder.mutation({
      query: (validDocumentsData) => ({
        url: "validation-documents",
        method: "POST",
        body: validDocumentsData,
      }),
    }),
    update: builder.mutation({
      query: ({ id, validDocumentsData }) => ({
        url: `validation-documents/${id}`,
        method: "PUT",
        body: validDocumentsData,
      }),
    }),
  }),
});

export const { useGetValidDocumentsQuery, useRegisterMutation, useUpdateMutation  } = validDocumentsApi;
