import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

export const fetchNotifications = createAsyncThunk('notifications/fetchNotifications', async () => {
  try {
    const token = Cookies.get('token');

    const [notificationResponse, userSavedNotificationResponse] = await Promise.all([
      fetch(process.env.REACT_APP_BASE_URL + 'notifications/relations', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
      fetch(process.env.REACT_APP_BASE_URL + 'notifications/user-notification-preferences', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    ]);

    const notifications = await notificationResponse.json();
    const notificationsUser = await userSavedNotificationResponse.json();
    const isNotificationEmpty =
      notificationsUser.machines.length === 0 &&
      notificationsUser.machineGroups.length === 0 &&
      notificationsUser.locations.length === 0 &&
      notificationsUser.maintenanceTypes.length === 0;

    return {
      filters: {
        machines: notifications.machines.map((machine) => ({
          id: +machine.id,
          name: `${machine.name} ${machine.number}`,
        })),
        machineGroups: notifications.machineGroups.map((group) => ({
          id: +group.id,
          name: group.name,
        })),
        locations: notifications.locations.map((location) => ({
          id: +location.id,
          name: location.name,
        })),
        maintenanceTypes: notifications.maintenanceTypes.map((type) => ({
          id: +type.id,
          name: type.name,
        })),
      },
      selectedFilters: {
        machines: isNotificationEmpty
          ? notifications.machines.map((machine) => +machine.id)
          : notificationsUser.machines.map((machine) => +machine.machine.id),
        machineGroups: isNotificationEmpty
          ? notifications.machineGroups.map((group) => +group.id)
          : notificationsUser.machineGroups.map((group) => +group.machine_group.id),
        locations: isNotificationEmpty
          ? notifications.locations.map((location) => +location.id)
          : notificationsUser.locations.map((location) => +location.location.id),
        maintenanceTypes: isNotificationEmpty
          ? notifications.maintenanceTypes.map((type) => +type.id)
          : notificationsUser.maintenanceTypes.map((type) => +type.maintenance_type.id),
      },
      rawMachines: notifications.machines.map((machine) => ({
        ...machine,
        locationIds: machine.locationIds.map(Number),
        machineGroupIds: machine.machineGroupIds.map(Number),
        machineIds: machine.machineIds.map(Number),
        maintenanceTypeIds: machine.maintenanceTypeIds.map(Number),
      })),
      enabled: notificationsUser.notify.enabled,
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const saveNotifications = createAsyncThunk(
  'notifications/saveNotifications',
  async (_, { getState }) => {
    try {
      const data = getState().notifications.selectedFilters;
      const enabled = getState().notifications.enabled;
      const token = Cookies.get('token');
      await fetch(process.env.REACT_APP_BASE_URL + `notifications`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...data, enabled }),
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

const initialState = {
  filters: {
    machines: [],
    locations: [],
    machineGroups: [],
    maintenanceTypes: [],
  },
  selectedFilters: {
    machines: [],
    locations: [],
    machineGroups: [],
    maintenanceTypes: [],
  },
  activeFilters: {
    machines: [],
    locations: [],
    machineGroups: [],
    maintenanceTypes: [],
  },
  rawMachines: [],
  filteredMachines: [],
  isLoading: false,
  error: null,
  enabled: false,
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    resetNotifications: () => JSON.parse(JSON.stringify(initialState)),
    filterMachines: (state) => {
      state.filteredMachines = state.rawMachines
        .filter((machine) =>
          state.selectedFilters.locations.some((location) => machine.locationIds.includes(location))
        )
        .filter((machine) =>
          state.selectedFilters.machineGroups.some((group) =>
            machine.machineGroupIds.includes(group)
          )
        )
        .filter((machine) =>
          state.selectedFilters.maintenanceTypes.some((type) =>
            machine.maintenanceTypeIds.includes(type)
          )
        );

      // const greyMachines = state.rawMachines
      //   .filter(
      //     (machine) =>
      //       !state.selectedFilters.locations.some((location) =>
      //         machine.locationIds.includes(location)
      //       )
      //   )
      //   .filter(
      //     (machine) =>
      //       !state.selectedFilters.machineGroups.some((group) =>
      //         machine.machineGroupIds.includes(group)
      //       )
      //   )
      //   .filter(
      //     (machine) =>
      //       !state.selectedFilters.maintenanceTypes.some((type) =>
      //         machine.maintenanceTypeIds.includes(type)
      //       )
      //   )
      //   .filter(
      //     (machine) => !state.selectedFilters.machines.includes(+machine.id)
      //   );
      // state.filteredMachines = state.rawMachines.filter(
      //   (machine) =>
      //     !greyMachines.some((greyMachine) => greyMachine.id === machine.id)
      // );
      state.activeFilters.machines = [
        ...new Set(state.filteredMachines.map((machine) => +machine.id)),
      ];

      state.filteredMachines = state.filteredMachines.filter((machine) =>
        state.selectedFilters.machines.includes(+machine.id)
      );

      state.activeFilters.machineGroups = [
        ...new Set(state.filteredMachines.flatMap((machine) => machine.machineGroupIds)),
      ];
      state.activeFilters.locations = [
        ...new Set(state.filteredMachines.flatMap((machine) => machine.locationIds)),
      ];
      state.activeFilters.maintenanceTypes = [
        ...new Set(state.filteredMachines.flatMap((machine) => machine.maintenanceTypeIds)),
      ];
    },
    toggleMachine: (state, action) => {
      if (state.selectedFilters.machines.includes(action.payload)) {
        state.selectedFilters.machines = state.selectedFilters.machines.filter(
          (id) => id !== action.payload
        );
      } else {
        state.selectedFilters.machines = [...state.selectedFilters.machines, action.payload];
      }
    },
    toggleGroup: (state, action) => {
      if (state.selectedFilters.machineGroups.includes(action.payload)) {
        state.selectedFilters.machineGroups = state.selectedFilters.machineGroups.filter(
          (id) => id !== action.payload
        );
      } else {
        state.selectedFilters.machineGroups = [
          ...state.selectedFilters.machineGroups,
          action.payload,
        ];
      }
    },
    toggleLocation: (state, action) => {
      if (state.selectedFilters.locations.includes(action.payload)) {
        state.selectedFilters.locations = state.selectedFilters.locations.filter(
          (id) => id !== action.payload
        );
      } else {
        state.selectedFilters.locations = [...state.selectedFilters.locations, action.payload];
      }
    },
    toggleType: (state, action) => {
      if (state.selectedFilters.maintenanceTypes.includes(action.payload)) {
        state.selectedFilters.maintenanceTypes = state.selectedFilters.maintenanceTypes.filter(
          (id) => id !== action.payload
        );
      } else {
        state.selectedFilters.maintenanceTypes = [
          ...state.selectedFilters.maintenanceTypes,
          action.payload,
        ];
      }
    },
    toggleEnabled: (state) => {
      state.enabled = !state.enabled;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNotifications.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchNotifications.fulfilled, (state, action) => {
      state.isLoading = false;
      state.filters = action.payload.filters;
      state.rawMachines = action.payload.rawMachines;
      state.selectedFilters = action.payload.selectedFilters;
      state.error = null;
      state.enabled = action.payload.enabled;
    });
    builder.addCase(fetchNotifications.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export const {
  resetNotifications,
  filterMachines,
  toggleGroup,
  toggleLocation,
  toggleMachine,
  toggleType,
  toggleEnabled,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;

export const selectNotifications = (state) => state.notifications.notifications;
