import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

export const deleteManual = createAsyncThunk('manual/deleteManual', async (id) => {
  try {
    const token = Cookies.get('token');
    await fetch(process.env.REACT_APP_BASE_URL + `manuals/${id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
});

const manualsSlice = createSlice({
  name: 'manuals',
  initialState: {
    manuals: [],
    isLoading: false,
    error: null,
  },
  reducers: {
    removeManuals: (state) => {
      state.manuals = null;
      state.isLoading = false;
      state.error = null;
    },
  },
});

export const { removeManuals } = manualsSlice.actions;

export default manualsSlice.reducer;

export const selectManuals = (state) => state.manuals.manuals;
