import { useState } from "react";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { useTranslation } from "react-i18next";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ACTIONS } from "../../constants/actionTables";

import { Button, Typography } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

import styles from "./styles.module.css";

export default function ModalEditor({ content, setContent, type }) {
  const [open, setOpen] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const { t } = useTranslation();

  const handleOpen = () => {
    if (content) {
      const contentObject = JSON.parse(content);
      const editorStateFromContent = EditorState.createWithContent(
        convertFromRaw(contentObject)
      );
      setEditorState(editorStateFromContent);
    } else {
      setEditorState(EditorState.createEmpty());
    }
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleSubmit = async () => {
    try {
      const contentState = editorState.getCurrentContent();
      const contentRaw = convertToRaw(contentState);
      const contentRawString = JSON.stringify(contentRaw);
      setContent(contentRawString);
      setOpen(false);
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  const uploadImageCallBack = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve({ data: { link: reader.result } });
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  return (
    <div>
      <Button
        variant="outlined"
        fullWidth
        onClick={handleOpen}
        sx={{
          color: "rgba(0, 0, 0, 0.6)",
          borderColor: "rgba(0, 0, 0, 0.3)",
          fontSize: "16px",
          height: "56px",
          textTransform: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          "&:hover": {
            borderColor: "rgba(0, 0, 0, 1)",
            background: "none",
          },
        }}
      >
        {type === ACTIONS.VIEW
          ? t("generic.view")
          : content
          ? t("generic.edit")
          : t("modals.add")}{" "}
        {t("generic.description")}
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 1500,
        }}
      >
        <Fade in={open}>
          <div className={styles.modal}>
            <div className={styles.container}>
              <Typography variant="h5" fontWeight="bold">
                {t("generic.description")}
              </Typography>

              <div className={styles.editor}>
                <Editor
                  editorState={editorState}
                  toolbarClassName={styles.toolbar}
                  wrapperClassName={styles.wrapper}
                  editorClassName="editorClassName"
                  onEditorStateChange={setEditorState}
                  toolbar={{
                    options: [
                      "inline",
                      "fontSize",
                      "list",
                      "textAlign",
                      "colorPicker",
                      "emoji",
                      "image",
                      "history",
                    ],
                    inline: {
                      options: ["bold", "italic", "underline"],
                    },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    image: {
                      uploadEnabled: true,
                      urlEnabled: false,
                      uploadCallback: uploadImageCallBack,
                      previewImage: true,
                      alt: { present: false, mandatory: false },
                    },
                  }}
                />
              </div>

              <div className={styles.button_group}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleClose}
                  style={{ padding: "5px 22px" }}
                >
                  Cancel
                </Button>
                {type === ACTIONS.EDIT && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    style={{ padding: "5px 22px" }}
                  >
                    Save
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
