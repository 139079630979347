// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__7KGQE {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 96px;
  width: 100%;
  padding: 28px 50px;
  box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.04);

  @media (max-width: 1024px) {
    padding: 10px;
  }
}

.style_logoLink__eWpt9 {
  color: unset;
  text-decoration: none;
  @media (max-width: 1024px) {
    display: none;
  }
}

.style_logo__g5xO1 {
  display: block;
}

.style_navigation__ADl8v {
  display: flex;
  align-items: center;
  gap: 50px;

  @media (max-width: 1024px) {
    gap: 0px;
    width: 100%;
    justify-content: space-between;
  }
}

.style_link__YfzqY {
  text-decoration: none;
  font-size: 20px;
  font-weight: 400;
  color: black;
}

.style_machines_button__bqCik {
  background: none;
  padding: 0;
  font-size: 20px;
  font-family: inherit;
  border: none;
  cursor: pointer;
}

.style_avatar_button__y72c0 {
  background: none;
  padding: 0;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}

.style_menu__bcWmk {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
}

.style_link_menu__AVRTB {
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  color: black;
  padding: 0 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Header/style.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,+CAA+C;;EAE/C;IACE,aAAa;EACf;AACF;;AAEA;EACE,YAAY;EACZ,qBAAqB;EACrB;IACE,aAAa;EACf;AACF;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;;EAET;IACE,QAAQ;IACR,WAAW;IACX,8BAA8B;EAChC;AACF;;AAEA;EACE,qBAAqB;EACrB,eAAe;EACf,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,UAAU;EACV,eAAe;EACf,oBAAoB;EACpB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,UAAU;EACV,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,SAAS;AACX;;AAEA;EACE,qBAAqB;EACrB,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".container {\n  box-sizing: border-box;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  height: 96px;\n  width: 100%;\n  padding: 28px 50px;\n  box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.04);\n\n  @media (max-width: 1024px) {\n    padding: 10px;\n  }\n}\n\n.logoLink {\n  color: unset;\n  text-decoration: none;\n  @media (max-width: 1024px) {\n    display: none;\n  }\n}\n\n.logo {\n  display: block;\n}\n\n.navigation {\n  display: flex;\n  align-items: center;\n  gap: 50px;\n\n  @media (max-width: 1024px) {\n    gap: 0px;\n    width: 100%;\n    justify-content: space-between;\n  }\n}\n\n.link {\n  text-decoration: none;\n  font-size: 20px;\n  font-weight: 400;\n  color: black;\n}\n\n.machines_button {\n  background: none;\n  padding: 0;\n  font-size: 20px;\n  font-family: inherit;\n  border: none;\n  cursor: pointer;\n}\n\n.avatar_button {\n  background: none;\n  padding: 0;\n  border: none;\n  border-radius: 50%;\n  cursor: pointer;\n}\n\n.menu {\n  display: flex;\n  flex-direction: column;\n  padding: 10px;\n  gap: 10px;\n}\n\n.link_menu {\n  text-decoration: none;\n  font-size: 16px;\n  font-weight: 400;\n  color: black;\n  padding: 0 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__7KGQE`,
	"logoLink": `style_logoLink__eWpt9`,
	"logo": `style_logo__g5xO1`,
	"navigation": `style_navigation__ADl8v`,
	"link": `style_link__YfzqY`,
	"machines_button": `style_machines_button__bqCik`,
	"avatar_button": `style_avatar_button__y72c0`,
	"menu": `style_menu__bcWmk`,
	"link_menu": `style_link_menu__AVRTB`
};
export default ___CSS_LOADER_EXPORT___;
