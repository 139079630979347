import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

export const fetchSupplies = createAsyncThunk('supplies/fetchSupplies', async () => {
  try {
    const token = Cookies.get('token');
    const response = await fetch(process.env.REACT_APP_BASE_URL + 'supplies', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const fetchSupplie = createAsyncThunk('supplie/fetchSupplie', async (id) => {
  try {
    const token = Cookies.get('token');
    const response = await fetch(process.env.REACT_APP_BASE_URL + `supplies/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});
export const deleteSupplie = createAsyncThunk('supplie/deleteSupplie', async (id) => {
  try {
    const token = Cookies.get('token');
    await fetch(process.env.REACT_APP_BASE_URL + `supplies/${id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
});

const suppliesSlice = createSlice({
  name: 'supplies',
  initialState: {
    supplies: [],
    isLoading: false,
    error: null,
  },
  reducers: {
    removeSupplies: (state) => {
      state.supplies = null;
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSupplies.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchSupplies.fulfilled, (state, action) => {
      state.isLoading = false;
      state.supplies = action.payload;
      state.error = null;
    });
    builder.addCase(fetchSupplies.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export const { removeSupplies } = suppliesSlice.actions;

export default suppliesSlice.reducer;

export const selectSupplies = (state) => state.supplies.supplies;
