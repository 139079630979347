import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import {
  fetchNotifications,
  filterMachines,
  saveNotifications,
  toggleEnabled,
  toggleGroup,
  toggleLocation,
  toggleMachine,
  toggleType,
} from '../../features/notifications/notificationsSlice';
import MultiSelectNotify from '../../components/MultiSelectNotify';

import { Button, FormControlLabel, Switch, Typography, Box, Tooltip } from '@mui/material';

import styles from './style.module.css';

function EquipmentInfoTitle({ title }) {
  return (
    <Typography sx={{ md: '8px', fontWeight: 600 }} variant="subtitle1">
      {title}
    </Typography>
  );
}

function UserProfilNotification() {
  const [isInputsDisabled, setIsInputsDisabled] = useState(false);
  const [isNotificationEnabled, setIsNotificationEnabled] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notifications);

  useEffect(() => {
    dispatch(fetchNotifications());
  }, []);

  useEffect(() => {
    setIsNotificationEnabled(notifications.enabled);
  }, [notifications]);

  useEffect(() => {
    dispatch(filterMachines());
  }, [notifications.selectedFilters]);

  const handleSubmit = async () => {
    try {
      await dispatch(saveNotifications());

      setIsInputsDisabled(false);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const handleCancel = () => {
    setIsInputsDisabled(false);
  };

  const handleChangeNotifications = async () => {
    dispatch(toggleEnabled());
    setIsNotificationEnabled(!isNotificationEnabled);
    try {
      await dispatch(saveNotifications());
    } catch (error) {
      console.error('Error updating user:', error);
    } finally {
      setIsInputsDisabled(false);
    }
  };

  return (
    <div className={styles.account}>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>
          <Typography variant="h6" fontWeight="bold">
            {t('userProfile.notificationPreferences')}
          </Typography>
          <Typography variant="subtitle1" fontWeight="bold">
            {t('userProfile.notifyMaintenanceOnly')}
          </Typography>
        </div>
        <Tooltip title={t('generic.notificationPreferencesAutoSaved')} placement="top" arrow>
          <FormControlLabel
            control={
              <Switch
                checked={isNotificationEnabled}
                onChange={() => {
                  handleChangeNotifications();
                }}
              />
            }
            label={
              isNotificationEnabled
                ? t('userProfile.disableNotifications')
                : t('userProfile.enableNotifications')
            }
            disabled={!isInputsDisabled}
          />
        </Tooltip>
        {!isInputsDisabled ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsInputsDisabled(true)}
            style={{ padding: '5px 22px', height: '42px' }}
          >
            {t('generic.edit')}
          </Button>
        ) : (
          <div className={styles.buttons}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleCancel}
              style={{ padding: '5px 22px', height: '42px' }}
            >
              {t('generic.cancel')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              style={{ padding: '5px 22px', height: '42px' }}
            >
              {t('generic.save')}
            </Button>
          </div>
        )}
      </div>
      <div className={styles.checkboxes_area}>
        <MultiSelectNotify
          name={t('generic.machineGroup')}
          options={notifications.filters.machineGroups}
          selectedOptions={notifications.selectedFilters.machineGroups}
          availableOptions={notifications.activeFilters.machineGroups}
          toggleOption={(id) => dispatch(toggleGroup(id))}
          disabled={isInputsDisabled}
        />
        <MultiSelectNotify
          name={t('generic.machine')}
          options={notifications.filters.machines}
          selectedOptions={notifications.selectedFilters.machines}
          availableOptions={notifications.activeFilters.machines}
          toggleOption={(id) => dispatch(toggleMachine(id))}
          disabled={isInputsDisabled}
        />
        <MultiSelectNotify
          name={t('generic.locations')}
          options={notifications.filters.locations}
          selectedOptions={notifications.selectedFilters.locations}
          availableOptions={notifications.activeFilters.locations}
          toggleOption={(id) => dispatch(toggleLocation(id))}
          disabled={isInputsDisabled}
        />
        <MultiSelectNotify
          name={t('generic.maintenanceType')}
          options={notifications.filters.maintenanceTypes}
          selectedOptions={notifications.selectedFilters.maintenanceTypes}
          availableOptions={notifications.activeFilters.maintenanceTypes}
          toggleOption={(id) => dispatch(toggleType(id))}
          disabled={isInputsDisabled}
        />
      </div>
      <Box className={styles.checkboxes_area} sx={{ pb: '40px' }}>
        {notifications.activeFilters?.machineGroups && (
          <div>
            <EquipmentInfoTitle title={t('generic.machineGroup')} />
            {notifications.activeFilters?.machineGroups.map((id) => {
              const group = notifications.filters.machineGroups.find((group) => group.id === id);
              return <div key={id}>{group ? group.name : ''}</div>;
            })}
          </div>
        )}
        {notifications.activeFilters?.machines && (
          <div>
            <EquipmentInfoTitle title={t('generic.machine')} />
            {notifications.activeFilters.machines.map((id) => {
              const machine = notifications.filters.machines.find((machine) => machine.id === id);
              return <div key={id}>{machine ? machine.name : ''}</div>;
            })}
          </div>
        )}
        {notifications.activeFilters?.locations && (
          <div>
            <EquipmentInfoTitle title={t('generic.locations')} />
            {notifications.activeFilters.locations.map((id) => {
              const location = notifications.filters.locations.find(
                (location) => location.id == id
              );
              return <div key={id}>{location ? location.name : ''}</div>;
            })}
          </div>
        )}
        {notifications.activeFilters?.maintenanceTypes && (
          <div>
            <EquipmentInfoTitle title={t('generic.maintenanceType')} />
            {notifications.activeFilters.maintenanceTypes.map((id) => {
              const maintenanceType = notifications.filters.maintenanceTypes.find(
                (maintenanceType) => maintenanceType.id === id
              );
              return <div key={id}>{maintenanceType ? maintenanceType.name : ''}</div>;
            })}
          </div>
        )}
      </Box>
    </div>
  );
}

export default UserProfilNotification;
