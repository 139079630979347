import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRegisterMutation, useUpdateMutation } from '../../app/services/groups';
import { fetchGroup, fetchGroups } from '../../features/groups/groupsSlice';
import ModalComponent from '../ModalComponent';

import { Button, TextField, Typography } from '@mui/material';

import styles from '../ModalComponent/modalStyles.module.css';
import { useTranslation } from 'react-i18next';

const initialForm = {
  name: '',
  description: '',
};

export default function ModalAddGroup({ id, type }) {
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState(initialForm);
  const [errorResponse, setErrorResponse] = useState('');
  const [registerGroup, { isLoading, error }] = useRegisterMutation();
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageLink, setImageLink] = useState(null);
  const [fileSizeError, setFileSizeError] = useState('');

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [updateGroups] = useUpdateMutation();

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setForm(initialForm);
    setSelectedFile(null);
    setImageLink(null);
    setFileSizeError('');
  };

  useEffect(() => {
    if (id && open) {
      async function fetchData() {
        try {
          const response = await dispatch(fetchGroup(id));
          setForm({
            name: response.payload.name,
            description: response.payload.description,
          });
          setImageLink(response.payload.image);
        } catch (error) {
          console.error('Error fetching group:', error);
        }
      }
      fetchData();
    }
  }, [id, open, dispatch]);

  useEffect(() => {
    if (error) {
      console.log(error);
      setErrorResponse(error.data?.message);
    }
  }, [error]);

  useEffect(() => {
    if (!isLoading && !error) {
      handleClose();
      setErrorResponse('');
      dispatch(fetchGroups());
    }
  }, [isLoading, error, dispatch]);

  const handleChange = (e) => {
    setErrorResponse('');

    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    if (id) {
      try {
        const formData = new FormData();
        formData.append('name', form.name);
        formData.append('description', form.description);
        if (selectedFile) {
          formData.append('image', selectedFile);
        }
        await updateGroups({ id, formData });
        handleClose();
        dispatch(fetchGroups());
      } catch (err) {
        console.error('Error update Group:', err);
      }
    } else {
      try {
        const formData = new FormData();
        formData.append('name', form.name);
        formData.append('description', form.description);
        formData.append('status', 'active');
        formData.append('image', selectedFile);
        await registerGroup(formData);
        dispatch(fetchGroups());
      } catch (err) {
        console.error('Error registering group:', err);
      }
    }
  };

  const handleFileChange = (e) => {
    setErrorResponse('');
    const file = e.target.files[0];
    if (file && file.size > process.env.REACT_APP_MAX_GROUP_IMAGE_FILE_SIZE) {
      setFileSizeError(
        `File size exceeds ${Math.round(
          process.env.REACT_APP_MAX_GROUP_IMAGE_FILE_SIZE / 1000000
        )}MB limit`
      );
      setSelectedFile(null);
    } else {
      setFileSizeError('');
      setSelectedFile(file);
    }
  };

  return (
    <div>
      {type === 'add' ? (
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpen}
          style={{ padding: '8px 22px', textTransform: 'none' }}
        >
          {t('modals.add+')}
        </Button>
      ) : (
        <Button
          variant="outlined"
          color="primary"
          onClick={handleOpen}
          style={{ padding: '5px 15px' }}
        >
          {t('generic.edit')}
        </Button>
      )}
      <ModalComponent open={open} handleClose={handleClose}>
        <div className={styles.modal}>
          <div className={styles.container}>
            <Typography variant="h5" fontWeight="bold">
              {type === 'add' ? t('modals.createNewGroup') : t('modals.updateGroup')}
            </Typography>
            <TextField
              value={form.name}
              label={t('generic.name')}
              variant="outlined"
              name="name"
              onChange={handleChange}
              fullWidth
            />
            <TextField
              value={form.description}
              label={t('generic.description')}
              variant="outlined"
              name="description"
              onChange={handleChange}
              fullWidth
            />

            <Button
              component="label"
              variant="outlined"
              sx={{
                color: 'rgba(0, 0, 0, 0.6)',
                borderColor: 'rgba(0, 0, 0, 0.3)',
                fontSize: '16px',
                height: '56px',
                textTransform: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                '&:hover': {
                  borderColor: 'rgba(0, 0, 0, 1)',
                  background: 'none',
                },
              }}
            >
              <input
                accept="image/*"
                id="fileInput"
                type="file"
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
              {type === 'add' ? t('modals.uploadImage') : t('modals.updateImage')}
            </Button>

            {selectedFile && (
              <img
                src={URL.createObjectURL(selectedFile)}
                alt="Selected file"
                style={{ maxWidth: '452px', marginTop: '10px' }}
              />
            )}
            {id && !selectedFile && (
              <img
                src={`${process.env.REACT_APP_BASE_URL}machine-groups/image/${imageLink}`}
                alt="Uploaded file"
                style={{ maxWidth: '452px', marginTop: '10px' }}
              />
            )}
            {fileSizeError && <Typography color="error">{fileSizeError}</Typography>}
            {errorResponse &&
              (Array.isArray(errorResponse) ? (
                errorResponse.map((item, index) => (
                  <Typography key={index} color="error">
                    {item}
                  </Typography>
                ))
              ) : (
                <Typography color="error">{errorResponse}</Typography>
              ))}

            <div className={styles.button_group}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClose}
                style={{ padding: '5px 22px' }}
              >
                {t('modals.cancel')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                style={{ padding: '5px 22px' }}
              >
                {type === 'add' ? t('modals.create') : t('modals.update')}
              </Button>
            </div>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
}
