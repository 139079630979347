import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useAuth } from '../../hooks/useAuth';
import { formatDate } from '../../helpers/formatDate';
import dayjs from 'dayjs';
import LogsTable from './LogsTable';
import NotFoundPage from '../NotFoundPage';
import { requestSearch } from '../../helpers/search';
import { fetchAuditLogs } from '../../features/auditLogs/auditLogsSlice';

import { Paper, Typography, InputBase, Select, MenuItem } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import styles from './style.module.css';
import 'dayjs/locale/de';

function AuditLogs() {
  const [auditLogs, setAuditLogs] = useState([]);
  const [data, setData] = useState([]);
  const [rowsData, setRowsData] = useState([]);
  const [inputSearch, setInputSearch] = useState('');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [optionModel, setOptionModel] = useState([]);
  const [choosenModel, setChoosenModel] = useState('');

  const { user } = useAuth();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await dispatch(fetchAuditLogs());
      if (response.payload) {
        setAuditLogs(response.payload.slice().reverse());
      }
    } catch (error) {
      console.error('Error fetching audit logs:', error);
    }
  };

  const optionForModel = {
    supply: t('generic.supply'),
    user: t('generic.user'),
    'maintenance type': t('generic.maintenanceType'),
    location: t('generic.locations'),
    machineGroup: t('generic.machineGroup'),
    maintenance: t('generic.maintenance'),
    manual: t('generic.manuals'),
    machine: t('generic.machines'),
    validationDocument: t('machineList.validationDocuments'),
  };

  useEffect(() => {
    if (auditLogs) {
      const preparedData = auditLogs?.map((auditLog) => ({
        number: auditLog.id,
        title: auditLog.title,
        description: auditLog.description,
        modifiedBy: auditLog.modified_by,
        model: auditLog.specific_model,
        created: formatDate(auditLog.created_at),
        created_at: auditLog.created_at,
        editor_description: auditLog.editor_description,
      }));

      setData(preparedData);

      const uniqueModels = [...new Set(auditLogs.map((auditLog) => auditLog.specific_model))];
      setOptionModel(uniqueModels);
    }
  }, [auditLogs]);

  const titles = [
    t('generic.number'),
    t('generic.title'),
    t('generic.description'),
    t('generic.modifiedBy'),
    t('generic.model'),
    t('generic.createdAt'),
  ];

  const handleStartDateChange = (date) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    setStartDate(formattedDate);
  };

  const handleEndDateChange = (date) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    setEndDate(formattedDate);
  };

  useEffect(() => {
    requestSearch(inputSearch, data, setRowsData, [], startDate, endDate, choosenModel);
  }, [startDate, endDate, inputSearch, data, choosenModel]);

  const handleInputSearch = (e) => {
    setInputSearch(e.target.value);
  };

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setChoosenModel(selectedValue);
  };

  if (user?.role === 'admin') {
    return (
      <section className={styles.container}>
        <div className={styles.header}>
          <Typography variant="h5" fontWeight="bold">
            {t('generic.auditLogs')}
          </Typography>
        </div>
        <div className={styles.options}>
          <Paper
            component="form"
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: 300,
              height: 52,
              border: '1px solid #ddd',
            }}
          >
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder={t('generic.search')}
              onChange={(e) => {
                handleInputSearch(e);
              }}
            />
          </Paper>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
            <DatePicker
              label={t('generic.from')}
              onChange={handleStartDateChange}
              fullWidth
              sx={{
                '& .MuiInputBase-root': {
                  width: '300px',
                },
              }}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
            <DatePicker
              label={t('generic.to')}
              onChange={handleEndDateChange}
              fullWidth
              sx={{
                '& .MuiInputBase-root': {
                  width: '300px',
                },
              }}
            />
          </LocalizationProvider>
          <Select
            labelId="model-select-label"
            id="model-select"
            value={choosenModel}
            onChange={handleChange}
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: 300,
              height: 52,
              border: '1px solid #ddd',
            }}
            displayEmpty
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            {optionModel.map((model, index) => (
              <MenuItem key={index} value={model}>
                {optionForModel[model]}
              </MenuItem>
            ))}
          </Select>
        </div>
        <LogsTable titles={titles} data={rowsData} logsModel={choosenModel} />
      </section>
    );
  } else {
    return <NotFoundPage />;
  }
}

export default AuditLogs;
