import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRegisterMutation } from '../../app/services/validDocuments';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTranslation } from 'react-i18next';
import ModalComponent from '../ModalComponent';

import { Button, FormControl, FormHelperText, TextField, Typography } from '@mui/material';

import styles from '../ModalComponent/modalStyles.module.css';
import 'dayjs/locale/de';
import { validateFormData, getValidationErrorMessage } from '../../helpers';
import { postValidationDocSchema } from '../../schemas/postValidationDocSchema';
import { INPUT_FILE_ACCEPT_DOC } from '../../constants';

const initialForm = {
  name: '',
  description: '',
};

export default function ModalAddValidDoc({ id, fetchData }) {
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState(initialForm);
  const [errorResponse, setErrorResponse] = useState('');
  const [registerValidDocument, { isLoading, error }] = useRegisterMutation();
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [formValidationErrors, setFormValidationErrors] = useState(null);
  const nameError = getValidationErrorMessage(formValidationErrors, 'name');
  const descrError = getValidationErrorMessage(formValidationErrors, 'description');
  const dateError = getValidationErrorMessage(formValidationErrors, 'date');
  const fileError = getValidationErrorMessage(formValidationErrors, 'file');

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setForm(initialForm);
    setFormValidationErrors(null);
  };

  useEffect(() => {
    if (error) {
      console.log(error);
      setErrorResponse(error.data.message);
    }
  }, [error]);

  useEffect(() => {
    if (!isLoading && !error) {
      handleClose();
      setErrorResponse('');
    }
  }, [isLoading, error, dispatch]);

  const handleChange = (e) => {
    setErrorResponse('');
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    const validationErrors = validateFormData(t, postValidationDocSchema, {
      ...form,
      date: selectedDate,
      file: selectedFile,
    });
    if (!!validationErrors) {
      setFormValidationErrors(validationErrors);
      return;
    }

    try {
      const formData = new FormData();
      formData.append('name', form.name);
      formData.append('description', form.description);
      formData.append('approvalDate', selectedDate);
      formData.append('machine', JSON.stringify({ id }));
      formData.append('file', selectedFile);

      await registerValidDocument(formData);
      await fetchData();
      setForm(initialForm);
      setSelectedDate(null);
      setSelectedFile(null);
    } catch (err) {
      console.error('Error add document:', err);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        style={{ padding: '5px 22px' }}
      >
        {t('modals.upload')}
      </Button>
      <ModalComponent open={open} handleClose={handleClose}>
        <div className={styles.modal}>
          <div className={styles.container}>
            <Typography variant="h5" fontWeight="bold">
              {t('modals.addValidDocument')}
            </Typography>
            <TextField
              label={t('generic.name')}
              variant="outlined"
              name="name"
              onChange={handleChange}
              fullWidth
              error={!!nameError}
              helperText={nameError ?? ' '}
            />
            <TextField
              label={t('generic.description')}
              variant="outlined"
              name="description"
              onChange={handleChange}
              fullWidth
              error={!!descrError}
              helperText={descrError ?? ' '}
            />

            <FormControl>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                <DatePicker
                  label="Approval Date"
                  value={selectedDate}
                  onChange={(newValue) => setSelectedDate(newValue)}
                  fullWidth
                  slotProps={{ textField: { error: !!dateError } }}
                />
              </LocalizationProvider>
              <FormHelperText error>{dateError ?? ' '}</FormHelperText>
            </FormControl>

            <FormControl>
              <Button
                component="label"
                variant="outlined"
                sx={(theme) => ({
                  color: fileError ? theme.palette.error.main : 'rgba(0, 0, 0, 0.6)',
                  borderColor: fileError ? theme.palette.error.main : 'rgba(0, 0, 0, 0.3)',
                  fontSize: '16px',
                  height: '56px',
                  textTransform: 'none',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  '&:hover': {
                    borderColor: 'rgba(0, 0, 0, 1)',
                    background: 'none',
                  },
                })}
              >
                <input
                  accept={INPUT_FILE_ACCEPT_DOC}
                  id="fileInput"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
                {selectedFile ? selectedFile.name : t('modals.uploadDocument')}
              </Button>
              <FormHelperText error={!!fileError}>{fileError ?? ' '}</FormHelperText>
            </FormControl>

            {errorResponse &&
              (Array.isArray(errorResponse) ? (
                errorResponse.map((item, index) => (
                  <Typography key={index} color="error">
                    {item}
                  </Typography>
                ))
              ) : (
                <Typography color="error">{errorResponse}</Typography>
              ))}

            <div className={styles.button_group}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClose}
                style={{ padding: '5px 22px' }}
              >
                {t('modals.cancel')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                style={{ padding: '5px 22px' }}
              >
                {t('modals.add')}
              </Button>
            </div>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
}
