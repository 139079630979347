import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { useMsal } from '@azure/msal-react';

import { useLoginMutation, useLoginWithAzureMutation } from '../../app/services/auth';
import fetchData from '../../helpers/fetchData';
import { fetchUser } from '../../features/auth/authSlice';
import { useAuth } from '../../hooks/useAuth';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';

import styles from './style.module.css';
import { useTranslation } from 'react-i18next';

import { validateFormData, getValidationErrorMessage } from '../../helpers';
import { signInSchema } from '../../schemas/signInSchema';

function SignIn() {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const [formValidationErrors, setFormValidationErrors] = useState(null);
  const emailError = getValidationErrorMessage(formValidationErrors, 'email');
  const passwordError = getValidationErrorMessage(formValidationErrors, 'password');

  const { instance } = useMsal();

  const navigate = useNavigate();
  const [login] = useLoginMutation();
  const [loginWithAzure] = useLoginWithAzureMutation();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    const token = Cookies.get('token');
    if (token && !user) {
      dispatch(fetchUser());
    }
    if (user) {
      navigate('/');
    }
  }, [user, navigate, dispatch]);

  const msalLogin = async () => {
    try {
      const loginResponse = await instance.loginPopup();
      if (loginResponse.account.idToken) {
        await loginWithAzure(loginResponse.account.idToken)
          .unwrap()
          .then((response) => {
            Cookies.set('token', response.access_token, { expires: 7 });
            navigate('/');
          })
          .catch(async (error) => {
            console.error('Get token from the server error: ', error);
          });
      }
    } catch (error) {
      console.error('MSAL login error', error);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleEmailInput = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordInput = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormValidationErrors(null);

    const formData = {
      email,
      password,
    };

    const validationErrors = validateFormData(t, signInSchema, formData);
    if (!!validationErrors) {
      setFormValidationErrors(validationErrors);
      return;
    }

    try {
      const response = await login(formData).unwrap();
      const token = response.access_token;
      navigate('/');
      Cookies.set('token', token, { expires: 7 });
      setEmail('');
      setPassword('');
      fetchData(dispatch);
    } catch (err) {
      console.log(err);
      setError(err.data?.message);
    }
  };

  return (
    <Stack component="section" direction="row" className={styles.container}>
      <div className={styles.container_logo}>
        <Typography variant="h4">Wartungssoftware KMG</Typography>
      </div>
      <div className={styles.container_login}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h4" fontWeight="bold" mb={5}>
            {t('signIn.loginToSystem')}
          </Typography>
          <Stack component="form" onSubmit={handleSubmit} sx={{ width: '100%' }}>
            <FormControl fullWidth variant="outlined" sx={{ mb: 1 }}>
              <TextField
                id="outlined-basic"
                label={t('signIn.emailAddress')}
                variant="outlined"
                fullWidth
                margin="normal"
                value={email}
                onChange={(e) => handleEmailInput(e)}
                error={!!emailError}
                helperText={emailError ?? ' '}
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password" shrink error={!!passwordError}>
                {t('signIn.password')}
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                autoComplete="off"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password *"
                value={password}
                onChange={(e) => handlePasswordInput(e)}
                error={!!passwordError}
                notched
              />
              <FormHelperText error={!!passwordError}>{passwordError ?? ' '}</FormHelperText>
            </FormControl>
            {error && <Typography color="error">{error}</Typography>}
            <Link to="/reset-password" className={styles.link}>
              {t('signIn.forgotPassword')}
            </Link>
            <Button
              fullWidth
              variant="contained"
              type="submit"
              color="primary"
              style={{ marginTop: '10px', height: '42px' }}
            >
              {t('signIn.submit')}
            </Button>

            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={msalLogin}
              sx={{ marginTop: '10px', textTransform: 'capitalize', height: '42px' }}
              startIcon={
                <Box component="img" src="/ms.webp" alt="MS Office" width="24px" height="24px" />
              }
            >
              Sign in with Office365
            </Button>
          </Stack>
        </Box>
      </div>
    </Stack>
  );
}

export default SignIn;
