import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { fetchMachineRepairReport } from '../../features/machines/machinesSlice';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ModalComponent from '../ModalComponent';

import { Button, Divider, TextField, Typography } from '@mui/material';

import styles from './styles.module.css';
import 'dayjs/locale/de';

const initialForm = {
  comments: '',
};

export default function ModalReportRepair({ id, machineName, machineNumber, groupName, groupImg }) {
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState(initialForm);
  const [errorResponse, setErrorResponse] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [reportDay, setReportDay] = useState(dayjs());

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setForm(initialForm);
    setSelectedFile(null);
  };

  const handleChange = (e) => {
    setErrorResponse('');
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const handleDateChange = (date) => {
    setReportDay(date);
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append('comment', form.comments);
      formData.append('machine', JSON.stringify({ id }));
      formData.append('file', selectedFile);
      const formattedDate = dayjs(reportDay).format('YYYY-MM-DDTHH:mm:ssZ');
      formData.append('activatedAt', formattedDate);
      if (!form.comments.trim()) {
        setErrorResponse(t('modals.commentNotEmpty'));
        return;
      }
      const response = await dispatch(fetchMachineRepairReport(formData));
      if (response.payload?.error || response.payload?.statusCode === 500) {
        setErrorResponse(response.payload.message);
      } else {
        handleClose();
      }
    } catch (err) {
      console.error('Error registering repair report:', err);
    }
  };

  return (
    <div style={{ alignSelf: 'flex-end' }}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        style={{
          textTransform: 'none',
          width: '171px',
          height: '42px',
          alignSelf: 'flex-end',
        }}
      >
        <Typography variant="subtitle2" fontWeight="bold">
          {t('modals.reportRepair')}
        </Typography>
      </Button>
      <ModalComponent open={open} handleClose={handleClose}>
        <div className={styles.modal}>
          <div className={styles.container}>
            <div className={styles.header}>
              <img
                src={`${process.env.REACT_APP_BASE_URL}machine-groups/image/${groupImg}`}
                alt=""
                style={{ maxWidth: '90px', maxHeight: '68px' }}
              />
              <div className={styles.title}>
                <p className={styles.title_group}>{groupName}</p>
                <p className={styles.title_machine}>
                  {machineNumber} {machineName}
                </p>
              </div>
            </div>
            <Divider />

            <div className={styles.content}>
              <div className={styles.description}>
                <p className={styles.description_title}>{t('modals.unscheduled')}</p>

                <p className={styles.description_task}>Task Description:</p>

                <p className={styles.description_task__desc}>{t('modals.pleaseProvide')}</p>
              </div>

              <div className={styles.fields}>
                <TextField
                  label={t('modals.comments')}
                  variant="outlined"
                  value={form.comments}
                  name="comments"
                  onChange={handleChange}
                  multiline
                  rows={3}
                  fullWidth
                />

                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                  <DatePicker
                    label={t('modals.reportDay')}
                    onChange={handleDateChange}
                    fullWidth
                    maxDate={dayjs(Date.now())}
                    value={reportDay}
                  />
                </LocalizationProvider>

                <Button
                  component="label"
                  variant="outlined"
                  sx={{
                    color: 'rgba(0, 0, 0, 0.6)',
                    borderColor: 'rgba(0, 0, 0, 0.3)',
                    fontSize: '16px',
                    height: '56px',
                    textTransform: 'none',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    '&:hover': {
                      borderColor: 'rgba(0, 0, 0, 1)',
                      background: 'none',
                    },
                  }}
                >
                  <input
                    accept="/*"
                    id="fileInput"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                  />
                  <span
                    style={{
                      display: 'inline-block',
                      maxWidth: '100%',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {selectedFile ? selectedFile.name : t('modals.uploadFile')}
                  </span>
                </Button>
                {errorResponse &&
                  (Array.isArray(errorResponse) ? (
                    errorResponse.map((item, index) => (
                      <Typography key={index} color="error">
                        {item}
                      </Typography>
                    ))
                  ) : (
                    <Typography color="error">{errorResponse}</Typography>
                  ))}
              </div>
            </div>
            <Divider />

            <div className={styles.button_group}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClose}
                style={{ padding: '5px 22px' }}
              >
                {t('modals.cancel')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                style={{ padding: '5px 22px' }}
              >
                {t('modals.report')}
              </Button>
            </div>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
}
