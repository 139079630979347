import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';
import { html } from 'js-beautify';

import { Button } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

import styles from './styles.module.css';

export default function ModalDiffViewer({ content }) {
  const [open, setOpen] = useState(false);
  const [formattedContent, setFormattedContent] = useState([]);

  const { t } = useTranslation();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (content) {
      const jsonDescription = JSON.parse(content);

      const formattedData = jsonDescription.map((item) => {
        const oldDesc = parseIfJson(item.values.old);
        const newDesc = parseIfJson(item.values.new);

        const convertContentToHtml = (content) => {
          try {
            const parsedContent = JSON.parse(content);

            if (typeof parsedContent === 'object' && parsedContent.blocks) {
              const editorStateFromContent = EditorState.createWithContent(
                convertFromRaw(parsedContent)
              );
              const rawContentState = convertToRaw(editorStateFromContent.getCurrentContent());
              return draftToHtml(rawContentState);
            } else {
              return content;
            }
          } catch (e) {
            return content;
          }
        };

        return {
          key: item.key,
          oldValue: convertContentToHtml(oldDesc).toString(),
          newValue: convertContentToHtml(newDesc).toString(),
        };
      });

      setFormattedContent(formattedData);
    }
  }, [content]);

  const parseIfJson = (str) => {
    try {
      return JSON.parse(str);
    } catch (e) {
      return str;
    }
  };

  const htmlStylingOptions = {
    indent_size: 4,
    html: {
      end_with_newline: true,
    },
  };

  function formatHTMLForDiff(htmlStr, textOnly) {
    const formattedHTML = html(htmlStr, htmlStylingOptions);
    if (textOnly) {
      return new DOMParser().parseFromString(formattedHTML, 'text/html').body.innerText;
    }

    return formattedHTML;
  }

  const compareStyles = {
    variables: {
      light: {
        codeFoldGutterBackground: '#6F767E',
        codeFoldBackground: '#E2E4E5',
      },
    },
  };
  return (
    <div>
      <Button
        fullWidth
        onClick={handleOpen}
        variant="text"
        sx={{
          fontSize: '14px',
          textTransform: 'none',
          padding: '0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          '&:hover': {
            borderColor: 'rgba(0, 0, 0, 1)',
            background: 'none',
          },
        }}
      >
        {t('modals.сlickView')}
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 1500,
        }}
      >
        <Fade in={open}>
          <div className={styles.modal}>
            <div className={styles.container}>
              {formattedContent.map((item, index) => (
                <div key={index} className={styles.editor}>
                  <h3>{`${item.key}`}</h3>
                  {/* <h3>{`t(modals.${item.key})`}</h3> */}
                  <ReactDiffViewer
                    oldValue={formatHTMLForDiff(item.oldValue, true)}
                    newValue={formatHTMLForDiff(item.newValue, true)}
                    splitView={true}
                    compareMethod={DiffMethod.WORDS}
                    styles={compareStyles}
                    leftTitle={t('modals.initialVersion')}
                    rightTitle={t('modals.adjustedSolution')}
                    renderContent={(source) => {
                      return source;
                    }}
                  />
                </div>
              ))}
              <div className={styles.button_group}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleClose}
                  style={{ padding: '5px 22px' }}
                >
                  {t('modals.cancel')}
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
