import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  fetchMachine,
  fetchMachineOutTask,
  fetchMachines,
} from '../../features/machines/machinesSlice';
import { useUpdateMutation } from '../../app/services/machines';
import { useAuth } from '../../hooks/useAuth';
import { ACTIONS } from '../../constants/actionTables';
import ModalMaintenanceTask from '../../components/ModalMaintenanceTask';
import ModalStatusMachine from '../../components/ModalStatusMachine';
import TableComponent from '../../components/Table';
import ModalAddValidDoc from '../../components/ModalAddValidDoc';
import ModalReportRepair from '../../components/ModalReportRepair';
import MachineProfileInputs from '../../components/MachineProfileInputs';
import ModalConfirmStatusChange from '../../components/ModalConfirmStatusChange';
import { formatDate } from '../../helpers/formatDate';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Button, Chip, Divider, Tooltip, Typography } from '@mui/material';

import styles from './style.module.css';
import Toast from '../../components/Toast';

const initialForm = {
  deputy: '',
  description: '',
  location: '',
  machineGroup: '',
  name: '',
  number: '',
  responsiblePerson: '',
  serialNumber: '',
  yearOfManufacture: '',
  status: '',
};

function MachineProfile() {
  const [form, setForm] = useState(initialForm);
  const [machine, setMachine] = useState(null);
  const [validationsDoc, setValidationsDoc] = useState(null);
  const [manualsDoc, setManualsDoc] = useState(null);
  const [maintenanceTasks, setMaintenanceTasks] = useState(null);
  const [suppliesData, setSuppliesData] = useState(null);
  const [imageLink, setImageLink] = useState();
  const [isInputsDisabled, setIsInputsDisabled] = useState(false);
  const [isStatusChange, setIsStatusChange] = useState(false);

  const { id } = useParams();
  const { user } = useAuth();
  const { t } = useTranslation();
  const [updateMachine] = useUpdateMutation();
  const dispatch = useDispatch();

  const titlesDocuments = [t('generic.document'), t('generic.approvalDate'), t('generic.action')];
  const titlesSupplies = [
    t('generic.number'),
    t('generic.name'),
    t('generic.description'),
    t('generic.manufacture'),
    t('generic.safetySheet'),
  ];
  const titlesManuals = [t('generic.document'), t('generic.description'), t('generic.action')];

  const fetchData = async () => {
    try {
      const response = await dispatch(fetchMachine(id));
      setMachine(response.payload);
      setForm({
        id,
        deputy: response.payload.deputy,
        description: response.payload.description,
        location: response.payload.location,
        machineGroup: response.payload.machine_group,
        name: response.payload.name,
        number: response.payload.number,
        responsiblePerson: response.payload.responsible_person,
        serialNumber: response.payload.serial_number,
        yearOfManufacture: response.payload.year_of_manufacture,
        status: response.payload.status,
        is_archived: response.payload.is_archived,
        activated_at: response.payload.activated_at,
      });
      setImageLink(response.payload.machine_group.image);
      setManualsDoc(
        response.payload.machine_group.manuals?.map((doc) => ({
          document: doc.original_filename,
          description: doc.description,
          link: doc.filename,
        }))
      );
      setValidationsDoc(
        response.payload.validation_documents.map((doc) => ({
          id: doc.id,
          document: doc.name,
          date: formatDate(doc.approval_date),
          link: doc.filename,
        }))
      );
      setSuppliesData(
        response.payload.machine_group.machine_supplies.map((supply) => ({
          number: supply.supply.id,
          name: supply.supply.name,
          description: supply.supply.description,
          manufacture: supply.supply.manufacture,
          link: supply.supply.filename,
        }))
      );
    } catch (error) {
      console.error('Error fetching machine data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dispatch, id]);

  const fetchMachineTask = async () => {
    if (machine?.id) {
      const response = await dispatch(fetchMachineOutTask(machine.id));
      if (response.payload) {
        setMaintenanceTasks(response.payload);
      }
    }
  };

  useEffect(() => {
    fetchMachineTask();
  }, [machine?.id]);

  const handleSubmit = async () => {
    try {
      const { activated_at, ...rest } = form;
      const machineData = {
        ...rest,
        activatedAt: activated_at,
      };

      if (form.status === machine.status) {
        await updateMachine({ id, machineData });
        await fetchData();
        await dispatch(fetchMachines());
        setIsInputsDisabled(false);
      } else {
        setIsStatusChange(true);
      }
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const handleCancel = async () => {
    try {
      await fetchData();
      setIsInputsDisabled(false);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const [toastOpen, setToastOpen] = useState(false);
  const handleToastClose = () => {
    setToastOpen(false);
  };

  return (
    <section className={styles.container}>
      <div className={styles.header}>
        <div className={styles.button_group}>
          <div className={styles.title}>
            <Link to={`/machines`} style={{ textDecoration: 'none', color: 'black' }}>
              <Typography variant="h5" fontWeight="bold" sx={{ opacity: 0.5 }}>
                {t('machineList.machineList')}
              </Typography>
            </Link>

            <Typography variant="h5" fontWeight="bold">
              {form.number} {form.name}
            </Typography>
          </div>
          <Chip
            label={form.status === 'active' ? t('machineList.active') : t('machineList.inactive')}
            color={form.status === 'active' ? 'success' : 'default'}
          />
        </div>
        {user?.role === 'admin' && (
          <div className={styles.button_group}>
            {!isInputsDisabled ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setIsInputsDisabled(true)}
                style={{ padding: '5px 22px', height: '42px' }}
              >
                {t('generic.edit')}
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                style={{ padding: '5px 22px', height: '42px' }}
              >
                {t('generic.save')}
              </Button>
            )}
            {!isInputsDisabled ? (
              form.is_archived === 'non-archived' && (
                <ModalStatusMachine
                  id={id}
                  action={'archived'}
                  onSuccess={() => {
                    setToastOpen(true);
                    setForm((prev) => ({ ...prev, is_archived: 'archived' }));
                  }}
                />
              )
            ) : (
              <Button
                variant="outlined"
                color="primary"
                onClick={handleCancel}
                style={{ padding: '5px 22px', height: '42px' }}
              >
                {t('generic.cancel')}
              </Button>
            )}
          </div>
        )}
      </div>
      <Divider variant="fullWidth " flexItem />
      <div className={styles.specific}>
        <div className={styles.fields_inputs}>
          <div className={styles.account_info}>
            <Typography variant="h6" fontWeight="bold">
              {t('generic.machine')}
            </Typography>

            <Tooltip
              title={
                <Typography variant="h6" fontWeight="bold">
                  {t('machineList.clickEdit')}
                </Typography>
              }
              placement="top"
              arrow
            >
              <InfoOutlinedIcon color="primary" />
            </Tooltip>
          </div>
          <img
            src={`${process.env.REACT_APP_BASE_URL}machine-groups/image/${imageLink}`}
            alt=""
            style={{ maxWidth: '305px', maxHeight: '305px', objectFit: 'contain' }}
          />

          <MachineProfileInputs form={form} setForm={setForm} isInputsDisabled={isInputsDisabled} />
        </div>

        <div className={styles.fields_maintenance}>
          <div className={styles.upcoming}>
            <Typography fontWeight="bold" variant="h6">
              {t('machineList.upcomingMaintenances')}
            </Typography>
            {maintenanceTasks && machine?.status === 'active' ? (
              <div className={styles.maintenance}>
                {maintenanceTasks.map((item) => (
                  <ModalMaintenanceTask
                    task={item}
                    type={ACTIONS.PROFILE}
                    fetchdata={fetchMachineTask}
                    machineGroupName={form.machineGroup.name}
                    machineGroupImg={imageLink}
                  />
                ))}
              </div>
            ) : (
              <div className={styles.maintenance}>
                <Typography fontWeight="bold">{t('machineList.noMaintenance')}</Typography>
              </div>
            )}
          </div>
          {machine?.status === 'active' ? (
            <ModalReportRepair
              id={machine.id}
              machineName={machine.name}
              machineNumber={machine.number}
              groupName={machine.machine_group.name}
              groupImg={machine.machine_group.image}
            />
          ) : (
            <Tooltip title={t('machineList.posiibleOnly')} placement="top" arrow>
              <div
                style={{
                  display: 'flex',
                  boxSizing: 'border-box',
                  alignItems: 'center',
                  justifyContent: 'center',
                  alignSelf: 'flex-end',
                  height: '42px',
                  width: '171px',
                  backgroundColor: 'transparent',
                  color: 'rgba(0, 0, 0, 0.26)',
                  border: '1px solid rgba(0, 0, 0, 0.12)',
                  borderRadius: '4px',
                  cursor: 'not-allowed',
                }}
              >
                {t('modals.reportRepair')}
              </div>
            </Tooltip>
          )}
        </div>

        <div className={styles.double_fields}>
          {manualsDoc && (
            <div className={styles.fields}>
              <Typography fontWeight="bold">{t('generic.manuals')}</Typography>
              <TableComponent
                search={false}
                titles={titlesManuals}
                data={manualsDoc}
                action={ACTIONS.SPECIFIC_MACHINE_MANUALS}
              />
            </div>
          )}

          <div className={styles.fields}>
            <div className={styles.sub_header}>
              <Typography fontWeight="bold">{t('machineList.validationDocuments')}</Typography>
              {user?.role === 'admin' && <ModalAddValidDoc id={id} fetchData={fetchData} />}
            </div>
            {validationsDoc && (
              <TableComponent
                search={false}
                titles={titlesDocuments}
                data={validationsDoc}
                fetchData={fetchData}
                action={ACTIONS.SPECIFIC_MACHINE_DOCUMENTS}
              />
            )}
          </div>

          <div className={styles.fields}>
            <Typography fontWeight="bold">{t('generic.supplies')}</Typography>
            {suppliesData && (
              <TableComponent
                search={false}
                titles={titlesSupplies}
                data={suppliesData}
                action={ACTIONS.SPECIFIC_MACHINE_SUPPLY}
              />
            )}
          </div>
        </div>
      </div>
      <ModalConfirmStatusChange
        isStatusChange={isStatusChange}
        setIsStatusChange={setIsStatusChange}
        setIsInputsDisabled={setIsInputsDisabled}
        fetchData={fetchData}
        id={id}
        form={form}
      />

      <Toast
        open={toastOpen}
        handleClose={handleToastClose}
        severity="success"
        message={t('message.machineArchivedNotification')}
      />
    </section>
  );
}

export default MachineProfile;
