import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import { LOCALS } from "./constants";
import enTranslation from "./localization/en.json";
import deTranslation from "./localization/de.json";

const resources = {
  [LOCALS.EN]: {
    translation: {
      ...enTranslation,
    },
  },
  [LOCALS.DE]: {
    translation: {
      ...deTranslation,
    },
  },
};

i18n
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
