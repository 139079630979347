// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__5Nih7 {
  display: flex;
  flex-direction: column;
  padding: 50px;
}

.style_header__qJzdl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.style_options__5atLT {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 30px;
  margin-bottom: 35px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
}

.style_text_field__5njzh {
  width: 300px;

  @media (max-width: 1024px) {
    width: 100%;
  }
}

.style_day__hJF4N {
  display: flex;
  font-size: 16px;
  background-color: #b1c7ff;
  border: none;
  width: 100%;
  cursor: pointer;
}
.style_fc__\\+7llt .style_fc-timegrid-axis-cushion__XsCL\\+ {
  flex-shrink: 0;
  max-width: 151px;
  margin: 100px 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/Calendar/style.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,WAAW;EACX,SAAS;EACT,mBAAmB;;EAEnB;IACE,sBAAsB;EACxB;AACF;;AAEA;EACE,YAAY;;EAEZ;IACE,WAAW;EACb;AACF;;AAEA;EACE,aAAa;EACb,eAAe;EACf,yBAAyB;EACzB,YAAY;EACZ,WAAW;EACX,eAAe;AACjB;AACA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  padding: 50px;\n}\n\n.header {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n  margin-bottom: 20px;\n}\n\n.options {\n  display: flex;\n  align-items: center;\n  flex-wrap: wrap;\n  width: 100%;\n  gap: 30px;\n  margin-bottom: 35px;\n\n  @media (max-width: 1024px) {\n    flex-direction: column;\n  }\n}\n\n.text_field {\n  width: 300px;\n\n  @media (max-width: 1024px) {\n    width: 100%;\n  }\n}\n\n.day {\n  display: flex;\n  font-size: 16px;\n  background-color: #b1c7ff;\n  border: none;\n  width: 100%;\n  cursor: pointer;\n}\n.fc .fc-timegrid-axis-cushion {\n  flex-shrink: 0;\n  max-width: 151px;\n  margin: 100px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__5Nih7`,
	"header": `style_header__qJzdl`,
	"options": `style_options__5atLT`,
	"text_field": `style_text_field__5njzh`,
	"day": `style_day__hJF4N`,
	"fc": `style_fc__+7llt`,
	"fc-timegrid-axis-cushion": `style_fc-timegrid-axis-cushion__XsCL+`
};
export default ___CSS_LOADER_EXPORT___;
