export const getPermissions = (t) => [
  {
    value: 'admin',
    label: t('options.admin'),
  },
  {
    value: 'user',
    label: t('options.user'),
  },
];

export const getLanguages = (t) => [
  {
    value: 'english',
    label: t('options.english'),
  },
  {
    value: 'german',
    label: t('options.german'),
  },
];

export const getIntervals = (t) => [
  { id: 1, period: t('options.daily'), value: { type: 'day', value: 1 } },
  { id: 2, period: t('options.weekly'), value: { type: 'week', value: 1 } },
  {
    id: 3,
    period: t('options.everyTwoWeeks'),
    value: { type: 'week', value: 2 },
  },
  { id: 4, period: t('options.monthly'), value: { type: 'month', value: 1 } },
  {
    id: 5,
    period: t('options.everyThreeMonths'),
    value: { type: 'month', value: 3 },
  },
  {
    id: 6,
    period: t('options.everySixMonths'),
    value: { type: 'month', value: 6 },
  },
  { id: 7, period: t('options.yearly'), value: { type: 'year', value: 1 } },
  {
    id: 8,
    period: t('options.everyTwoYears'),
    value: { type: 'year', value: 2 },
  },
  {
    id: 9,
    period: t('options.everyThreeYears'),
    value: { type: 'year', value: 3 },
  },
  {
    id: 10,
    period: t('options.everyFourYears'),
    value: { type: 'year', value: 4 },
  },
  {
    id: 11,
    period: t('options.everyFiveYears'),
    value: { type: 'year', value: 5 },
  },
  {
    id: 12,
    period: t('options.everySixYears'),
    value: { type: 'year', value: 6 },
  },
];

export const getIntervalsNotify = (t) => [
  { period: t('options.1dayBefore'), value: 1 },
  { period: t('options.2daysBefore'), value: 2 },
  { period: t('options.3daysBefore'), value: 3 },
  { period: t('options.1weekBefore'), value: 7 },
  { period: t('options.2weeksBefore'), value: 14 },
  { period: t('options.1monthBefore'), value: 30 },
  { period: t('options.2monthsBefore'), value: 60 },
  { period: t('options.3monthsBefore'), value: 90 },
];

export const getStatusesTask = (t) => [
  {
    value: 'perfomed',
    label: t('options.perfomed'),
  },
  {
    value: 'not perfomed',
    label: t('options.notPerfomed'),
  },
];

export const getStatusesMachine = (t) => [
  {
    name: t('options.active'),
    value: 'active',
  },
  {
    name: t('options.inactive'),
    value: 'inactive',
  },
];

/**
 * Object representing intervals in days associated with specific keys.
 * Keys represent interval ids and values represent the number of days in each interval.
 * It is assumed that a month consists of 30 days.
 * @type {Object<string, number>}
 */
export const daysPerInterval = {
  1: 1,
  2: 7,
  3: 14,
  4: 30,
  5: 90,
};
