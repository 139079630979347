import { useEffect, useState } from "react";
import { useLocations } from "../../hooks/useLocations";
import { ACTIONS } from "../../constants/actionTables";
import TableComponent from "../../components/Table";
import ModalAddLocation from "../../components/ModalAddLocation";

import { Typography } from "@mui/material";

import styles from "./style.module.css";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../hooks/useAuth";

function Locations() {
  const [data, setData] = useState(null);

  const { user } = useAuth();
  const { locations } = useLocations();
  const { t } = useTranslation();

  useEffect(() => {
    if (locations) {
      const preparedData = locations.map((location) => ({
        id: location.id,
        number: location.id,
        name: location.name,
        description: location.description,
        flagForDelete: location.is_able_to_remove,
      }));
      setData(preparedData);
    }
  }, [locations]);

  const titles = [
    t("generic.number"),
    t("generic.name"),
    t("generic.description"),
  ];

  if (user.role === "admin") {
    titles.push(t("generic.action"));
  }

  return (
    <section className={styles.container}>
      <div className={styles.header}>
        <Typography variant="h5" fontWeight="bold">
          {t("generic.locations")}
        </Typography>
        {user.role === "admin" && <ModalAddLocation type={"add"} />}
      </div>
      {data && (
        <TableComponent
          titles={titles}
          data={data}
          action={ACTIONS.LOCATIONS}
        />
      )}
    </section>
  );
}

export default Locations;
