import { Typography } from "@mui/material";
import styles from "./style.module.css";

import { useTranslation } from "react-i18next";

function NotFoundPage() {
  const { t } = useTranslation();

  return (
    <section className={styles.container}>
      <Typography variant="h4" fontWeight="bold">{t("generic.pageNotFound")}</Typography>
    </section>
  );
}

export default NotFoundPage;
