// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_modal__JE6WK {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  width: 80%;
  max-height: 80%;
  overflow: hidden;

  @media (max-width: 1024px) {
    width: calc(80% - 20px);
  }
}

.styles_container__QGN5K {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  gap: 15px;
}

.styles_editor__FKVRU {
  height: 60vh;
  overflow: auto;
}

/* Styling for toolbar */
.styles_toolbar__qHObJ {
  position: sticky;
  top: 0; /* Sticky position start */
  z-index: 1000; /* Ensures the toolbar stays on top */
  background-color: white; /* Optional for visibility */
}

.styles_button_group__UvSVG {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  padding: 5px 0;
}

.styles_wrapper__pevV2 {
  position: relative;
}
`, "",{"version":3,"sources":["webpack://./src/components/ModalEditor/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,sBAAsB;EACtB,mBAAmB;EACnB,UAAU;EACV,eAAe;EACf,gBAAgB;;EAEhB;IACE,uBAAuB;EACzB;AACF;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,sBAAsB;EACtB,WAAW;EACX,aAAa;EACb,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,cAAc;AAChB;;AAEA,wBAAwB;AACxB;EACE,gBAAgB;EAChB,MAAM,EAAE,0BAA0B;EAClC,aAAa,EAAE,qCAAqC;EACpD,uBAAuB,EAAE,4BAA4B;AACvD;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,SAAS;EACT,cAAc;AAChB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".modal {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  background-color: #fff;\n  border-radius: 10px;\n  width: 80%;\n  max-height: 80%;\n  overflow: hidden;\n\n  @media (max-width: 1024px) {\n    width: calc(80% - 20px);\n  }\n}\n\n.container {\n  display: flex;\n  box-sizing: border-box;\n  flex-direction: column;\n  width: 100%;\n  padding: 24px;\n  gap: 15px;\n}\n\n.editor {\n  height: 60vh;\n  overflow: auto;\n}\n\n/* Styling for toolbar */\n.toolbar {\n  position: sticky;\n  top: 0; /* Sticky position start */\n  z-index: 1000; /* Ensures the toolbar stays on top */\n  background-color: white; /* Optional for visibility */\n}\n\n.button_group {\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  gap: 10px;\n  padding: 5px 0;\n}\n\n.wrapper {\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `styles_modal__JE6WK`,
	"container": `styles_container__QGN5K`,
	"editor": `styles_editor__FKVRU`,
	"toolbar": `styles_toolbar__qHObJ`,
	"button_group": `styles_button_group__UvSVG`,
	"wrapper": `styles_wrapper__pevV2`
};
export default ___CSS_LOADER_EXPORT___;
