import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../hooks/useAuth';
import { ACTIONS } from '../../constants/actionTables';
import { requestSearch } from '../../helpers/search';

import ModalStatusUser from '../ModalStatusUser';
import ModalUpdateUser from '../ModalUpdateUser';
import ModalStatusMachine from '../ModalStatusMachine';
import ModalAddLocation from '../ModalAddLocation';
import ModalAddMaintanenceTask from '../ModalAddMaintanenceTask';
import ModalAddSupplie from '../ModalAddSupplie';
import ModalDelete from '../ModalDelete';
import ModalAddGroup from '../ModalAddGroup';
import ModalStatusGroup from '../ModalStatusGroup';

import {
  Button,
  IconButton,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

function TableComponent({ search = true, titles, data, action, fetchData }) {
  const [rows, setRows] = useState([]);

  const { t } = useTranslation();
  const { user } = useAuth();

  useEffect(() => {
    setRows(data);
  }, [data]);

  const renderAction = (row) => {
    switch (action) {
      case ACTIONS.USERS_ACTIVE:
        return (
          <div style={{ display: 'flex', gap: '10px', justifyContent: 'end' }}>
            {row.id !== user.id && (
              <>
                <ModalUpdateUser id={row.id} />
                <ModalStatusUser id={row.id} action={'archived'} />
              </>
            )}
          </div>
        );
      case ACTIONS.USERS_ARCHIVED:
        return <ModalStatusUser id={row.id} action={'active'} />;
      case ACTIONS.ACTIVE_MACHINES:
        return (
          <Link to={`/machines/${row.id}`}>
            <Button variant="contained" color="primary" style={{ padding: '5px 15px' }}>
              {t('generic.view')}
            </Button>
          </Link>
        );
      case ACTIONS.ARCHIVED_MACHINES:
        return <ModalStatusMachine id={row.id} action={'non-archived'} />;
      case ACTIONS.ACTIVE_MACHINES_GROUPS:
        return (
          <div style={{ display: 'flex', gap: '10px', justifyContent: 'end' }}>
            {user.role === 'admin' && <ModalAddGroup id={row.id} type={'update'} />}
            <Link to={`/machines-groups/${row.id}`}>
              <Button variant="contained" color="primary" style={{ padding: '5px 15px' }}>
                {t('generic.view')}
              </Button>
            </Link>
          </div>
        );
      case ACTIONS.ARCHIVED_MACHINES_GROUPS:
        return <ModalStatusGroup id={row.id} action={'active'} />;
      case ACTIONS.SPECIFIC_GROUP_MAINTENANCE_TASK:
        return (
          <div style={{ display: 'flex', gap: '10px', justifyContent: 'end' }}>
            {user.role === 'admin' && <ModalAddMaintanenceTask id={row.id} fetchData={fetchData} />}
            {user.role === 'admin' && (
              <ModalDelete id={row.id} name={row.name} type={ACTIONS.TASK} fetchData={fetchData} />
            )}
          </div>
        );
      case ACTIONS.SPECIFIC_GROUP_SUPPLIES:
        return (
          <div style={{ display: 'flex', gap: '10px', justifyContent: 'end' }}>
            <Link to={process.env.REACT_APP_BASE_URL + `supplies/file/${row.link}`} target="_blank">
              <Button variant="contained" color="primary" style={{ padding: '5px 15px' }}>
                {t('generic.view')}
              </Button>
            </Link>
            {user.role === 'admin' && (
              <ModalDelete
                id={row.id}
                name={row.name}
                type={ACTIONS.SUPPLY}
                fetchData={fetchData}
              />
            )}
          </div>
        );
      case ACTIONS.SPECIFIC_GROUP_MANUALS:
        return (
          <div style={{ display: 'flex', gap: '10px', justifyContent: 'end' }}>
            <Link to={process.env.REACT_APP_BASE_URL + `manuals/file/${row.link}`} target="_blank">
              <Button variant="contained" color="primary" style={{ padding: '5px 15px' }}>
                {t('generic.view')}
              </Button>
            </Link>
            {user.role === 'admin' && (
              <ModalDelete
                id={row.id}
                name={row.safetySheet}
                type={ACTIONS.MANUAL}
                fetchData={fetchData}
              />
            )}
          </div>
        );
      case ACTIONS.SPECIFIC_MACHINE_DOCUMENTS:
        return (
          <div style={{ display: 'flex', gap: '10px', justifyContent: 'end' }}>
            <Link
              to={process.env.REACT_APP_BASE_URL + `validation-documents/file/${row.link}`}
              target="_blank"
            >
              <Button variant="contained" color="primary" style={{ padding: '5px 15px' }}>
                {t('generic.view')}
              </Button>
            </Link>
            {user.role === 'admin' && (
              <ModalDelete
                id={row.id}
                name={row.document}
                type={ACTIONS.DOCUMENT}
                fetchData={fetchData}
              />
            )}
          </div>
        );
      case ACTIONS.SPECIFIC_MACHINE_MANUALS:
        return (
          <Link to={process.env.REACT_APP_BASE_URL + `manuals/file/${row.link}`} target="_blank">
            <Button variant="contained" color="primary" style={{ padding: '5px 15px' }}>
              {t('generic.view')}
            </Button>
          </Link>
        );
      case ACTIONS.SPECIFIC_MACHINE_SUPPLY:
        return (
          <Link to={process.env.REACT_APP_BASE_URL + `supplies/file/${row.link}`} target="_blank">
            <Button variant="contained" color="primary" style={{ padding: '5px 15px' }}>
              {t('generic.view')}
            </Button>
          </Link>
        );
      case ACTIONS.SUPPLIES:
        return (
          <div style={{ display: 'flex', gap: '10px', justifyContent: 'end' }}>
            {user.role === 'admin' && <ModalAddSupplie id={row.id} type={'update'} />}

            {!!row.link ? (
              <Link
                to={process.env.REACT_APP_BASE_URL + `supplies/file/${row.link}`}
                target="_blank"
              >
                <Button variant="contained" color="primary" style={{ padding: '5px 15px' }}>
                  {t('generic.view')}
                </Button>
              </Link>
            ) : (
              <Button disabled variant="contained" color="primary" style={{ padding: '5px 15px' }}>
                {t('generic.view')}
              </Button>
            )}

            {user.role === 'admin' && (
              <ModalDelete
                id={row.id}
                name={row.name}
                type={ACTIONS.SUPPLIES}
                flagForDelete={row.flagForDelete}
              />
            )}
          </div>
        );
      case ACTIONS.LOCATIONS:
        return (
          <div style={{ display: 'flex', gap: '10px', justifyContent: 'end' }}>
            {user.role === 'admin' && <ModalAddLocation id={row.id} type={'update'} />}
            {user.role === 'admin' && (
              <ModalDelete
                id={row.id}
                name={row.name}
                flagForDelete={row.flagForDelete}
                type={ACTIONS.LOCATIONS}
              />
            )}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div style={{ width: '100%' }}>
      {search && (
        <Paper
          component="form"
          sx={{
            display: 'flex',
            alignItems: 'center',
            maxWidth: 400,
            mb: 2,
            border: '1px solid #f0f0f0',
          }}
        >
          <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
            <SearchIcon />
          </IconButton>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder={t('generic.search')}
            onChange={(e) => {
              requestSearch(e.target.value, data, setRows);
            }}
          />
        </Paper>
      )}

      <TableContainer component={Paper} sx={{ marginBottom: '24px' }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {titles.map((title, index) => (
                <TableCell key={index} align={title === 'Action' ? 'right' : 'left'}>
                  {title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows && rows.length > 0 ? (
              rows.map((row, rowIndex) => (
                <TableRow key={rowIndex} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  {row.image && (
                    <TableCell key="image" align="left">
                      <img
                        src={row.image}
                        alt=""
                        style={{ maxWidth: '100px', maxHeight: '100px' }}
                      />
                    </TableCell>
                  )}
                  {Object.keys(row).map((key, cellIndex) =>
                    key !== 'image' && key !== 'id' && key !== 'link' && key !== 'flagForDelete' ? (
                      <TableCell key={cellIndex} align="left">
                        {row[key]}
                      </TableCell>
                    ) : null
                  )}
                  <TableCell align="right" sx={{ p: 0, pr: '15px' }}>
                    {renderAction(row)}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={titles.length} align="center">
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default TableComponent;
