import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForgotPasswordMutation } from '../../app/services/auth';

import { Button, FormControl, TextField, Typography } from '@mui/material';

import styles from './style.module.css';
import { Link } from 'react-router-dom';

function ResetPassword() {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const [forgotPassword] = useForgotPasswordMutation();
  const { t } = useTranslation();

  const handleEmailInput = (e) => {
    setEmail(e.target.value);
    setEmailError(false);
    setError('');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!email.includes('@')) {
      setEmailError(true);
      return;
    }

    const formData = {
      email: email,
    };
    try {
      const response = await forgotPassword(formData);
      if (response.error) {
        setError(response.error.data.message);
      } else {
        setMessage(t('signIn.passwordRecoverySent'));
        setEmail('');
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <section className={styles.container}>
      <div className={styles.container_email}>
        <Typography variant="h4" fontWeight="bold" mb={5}>
          {t('signIn.forgotPassword')}
        </Typography>
        <Typography variant="h6" fontWeight="bold" mb={5}>
          {t('signIn.enterEmail')}
        </Typography>
        <form
          onSubmit={handleSubmit}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
            <TextField
              id="outlined-basic"
              label={t('signIn.emailAddress')}
              variant="outlined"
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => handleEmailInput(e)}
              error={emailError}
              inputProps={{
                'aria-describedby': emailError ? 'email-helper-text' : undefined,
              }}
            />
            {emailError && (
              <Typography id="email-helper-text" color="error">
                {t('signIn.invalidEmail')}
              </Typography>
            )}
          </FormControl>

          <Button
            variant="contained"
            type="submit"
            color="primary"
            style={{ marginTop: '10px', width: '100%', height: '56px' }}
          >
            {t('signIn.submit')}
          </Button>
          {error && (
            <Typography
              id="email-helper-text"
              color="error"
              style={{ marginTop: '20px', fontSize: '20px' }}
            >
              {error}
            </Typography>
          )}
          {message && (
            <Typography id="email-helper-text" style={{ marginTop: '20px', fontSize: '20px' }}>
              {message}
            </Typography>
          )}
        </form>
        <Link to="/sign-in" className={styles.link}>
          {t('signIn.backLogin')}
        </Link>
      </div>
    </section>
  );
}

export default ResetPassword;
