import { useState } from 'react';
import { Popover } from '@mui/material';
import { NavLink } from 'react-router-dom';
import styles from './style.module.css';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';

const StyledButton = styled('button')(({ theme }) => ({
  ...theme.typography.h6,
  background: 'none',
  padding: 0,
  border: 'none',
  cursor: 'pointer',
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

function PopoverMachines() {
  const [anchorEl, setAnchorEl] = useState(null);

  const { t } = useTranslation();
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <StyledButton onClick={handleClick} className={styles.machines_button}>
        {t('generic.machines')}
      </StyledButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        style={{ marginTop: '25px' }}
      >
        <div className={styles.menu}>
          <NavLink to="/machines" className={styles.link_menu} onClick={handleClose}>
            {t('generic.machinesList')}
          </NavLink>
          <NavLink to="/machines-groups" className={styles.link_menu} onClick={handleClose}>
            {t('generic.machineGroups')}
          </NavLink>
          <NavLink to="/locations" className={styles.link_menu} onClick={handleClose}>
            {t('generic.locations')}
          </NavLink>
          <NavLink to="/supplies" className={styles.link_menu} onClick={handleClose}>
            {t('generic.supplies')}
          </NavLink>
        </div>
      </Popover>
    </div>
  );
}

export default PopoverMachines;
