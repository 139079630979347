import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";

export const machinesApi = createApi({
  reducerPath: "machinesApi",
  baseQuery,
  endpoints: (builder) => ({
    getMachines: builder.query({
      query: () => "machines",
    }),
    register: builder.mutation({
      query: (machineData) => {
        return {
          url: "machines",
          method: "POST",
          body: machineData,
        };
      },
    }),
    update: builder.mutation({
      query: ({ id, machineData }) => {
        return {
          url: `machines/${id}`,
          method: "PUT",
          body: machineData,
        };
      },
    }),
  }),
});

export const { useGetMachinesQuery, useRegisterMutation, useUpdateMutation } =
  machinesApi;
