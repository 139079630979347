import { useMemo } from 'react'
import { useSelector } from 'react-redux'

export const useUsers = () => {
  const users = useSelector(state => state.users.users);
  const error = useSelector(state => state.users.error);
  const isLoading = useSelector(state => state.users.isLoading);

  return useMemo(() => ({ users, error, isLoading }), [users, error, isLoading]);
}

