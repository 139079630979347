import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../features/auth/authSlice'

export const useAuth = () => {
  const user = useSelector(selectCurrentUser);
  const error = useSelector(state => state.auth.error);
  const isLoading = useSelector(state => state.auth.isLoading);

  return useMemo(() => ({ user, error, isLoading }), [user, error, isLoading]);
}

