import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRegisterMutation } from '../../app/services/users';
import { fetchUsers } from '../../features/users/usersSlice';
import { getPermissions } from '../../constants/options';
import ModalComponent from '../ModalComponent';

import { Button, MenuItem, TextField, Typography } from '@mui/material';

import styles from '../ModalComponent/modalStyles.module.css';
import { useTranslation } from 'react-i18next';

import { validateFormData, getValidationErrorMessage } from '../../helpers';
import { postUserSchema } from '../../schemas/postUserSchema';

const initialForm = {
  firstname: '',
  lastname: '',
  email: '',
  role: 'admin',
};

export default function ModalAddUser() {
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState(initialForm);
  const [errorResponse, setErrorResponse] = useState('');

  const [registerUser, { isLoading, error }] = useRegisterMutation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const permissions = getPermissions(t);

  const [formValidationErrors, setFormValidationErrors] = useState(null);
  const firstNameError = getValidationErrorMessage(formValidationErrors, 'firstname');
  const lastNameError = getValidationErrorMessage(formValidationErrors, 'lastname');
  const emailError = getValidationErrorMessage(formValidationErrors, 'email');
  const roleError = getValidationErrorMessage(formValidationErrors, 'role');

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setForm(initialForm);
    setFormValidationErrors(null);
  };

  useEffect(() => {
    if (error) {
      console.log(error);
      setErrorResponse(error.data.message);
    }
  }, [error]);

  useEffect(() => {
    if (!isLoading && !error) {
      handleClose();
      setErrorResponse('');
      dispatch(fetchUsers());
    }
  }, [isLoading, error, dispatch]);

  const handleChange = (e) => {
    setErrorResponse('');
    if (e.target.name === 'permission') {
      setForm({
        ...form,
        permission: e.target.value,
      });
    } else {
      setForm({
        ...form,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSubmit = async () => {
    const validationErrors = validateFormData(t, postUserSchema, form);
    if (!!validationErrors) {
      setFormValidationErrors(validationErrors);
      return;
    }

    try {
      await registerUser(form);
    } catch (err) {
      console.error('Error registering user:', err);
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        style={{ padding: '8px 22px', textTransform: 'none' }}
      >
        + Add
      </Button>
      <ModalComponent open={open} handleClose={handleClose}>
        <div className={styles.modal}>
          <div className={styles.container}>
            <Typography variant="h5" fontWeight="bold">
              Create a new User
            </Typography>
            <TextField
              label={t('generic.firstName')}
              variant="outlined"
              name="firstname"
              onChange={handleChange}
              fullWidth
              error={!!firstNameError}
              helperText={firstNameError ?? ' '}
            />
            <TextField
              label={t('generic.lastName')}
              variant="outlined"
              name="lastname"
              onChange={handleChange}
              fullWidth
              error={!!lastNameError}
              helperText={lastNameError ?? ' '}
            />
            <TextField
              label={t('generic.email')}
              variant="outlined"
              name="email"
              onChange={handleChange}
              fullWidth
              error={!!emailError}
              helperText={emailError ?? ' '}
            />
            <TextField
              defaultValue="admin"
              select
              label={t('generic.permission')}
              name="role"
              onChange={handleChange}
              error={!!roleError}
              helperText={roleError ?? ' '}
            >
              {permissions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            {errorResponse &&
              (Array.isArray(errorResponse) ? (
                errorResponse.map((item, index) => (
                  <Typography key={index} color="error">
                    {item}
                  </Typography>
                ))
              ) : (
                <Typography color="error">{errorResponse}</Typography>
              ))}

            <div className={styles.button_group}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClose}
                style={{ padding: '5px 22px' }}
              >
                {t('modals.cancel')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                style={{ padding: '5px 22px' }}
              >
                {t('modals.create')}
              </Button>
            </div>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
}
