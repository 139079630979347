import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../baseQuery';

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery,
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => 'users',
    }),
    register: builder.mutation({
      query: (userData) => ({
        url: 'users',
        method: 'POST',
        body: userData,
      }),
    }),
    update: builder.mutation({
      query: ({ id, userData }) => ({
        url: `users/${id}`,
        method: 'PUT',
        body: userData,
      }),
    }),
    updatePassword: builder.mutation({
      query: ({ id, passwords }) => ({
        url: `users/${id}/password`,
        method: 'PUT',
        body: passwords,
      }),
    }),
    disableProviderChanged: builder.mutation({
      query: () => ({
        url: `users/disable-provider-changed`,
        method: 'PUT',
      }),
    }),
  }),
});

export const {
  useGetUsersQuery,
  useRegisterMutation,
  useUpdateMutation,
  useUpdatePasswordMutation,
  useDisableProviderChangedMutation,
} = usersApi;
