import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateMutation } from '../../app/services/users';
import { useTranslation } from 'react-i18next';
import { fetchUsers } from '../../features/users/usersSlice';
import { getLanguages, getPermissions } from '../../constants/options';
import ModalComponent from '../ModalComponent';

import {
  Button,
  FormControl,
  MenuItem,
  TextField,
  Typography,
  FormHelperText,
} from '@mui/material';

import styles from '../ModalComponent/modalStyles.module.css';

import { validateFormData, getValidationErrorMessage } from '../../helpers';
import { putUserSchema } from '../../schemas/putUserSchema';
import { selectUserById } from '../../features/users/usersSlice';

const initialForm = {
  firstname: 'firstname',
  lastname: 'lastname',
  email: 'email',
  language: 'english',
  role: 'admin',
  status: 'active',
};

export default function ModalUpdateUser({ id }) {
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState(initialForm);

  const { t } = useTranslation();
  const permissions = getPermissions(t);
  const languages = getLanguages(t);

  const dispatch = useDispatch();
  const [updateUser] = useUpdateMutation();

  const [formValidationErrors, setFormValidationErrors] = useState(null);
  const firstNameError = getValidationErrorMessage(formValidationErrors, 'firstname');
  const lastNameError = getValidationErrorMessage(formValidationErrors, 'lastname');
  const emailError = getValidationErrorMessage(formValidationErrors, 'email');
  const languageError = getValidationErrorMessage(formValidationErrors, 'language');
  const roleError = getValidationErrorMessage(formValidationErrors, 'role');

  const selectedUser = useSelector((state) => selectUserById(state, id));
  useEffect(() => {
    if (!selectedUser) return;
    setForm(selectedUser);
  }, [selectedUser]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setFormValidationErrors(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case 'role':
        setForm({
          ...form,
          role: value,
        });
        break;
      case 'language':
        setForm({
          ...form,
          language: value,
        });
        break;
      default:
        setForm({
          ...form,
          [name]: value,
        });
    }
  };

  const handleSubmit = async () => {
    try {
      const { id, ...userData } = form;

      const validationErrors = validateFormData(t, putUserSchema, userData);
      if (!!validationErrors) {
        setFormValidationErrors(validationErrors);
        return;
      }

      await updateUser({ id, userData });
      dispatch(fetchUsers());
      setOpen(false);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        style={{ padding: '5px 15px' }}
      >
        {t('generic.edit')}
      </Button>
      <ModalComponent open={open} handleClose={handleClose}>
        <div className={styles.modal}>
          <div className={styles.container}>
            <Typography variant="h5" fontWeight="bold">
              {t('modals.updateUser')}
            </Typography>
            <TextField
              label={t('generic.firstName')}
              variant="outlined"
              name="firstname"
              value={form.firstname}
              onChange={handleChange}
              className={styles.text_field}
              InputLabelProps={{
                shrink: true,
              }}
              error={!!firstNameError}
              helperText={firstNameError ?? ' '}
            />
            <TextField
              label={t('generic.lastName')}
              variant="outlined"
              name="lastname"
              value={form.lastname}
              onChange={handleChange}
              className={styles.text_field}
              InputLabelProps={{
                shrink: true,
              }}
              error={!!lastNameError}
              helperText={lastNameError ?? ' '}
            />
            <TextField
              label={t('generic.email')}
              variant="outlined"
              name="email"
              value={form.email}
              onChange={handleChange}
              className={styles.text_field}
              InputLabelProps={{
                shrink: true,
              }}
              error={!!emailError}
              helperText={emailError ?? ' '}
            />
            <FormControl>
              <TextField
                value={form.language}
                select
                label={t('modals.languagePreferences')}
                name="language"
                onChange={handleChange}
                className={styles.text_field}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                {languages.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <FormHelperText error>{languageError ?? ' '}</FormHelperText>
            </FormControl>

            <FormControl>
              <TextField
                value={form.role}
                select
                label={t('generic.permission')}
                name="role"
                onChange={handleChange}
                className={styles.text_field}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                {permissions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <FormHelperText error>{roleError ?? ' '}</FormHelperText>
            </FormControl>

            <div className={styles.button_group}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClose}
                style={{ padding: '5px 22px' }}
              >
                {t('modals.cancel')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                style={{ padding: '5px 22px' }}
              >
                {t('modals.update')}
              </Button>
            </div>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
}
