import { ZodError } from 'zod';

export const validateFormData = (t, getSchema, formData) => {
  let validationErrors = null;

  const schema = getSchema(t);
  try {
    schema.parse(formData);
  } catch (e) {
    if (e instanceof ZodError) {
      validationErrors = e.format();
    }
  }
  return validationErrors;
};

export const getValidationErrorMessage = (validationErrors, key) =>
  validationErrors ? validationErrors[key]?._errors?.join(' ') : undefined;
