import { Badge, FormControl, TextField, MenuItem, Checkbox, ListItemText } from '@mui/material';

export default function MultiSelectNotify({
  name,
  options,
  selectedOptions,
  availableOptions,
  toggleOption,
  disabled,
  showReportRepair,
  setShowReportRepair,
  type,
}) {
  const onRenderValue = (selected) => {
    return options
      .reduce((value, { id, name }) => {
        if (selected.includes(id)) {
          return [...value, name];
        }
        return value;
      }, [])
      .join(', ');
  };

  const selectAll = () => {
    if (options.length === selectedOptions.length) {
      options.forEach((option) => {
        if (selectedOptions.includes(option.id)) {
          toggleOption(option.id);
        }
      });
    } else {
      options.forEach((option) => {
        if (!selectedOptions.includes(option.id)) {
          toggleOption(option.id);
        }
      });
    }
  };

  return (
    <div>
      {options && (
        <Badge
          badgeContent={' '}
          color="primary"
          invisible={selectedOptions.length === options.length}
        >
          <FormControl sx={{ width: 300 }}>
            <TextField
              select
              label={name}
              disabled={!disabled}
              value={selectedOptions}
              SelectProps={{
                multiple: true,
                renderValue: onRenderValue,
              }}
              focused={false}
            >
              <MenuItem key="selectAll" onClick={selectAll}>
                <Checkbox checked={options.length === selectedOptions.length} />
                <ListItemText primary="Select All" />
              </MenuItem>
              {options.map((item) => (
                <MenuItem
                  key={item.id}
                  value={item.id}
                  onClick={() => toggleOption(item.id)}
                  style={availableOptions.includes(item.id) ? {} : { backgroundColor: 'lightgrey' }}
                >
                  <Checkbox
                    checked={selectedOptions.includes(item.id)}
                    style={availableOptions.includes(item.id) ? {} : { color: 'grey' }}
                  />
                  <ListItemText primary={item.name} />
                </MenuItem>
              ))}
              {type === 'calendar' && (
                <MenuItem
                  key="report"
                  onClick={() => {
                    setShowReportRepair(!showReportRepair);
                  }}
                >
                  <Checkbox checked={showReportRepair} />
                  <ListItemText primary="Report a repair" />
                </MenuItem>
              )}
            </TextField>
          </FormControl>
        </Badge>
      )}
    </div>
  );
}
