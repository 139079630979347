import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSupplies } from '../../hooks/useSupplies';
import { fetchGroupSupplie } from '../../features/groups/groupsSlice';
import ModalComponent from '../ModalComponent';

import {
  Button,
  Checkbox,
  FormGroup,
  IconButton,
  InputBase,
  Paper,
  Typography,
  Box,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import styles from '../ModalComponent/modalStyles.module.css';

export default function ModalAddSupplyGroup({ id, fetchData, selectedSuppliesList }) {
  const [open, setOpen] = useState(false);
  const [errorResponse, setErrorResponse] = useState('');
  const [suppliesList, setSuppliesList] = useState(null);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [filteredSuppliesList, setFilteredSuppliesList] = useState(null);

  const { t } = useTranslation();
  const { supplies } = useSupplies();
  const dispatch = useDispatch();

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setSelectedSuppliers([]);
    setSuppliesList(null);
    setFilteredSuppliesList(null);
  };

  useEffect(() => {
    if (supplies && open) {
      const filteredSupplies = supplies.filter(
        (supply) => !selectedSuppliesList.includes(supply.id)
      );
      setSuppliesList(filteredSupplies);
      setFilteredSuppliesList(filteredSupplies);
    }
  }, [supplies, open, selectedSuppliesList]);

  const handleSupplierToggle = (supplierId) => () => {
    const currentIndex = selectedSuppliers.indexOf(supplierId);
    const newSelectedSuppliers = [...selectedSuppliers];

    if (currentIndex === -1) {
      newSelectedSuppliers.push(supplierId);
    } else {
      newSelectedSuppliers.splice(currentIndex, 1);
    }

    setSelectedSuppliers(newSelectedSuppliers);
  };

  const handleSubmit = async () => {
    try {
      const selectedSuppliersNumbers = selectedSuppliers.map((supplier) => parseInt(supplier));
      const formData = {
        machineGroup: { id },
        machineSupplies: selectedSuppliersNumbers,
      };
      await dispatch(fetchGroupSupplie(formData));
      await fetchData();
      handleClose();
    } catch (err) {
      setErrorResponse(err);
      console.error('Error add document:', err);
    }
  };

  const requestSearch = (searchedVal) => {
    if (!filteredSuppliesList) return;

    const filteredRows = filteredSuppliesList.filter((item) => {
      const lowerCaseSearchedVal = searchedVal.toLowerCase();
      return (
        item.name.toLowerCase().includes(lowerCaseSearchedVal) ||
        item.description.toLowerCase().includes(lowerCaseSearchedVal)
      );
    });
    setSuppliesList(filteredRows);
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        style={{ padding: '5px 22px', textTransform: 'none' }}
      >
        {t('modals.add+')}
      </Button>
      <ModalComponent open={open} handleClose={handleClose}>
        <Box className={styles.modal} sx={{ height: '75vh' }}>
          <div className={styles.container}>
            <Typography variant="h5" fontWeight="bold">
              {t('modals.addSupply')}
            </Typography>
            <Paper
              component="form"
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 2,
                border: '1px solid #f0f0f0',
              }}
            >
              <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                onChange={(e) => {
                  requestSearch(e.target.value);
                }}
              />
            </Paper>

            <FormGroup sx={{ maxHeight: '400px', overflowY: 'auto', flexWrap: 'nowrap' }}>
              {suppliesList && suppliesList.length > 0 ? (
                <table>
                  <thead
                    style={{
                      marginBottom: '10px',
                      height: '30px',
                      position: 'sticky',
                      top: '0',
                      zIndex: '1',
                      backgroundColor: '#fff',
                    }}
                  >
                    <tr>
                      <th></th>
                      <th style={{ textAlign: 'left' }}>{t('generic.number')}</th>
                      <th style={{ textAlign: 'left' }}>{t('generic.name')}</th>
                      <th style={{ textAlign: 'left' }}>{t('generic.description')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {suppliesList.map((supplier) => (
                      <tr key={supplier.id}>
                        <td>
                          <Checkbox
                            checked={selectedSuppliers.includes(supplier.id)}
                            onChange={handleSupplierToggle(supplier.id)}
                            color="primary"
                          />
                        </td>
                        <td>{supplier.number}</td>
                        <td>{supplier.name}</td>
                        <td style={{ maxWidth: '150px' }}>{supplier.description}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <Typography variant="body1">{t('modals.noSupplies')}</Typography>
              )}
            </FormGroup>

            {errorResponse &&
              (Array.isArray(errorResponse) ? (
                errorResponse.map((item, index) => (
                  <Typography key={index} color="error">
                    {item}
                  </Typography>
                ))
              ) : (
                <Typography color="error">{errorResponse}</Typography>
              ))}

            <div className={styles.button_group}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClose}
                style={{ padding: '5px 22px' }}
              >
                {t('modals.cancel')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={suppliesList && suppliesList.length === 0}
                style={{ padding: '5px 22px' }}
              >
                {t('modals.add')}
              </Button>
            </div>
          </div>
        </Box>
      </ModalComponent>
    </div>
  );
}
