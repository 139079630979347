export const ACTIONS = {
  SUPPLIES: "supplies",
  SUPPLY: "supply",
  LOCATIONS: "locations",
  MACHINEGROUPS: "machinegroups",
  MAINTENANCETYPES: "maintenancetypes",
  MACHINES: "machines",
  MANUAL: "manual",
  PROFILE: "profile",
  CALENDAR: "calendar",
  DOCUMENT: "document",
  TASK: "task",
  VIEW: "view",
  EDIT: "edit",
  USERS_ACTIVE: "users_active",
  USERS_ARCHIVED: "users_archived",
  ACTIVE_MACHINES: "active_machines",
  ARCHIVED_MACHINES: "archived_machines",
  ACTIVE_MACHINES_GROUPS: "active_machines_groups",
  ARCHIVED_MACHINES_GROUPS: "archived_machines_groups",
  SPECIFIC_GROUP_MAINTENANCE_TASK: "specific_group_maintenance_task",
  SPECIFIC_GROUP_SUPPLIES: "specific_group_supplies",
  SPECIFIC_GROUP_MANUALS: "specific_group_manuals",
  SPECIFIC_MACHINE_DOCUMENTS: "specific_machine_documents",
  SPECIFIC_MACHINE_MANUALS: "specific_machine_manuals",
  SPECIFIC_MACHINE_SUPPLY: "specific_machine_supply",
  CALENDAR_LIST: 'calendar_list',
};
