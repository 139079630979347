import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useUpdateMutation } from "../../app/services/groups";
import { fetchGroup, fetchGroups } from "../../features/groups/groupsSlice";
import ModalComponent from "../ModalComponent";

import { Button, Typography } from "@mui/material";

import styles from "../ModalComponent/modalStyles.module.css";

export default function ModalStatusGroup({ id, action, setStatus }) {
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState();
  const [errorResponse, setErrorResponse] = useState("");

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [updateGroup] = useUpdateMutation();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (id && open) {
      async function fetchData() {
        try {
          const response = await dispatch(fetchGroup(id));
          setForm(response.payload);
        } catch (error) {
          setErrorResponse(error);
          console.error("Error fetching group:", error);
        }
      }
      fetchData();
    }
  }, [id, open, dispatch]);

  const handleSubmit = async () => {
    try {
      const { id } = form;
      const formData = {};
      formData.status = action;
      await updateGroup({ id, formData });
      dispatch(fetchGroups());
      setOpen(false);
    } catch (error) {
      console.error("Error updating group:", error);
    }
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        style={{ padding: "5px 22px", height: "42px" }}
        onClick={handleOpen}
      >
        {action === "archived" ? t("modals.archive") : t("modals.reactivate")}
      </Button>
      <ModalComponent open={open} handleClose={handleClose}>
        <div className={styles.modal}>
          <div className={styles.container}>
            <Typography variant="h6" fontWeight="bold">
              {t("modals.areSureWant")}{" "}
              {action === "archived"
                ? t("modals.archive")
                : t("modals.reactivate")}{" "}
              {t("modals.thisGroup")}?
            </Typography>

            {form && (
              <Typography variant="h6" style={{ color: "#9747FF" }}>
                {form?.name}
              </Typography>
            )}

            <div className={styles.button_group}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleClose}
                style={{ padding: "5px 22px" }}
              >
                {t("modals.cancel")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                style={{ padding: "5px 22px" }}
              >
                {action === "archived"
                  ? t("modals.archive")
                  : t("modals.reactivate")}
              </Button>
            </div>
            {errorResponse &&
              (Array.isArray(errorResponse) ? (
                errorResponse.map((item, index) => (
                  <Typography key={index} color="error">
                    {item}
                  </Typography>
                ))
              ) : (
                <Typography color="error">{errorResponse}</Typography>
              ))}
          </div>
        </div>
      </ModalComponent>
    </div>
  );
}
