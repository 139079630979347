import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";

export const manualsApi = createApi({
  reducerPath: "manualsApi",
  baseQuery,
  endpoints: (builder) => ({
    getValidDocuments: builder.query({
      query: () => "manuals",
    }),
    register: builder.mutation({
      query: (manualData) => ({
        url: "manuals",
        method: "POST",
        body: manualData,
      }),
    }),
    update: builder.mutation({
      query: ({ id, manualData }) => ({
        url: `manuals/${id}`,
        method: "PUT",
        body: manualData,
      }),
    }),
  }),
});

export const { useGetManualsQuery, useRegisterMutation, useUpdateMutation  } = manualsApi;
