import { createContext, useContext, useEffect } from "react";
import { useAuth } from "../hooks/useAuth";
import i18n from "./index";

export const LanguageContext = createContext();

export function useLanguage() {
  return useContext(LanguageContext);
}

function LocaleProvider({ children }) {
  const { user } = useAuth();

  useEffect(() => {
    if (user?.language) {
      let lang = "en";
      if (user.language === "german") {
        lang = "de";
      }
      i18n.changeLanguage(lang);
    }
  }, [user]);

  return (
    <LanguageContext.Provider value={{ user }}>
      {children}
    </LanguageContext.Provider>
  );
}

export default LocaleProvider;
