import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRegisterMutation, useUpdateMutation } from '../../app/services/locations';
import { fetchLocation, fetchLocations } from '../../features/locations/locationsSlice';
import ModalComponent from '../ModalComponent';
import { useTranslation } from 'react-i18next';

import { Button, TextField, Typography } from '@mui/material';

import styles from '../ModalComponent/modalStyles.module.css';
import { validateFormData, getValidationErrorMessage } from '../../helpers';
import { postLocationSchema } from '../../schemas';

const initialForm = {
  name: '',
  description: '',
};

export default function ModalAddLocation({ id, type }) {
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState(initialForm);
  const [errorResponse, setErrorResponse] = useState('');
  const [registerLocations, { error }] = useRegisterMutation();

  const [formValidationErrors, setFormValidationErrors] = useState(null);
  const locationNameError = getValidationErrorMessage(formValidationErrors, 'name');
  const locationDescrError = getValidationErrorMessage(formValidationErrors, 'description');

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [updateLocation] = useUpdateMutation();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setForm(initialForm);
    setFormValidationErrors(null);
  };

  useEffect(() => {
    if (id && open) {
      async function fetchData() {
        try {
          const response = await dispatch(fetchLocation(id));
          setForm({
            name: response.payload.name,
            description: response.payload.description,
          });
        } catch (error) {
          console.error('Error fetching location:', error);
        }
      }
      fetchData();
    }
  }, [id, open, dispatch]);

  useEffect(() => {
    if (error) {
      console.log(error);
      setErrorResponse(error.data.message);
    }
  }, [error]);

  const handleChange = (e) => {
    setErrorResponse('');
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    const validationErrors = validateFormData(t, postLocationSchema, form);
    if (!!validationErrors) {
      setFormValidationErrors(validationErrors);
      return;
    }
    if (id) {
      try {
        await updateLocation({ id, form });
        handleClose();
        dispatch(fetchLocations());
      } catch (err) {
        console.error('Error registering location:', err);
      }
    } else {
      try {
        await registerLocations(form);
        handleClose();
        dispatch(fetchLocations());
      } catch (err) {
        console.error('Error registering location:', err);
      }
    }
  };

  return (
    <div>
      {type === 'add' ? (
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpen}
          style={{ padding: '8px 22px', textTransform: 'none' }}
        >
          {t('modals.add+')}
        </Button>
      ) : (
        <Button
          variant="outlined"
          color="primary"
          onClick={handleOpen}
          style={{ padding: '5px 15px' }}
        >
          {t('generic.edit')}
        </Button>
      )}
      <ModalComponent open={open} handleClose={handleClose}>
        <div className={styles.modal}>
          <div className={styles.container}>
            <Typography variant="h5" fontWeight="bold">
              {type === 'add' ? t('modals.createLocation') : t('modals.updateLocation')}
            </Typography>
            <TextField
              label={t('generic.name')}
              variant="outlined"
              value={form.name}
              name="name"
              onChange={handleChange}
              fullWidth
              error={!!locationNameError}
              helperText={locationNameError ?? ' '}
            />
            <TextField
              label={t('generic.description')}
              variant="outlined"
              value={form.description}
              name="description"
              onChange={handleChange}
              fullWidth
              error={!!locationDescrError}
              helperText={locationDescrError ?? ' '}
            />

            {errorResponse &&
              (Array.isArray(errorResponse) ? (
                errorResponse.map((item, index) => (
                  <Typography key={index} color="error">
                    {item}
                  </Typography>
                ))
              ) : (
                <Typography color="error">{errorResponse}</Typography>
              ))}

            <div className={styles.button_group}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClose}
                style={{ padding: '5px 22px' }}
              >
                {t('modals.cancel')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                style={{ padding: '5px 22px' }}
              >
                {type === 'add' ? t('modals.create') : t('modals.update')}
              </Button>
            </div>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
}
