import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

export const fetchMachines = createAsyncThunk('machines/fetchMachines', async () => {
  try {
    const token = Cookies.get('token');
    const response = await fetch(process.env.REACT_APP_BASE_URL + 'machines', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const fetchMachine = createAsyncThunk('machines/fetchMachine', async (id) => {
  try {
    const token = Cookies.get('token');
    const response = await fetch(process.env.REACT_APP_BASE_URL + `machines/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const fetchMachineOutTask = createAsyncThunk('machines/fetchMachineOutTask', async (id) => {
  try {
    const token = Cookies.get('token');
    const response = await fetch(process.env.REACT_APP_BASE_URL + `machines/${id}/maintenances`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const fetchMachineUpdateTask = createAsyncThunk(
  'machines/fetchMachineUpdateTask',
  async (formData) => {
    try {
      const token = Cookies.get('token');
      const response = await fetch(process.env.REACT_APP_BASE_URL + `machines/create-update-task`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      const data = await response.json();

      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const fetchMachineRepairReport = createAsyncThunk(
  'machines/fetchMachineRepairReport',
  async (formData) => {
    try {
      const token = Cookies.get('token');
      const response = await fetch(
        process.env.REACT_APP_BASE_URL + `machines/machine/report-a-repair`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
      const data = await response.json();

      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

const machinesSlice = createSlice({
  name: 'machines',
  initialState: {
    machines: [],
    isLoading: false,
    error: null,
  },
  reducers: {
    removeMachines: (state) => {
      state.machines = null;
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMachines.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchMachines.fulfilled, (state, action) => {
      state.isLoading = false;
      state.machines = action.payload;
      state.error = null;
    });
    builder.addCase(fetchMachines.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export const { removeMachines } = machinesSlice.actions;

export default machinesSlice.reducer;

export const selectMachines = (state) => state.machines.machines;
