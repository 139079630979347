// import { LogLevel } from '@azure/msal-browser';

const isProd = process.env.REACT_APP_SITE_URL;

export const azureConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}`,
    redirectUri: isProd
      ? `${process.env.REACT_APP_SITE_URL}/msal-redirect.html`
      : 'http://localhost:3001/msal-redirect.html',
    postLogoutRedirectUri: isProd ? `${process.env.REACT_APP_SITE_URL}` : 'http://localhost:3001',
  },
  cache: {
    cacheLocation: 'memoryStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    allowNativeBroker: false,
    // loggerOptions: {
    //   loggerCallback: (level, message, containsPii) => {
    //     if (containsPii) {
    //       return;
    //     }
    //     switch (level) {
    //       case LogLevel.Error:
    //         console.error('Error: ', message);
    //         return;
    //       case LogLevel.Info:
    //         console.info('Info: ', message);
    //         return;
    //       case LogLevel.Verbose:
    //         console.debug('Verbose: ', message);
    //         return;
    //       case LogLevel.Warning:
    //         console.warn('Warning: ', message);
    //         return;
    //       default:
    //         return;
    //     }
    //   },
    // },
  },
};

export const loginRequest = {
  scopes: ['User.Read'],
};
