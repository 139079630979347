import { useMemo } from 'react'
import { useSelector } from 'react-redux'

export const useMaintenanceTypes = () => {
  const maintenanceTypes = useSelector(state => state.maintenanceTypes.maintenanceTypes);
  const error = useSelector(state => state.maintenanceTypes.error);
  const isLoading = useSelector(state => state.maintenanceTypes.isLoading);

  return useMemo(() => ({ maintenanceTypes, error, isLoading }), [maintenanceTypes, error, isLoading]);
}
