import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { MsalProvider } from '@azure/msal-react';

import { AppRoutes } from './routes/AppRoutes';
import fetchData from './helpers/fetchData.js';
import LocaleProvider from './i18n/LocaleProvider.js';
import ThemeMuiProvider from './providers/ThemeMuiProvider.js';
import './App.css';

function App(props) {
  const { pca } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    fetchData(dispatch);
  }, [dispatch]);

  return (
    <ThemeMuiProvider>
      <MsalProvider instance={pca}>
        <LocaleProvider>
          <AppRoutes />
        </LocaleProvider>
      </MsalProvider>
    </ThemeMuiProvider>
  );
}

export default App;
