import { removeAuditLogs } from "../features/auditLogs/auditLogsSlice";
import { removeUser } from "../features/auth/authSlice";
import { resetFilters } from "../features/filters/filtersSlice";
import { removeGroups } from "../features/groups/groupsSlice";
import { removeLocations } from "../features/locations/locationsSlice";
import { removeMachines } from "../features/machines/machinesSlice";
import { removeMaintenanceTypes } from "../features/maintenanceTypes/maintenanceTypesSlice";
import { removeMaintenances } from "../features/maintenances/maintenancesSlice";
import { resetNotifications } from "../features/notifications/notificationsSlice";
import { removeSupplies } from "../features/supplies/suppliesSlice";
import { removeUsers } from "../features/users/usersSlice";

function removeData(dispatch) {
  dispatch(removeUser());
  dispatch(removeUsers());
  dispatch(removeMachines());
  dispatch(removeGroups());
  dispatch(removeLocations());
  dispatch(removeMaintenances());
  dispatch(removeMaintenanceTypes());
  dispatch(removeSupplies());
  dispatch(removeAuditLogs());
  dispatch(resetNotifications());
  dispatch(resetFilters());
}

export default removeData;
