import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

export const fetchAuditLogs = createAsyncThunk('auditLogs/fetchAuditLogs', async () => {
  try {
    const token = Cookies.get('token');
    const response = await fetch(process.env.REACT_APP_BASE_URL + 'audit-logs', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const fetchAuditLog = createAsyncThunk('auditlogs/fetchAuditLog', async (model) => {
  try {
    const token = Cookies.get('token');
    const response = await fetch(process.env.REACT_APP_BASE_URL + `audit-logs/${model}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

const auditLogsSlice = createSlice({
  name: 'auditLogs',
  initialState: {
    auditLogs: [],
    isLoading: false,
    error: null,
  },
  reducers: {
    removeAuditLogs: (state) => {
      state.auditLogs = null;
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAuditLogs.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAuditLogs.fulfilled, (state, action) => {
      state.isLoading = false;
      state.auditLogs = action.payload;
      state.error = null;
    });
    builder.addCase(fetchAuditLogs.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export const { removeAuditLogs } = auditLogsSlice.actions;

export default auditLogsSlice.reducer;

export const selectAuditLogs = (state) => state.auditLogs.auditLogs;
