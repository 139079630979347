import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

export const fetchLocations = createAsyncThunk('locations/fetchLocations', async () => {
  try {
    const token = Cookies.get('token');
    const response = await fetch(process.env.REACT_APP_BASE_URL + 'locations', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const fetchLocation = createAsyncThunk('locations/fetchLocation', async (id) => {
  try {
    const token = Cookies.get('token');
    const response = await fetch(process.env.REACT_APP_BASE_URL + `locations/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const deleteLocation = createAsyncThunk('locations/deleteLocation', async (id) => {
  try {
    const token = Cookies.get('token');
    await fetch(process.env.REACT_APP_BASE_URL + `locations/${id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
});

const locationsSlice = createSlice({
  name: 'locations',
  initialState: {
    locations: [],
    isLoading: false,
    error: null,
  },
  reducers: {
    removeLocations: (state) => {
      state.locations = null;
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLocations.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchLocations.fulfilled, (state, action) => {
      state.isLoading = false;
      state.locations = action.payload;
      state.error = null;
    });
    builder.addCase(fetchLocations.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export const { removeLocations } = locationsSlice.actions;

export default locationsSlice.reducer;

export const selectLocations = (state) => state.locations.locations;
