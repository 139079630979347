import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import draftToHtml from 'draftjs-to-html';
import { fetchMachineUpdateTask } from '../../features/machines/machinesSlice';
import { formatDate, formatDateForReport } from '../../helpers/formatDate';
import { getIntervals, getStatusesTask } from '../../constants/options';
import { ACTIONS } from '../../constants/actionTables';
import { INPUT_FILE_ACCEPT_DOC } from '../../constants';
import ModalComponent from '../ModalComponent';

import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import styles from './styles.module.css';

const initialForm = {
  comments: '',
  status: '',
};

export default function ModalMaintenanceTask({
  task,
  type,
  fetchdata,
  view,
  machineGroupName,
  machineGroupImg,
}) {
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState(initialForm);
  const [errorResponse, setErrorResponse] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [taskData, setTaskData] = useState();
  const [descHtml, setDescHtml] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isTaskCompletedOnTime, setIsTaskCompletedOnTime] = useState(null);
  const [backgroundTask, setBackgroundTask] = useState(null);
  const [borderColorTask, setBorderColorTask] = useState(null);

  const { t } = useTranslation();
  const statusesTask = getStatusesTask(t);
  const intervals = getIntervals(t);
  const dispatch = useDispatch();

  useEffect(() => {
    if (task && type === ACTIONS.PROFILE) {
      const interval = JSON.parse(task.maintenance.interval);
      const description = JSON.parse(task.maintenance.description);

      setTaskData({
        machineId: task.machine.id,
        maintenanceId: task.maintenance.id,
        createdAt: task.created_at,
        updatedAt: task.updated_at,
        intervalType: interval.type,
        intervalValue: interval.value,
        notifyTime: task.maintenance.notification_lead_time,
        status: task.status,
        machineName: task.machine.name,
        machineNumber: task.machine.number,
        groupName: machineGroupName,
        groupImage: machineGroupImg,
        maintenanceName: task.maintenance.name,
        filename: task.filename,
        original_filename: task.original_filename,
        comment: task.comment,
        description,
      });
    }
    if (task && type === ACTIONS.CALENDAR) {
      setTaskData({
        machineId: task.extendedProps?.machineId || task.machineId || '',
        maintenanceId: task.extendedProps?.maintanenceId || task.maintanenceId || '',
        createdAt: task.extendedProps?.createdAt || task.createdAt || '',
        updatedAt: task.extendedProps?.updatedAt || task.updatedAt || '',
        completedAt: task.extendedProps?.completedAt || task.completedAt || '',
        status: task.extendedProps?.status || task.status || '',
        machineName: task.extendedProps?.machineName || task.machineName || '',
        machineNumber: task.extendedProps?.machineNumber || task.machineNumber || '',
        groupName: task.extendedProps?.groupName || task.groupName || '',
        groupImage: task.extendedProps?.groupImage || task.groupImage || '',
        maintenanceName: task.extendedProps?.maintenanceName || task.maintenanceName || '',
        filename: task.extendedProps?.filename || task.filename || '',
        original_filename: task.extendedProps?.original_filename || task.original_filename || '',
        comment: task.extendedProps?.comment || task.comment || '',
        intervalType: task.extendedProps?.intervalType || task.intervalType || '',
        intervalValue: task.extendedProps?.intervalValue || task.intervalValue || '',
        notifyTime: task.extendedProps?.notifyTime || task.notifyTime || '',
        description: task?.extendedProps?.description || task.description || '',
        title: task._def?.title || task.maintenanceName || '',
        responsibleLastname:
          task?.extendedProps?.responsibleLastname || task?.responsibleLastname || '',
        responsibleFirstname:
          task?.extendedProps?.responsibleFirstname || task?.responsibleFirstname || '',
      });
    }
  }, [task, type]);

  const today = new Date().toISOString().split('T')[0];

  useEffect(() => {
    if (taskData?.description) {
      setDescHtml(draftToHtml(JSON.parse(taskData.description)));
    }

    if (taskData && taskData.maintenanceName !== 'Report a repair') {
      const updatedAt = new Date(taskData.updatedAt);
      const createdAt = new Date(taskData.createdAt);
      setIsTaskCompletedOnTime(updatedAt <= createdAt);
    }

    if (taskData) {
      if (taskData?.status === 'outdated') {
        setBackgroundTask('#FDD2D2');
        setBorderColorTask('#963333');
      }

      if (formatDateTask(taskData?.updatedAt) === today) {
        setBackgroundTask('#FAF7E7');
        setBorderColorTask('#D4BD35');
      }

      if (taskData?.status === 'not perfomed') {
        setBackgroundTask('#FFF');
        setBorderColorTask('#000');
      }

      if (taskData?.status === 'incompleted') {
        setBackgroundTask('#FFEBEB');
        setBorderColorTask('#B44F4F');
      }

      if (taskData?.status === 'completed') {
        if (taskData?.maintenanceName === 'Report a repair') {
          setBackgroundTask('#EDF9FF');
          setBorderColorTask('#3F97BE');
        } else if (formatDateTask(taskData?.updatedAt) > formatDateTask(taskData?.createdAt)) {
          setBackgroundTask('#FFEBEB');
          setBorderColorTask('#B44F4F');
        } else {
          setBackgroundTask('#EDF8F2');
          setBorderColorTask('#4FB47E');
        }
      }
    }
  }, [taskData]);

  const handleOpen = () => {
    setOpen(true);
    setScrollPosition(window.pageYOffset);
  };

  const handleClose = () => {
    setOpen(false);
    setForm(initialForm);
    setSelectedFile(null);
    setErrorResponse('');
  };

  useEffect(() => {
    if (!open) {
      window.scrollTo(0, scrollPosition);
    }
  }, [open, scrollPosition]);

  const handleChange = (e) => {
    setErrorResponse('');
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    setErrorResponse('');
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      if (selectedFile) {
        formData.append('file', selectedFile);
      }
      formData.append('comment', form.comments);
      formData.append('status', form.status);
      formData.append('date', formatDateTask(taskData.createdAt));
      formData.append('machine', JSON.stringify({ id: taskData.machineId }));
      formData.append('maintenance', JSON.stringify({ id: taskData.maintenanceId }));
      formData.append('title', taskData.title);
      formData.append('description', taskData.description);

      if (taskData.status === 'outdated' && formatDateTask(taskData?.updatedAt) !== today) {
        if (!form.comments.trim()) {
          setErrorResponse(t('modals.commentNotEmpty'));
          return;
        }
      }

      const response = await dispatch(fetchMachineUpdateTask(formData));
      if (response.payload?.error || response.payload?.statusCode === 500) {
        setErrorResponse(response.payload.message);
      } else {
        fetchdata();
        handleClose();
      }
    } catch (err) {
      console.error('Error registering task:', err);
    }
  };

  function formatDateTask(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  function isTaskOutdated(taskDate, interval) {
    const currentDate = new Date();
    const taskUpdatedAt = new Date(taskDate);
    taskUpdatedAt.setDate(taskUpdatedAt.getDate() - interval);
    return taskUpdatedAt < currentDate;
  }

  function intervalText(intervalType, intervalValue) {
    const findInterval = intervals.find(
      (interval) => interval.value.type === intervalType && interval.value.value === intervalValue
    );
    return findInterval.period;
  }

  const isReadonly =
    taskData &&
    taskData.status === 'not perfomed' &&
    !isTaskOutdated(taskData.updatedAt, taskData.notifyTime);

  return (
    <div>
      {taskData && type === ACTIONS.PROFILE && (
        <Tooltip
          title={
            !isTaskOutdated(taskData.updatedAt, taskData.notifyTime)
              ? t('modals.notAvailable')
              : null
          }
          placement="top"
        >
          <span>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpen}
              disabled={!isTaskOutdated(taskData.updatedAt, taskData.notifyTime)}
              style={{
                textTransform: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
                padding: '4px 15px',
                borderRadius: '4px',
                border: taskData.status === 'outdated' ? '1px dashed #B44F4F' : '1px dashed #000',
                background: taskData.status === 'outdated' ? '#F8EDED' : '#fff',
                width: '100%',
                color: 'black',
              }}
            >
              <Typography
                variant="subtitle2"
                fontWeight="bold"
                style={{
                  width: '100px',
                  wordWrap: 'break-word',
                  textAlign: 'left',
                }}
                className="line-clamp"
              >
                {taskData.maintenanceName}
              </Typography>
              <Typography variant="subtitle2" fontWeight="bold">
                {formatDate(taskData.createdAt)}
              </Typography>
            </Button>
          </span>
        </Tooltip>
      )}
      {taskData && type === ACTIONS.CALENDAR && view && (
        <Tooltip
          title={
            taskData.status === 'not perfomed' &&
            !isTaskOutdated(taskData.updatedAt, taskData.notifyTime)
              ? t('modals.notAvailable')
              : null
          }
          placement="top"
        >
          <span>
            <Button
              variant="text"
              onClick={() => {
                if (isReadonly) return;
                handleOpen();
              }}
              style={{
                textTransform: 'none',
                display: 'flex',
                justifyContent: 'flex-start',
                padding: '0px 10px',
                borderRadius: '4px',
                border: `1px solid ${borderColorTask}`,
                background: backgroundTask,
                width: '100%',
                color: 'black',
              }}
              disableRipple={isReadonly}
            >
              <div>
                <Typography
                  variant="subtitle1"
                  style={{
                    wordWrap: 'break-word',
                    textAlign: 'left',
                    textWrap: 'wrap',
                  }}
                >
                  {taskData.maintenanceName === 'Report a repair'
                    ? t('modals.reportRepair')
                    : taskData.maintenanceName}
                </Typography>
                <Typography
                  variant="subtitle2"
                  style={{
                    wordWrap: 'break-word',
                    textAlign: 'left',
                    textWrap: 'wrap',
                  }}
                >
                  <Link
                    onClick={(e) => e.stopPropagation()}
                    to={`machines/${taskData.machineId}`}
                    style={{ color: '#000' }}
                  >
                    {taskData.machineNumber} {taskData.machineName}
                  </Link>
                </Typography>
              </div>
            </Button>
          </span>
        </Tooltip>
      )}
      {taskData && type === ACTIONS.CALENDAR && !view && (
        <Tooltip
          title={
            taskData.status === 'not perfomed' &&
            !isTaskOutdated(taskData.updatedAt, taskData.notifyTime)
              ? t('modals.notAvailable')
              : null
          }
          placement="top"
        >
          <span>
            <Button
              variant="text"
              onClick={handleOpen}
              disabled={
                taskData.status === 'not perfomed' &&
                !isTaskOutdated(taskData.updatedAt, taskData.notifyTime)
              }
              style={{
                display: 'flex',
                padding: '5px 15px',
                borderRadius: '4px',
                border: `1px solid ${borderColorTask}`,
                background: backgroundTask,
                color: 'black',
              }}
            >
              <Typography variant="subtitle1">{t('generic.view')}</Typography>
            </Button>
          </span>
        </Tooltip>
      )}

      {taskData && (
        <ModalComponent open={open} handleClose={handleClose}>
          <div className={styles.modal}>
            <div className={styles.container}>
              {taskData && (
                <div className={styles.header}>
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}machine-groups/image/${taskData.groupImage}`}
                    alt=""
                    style={{ maxWidth: '90px', maxHeight: '68px' }}
                  />
                  <div className={styles.title}>
                    <p className={styles.title_group}>{taskData.groupName}</p>
                    <p className={styles.title_machine}>
                      {taskData.machineNumber} {taskData.machineName}
                    </p>
                  </div>
                </div>
              )}
              <Divider />

              <div className={styles.content}>
                <div className={styles.description}>
                  {taskData.maintenanceName !== 'Report a repair' && (
                    <p className={styles.description_title}>
                      {intervalText(taskData.intervalType, taskData.intervalValue)}
                      {t('calendar.dayInterval')}
                      {formatDateForReport(taskData.createdAt, 'date')}
                    </p>
                  )}
                  <p className={styles.description_task}>{t('modals.taskDescription')}</p>
                  {taskData.maintenanceName === 'Report a repair' ? (
                    <p className={styles.description_task__desc}>{t('modals.pleaseProvide')}</p>
                  ) : (
                    <div
                        dangerouslySetInnerHTML={{ __html: descHtml }}
                        style={{ marginLeft: '20px' }}
                    />
                  )}
                </div>
                {type === ACTIONS.CALENDAR &&
                (taskData.status === 'completed' || taskData.status === 'incompleted') ? (
                  <div className={styles.fields}>
                    {taskData.maintenanceName === 'Report a repair' && (
                      <div
                        className={styles.completed}
                        style={{
                          backgroundColor: '#EDF9FF',
                          border: '1px solid #3F97BE',
                        }}
                      >
                        {t('modals.repair')}
                      </div>
                    )}
                    {isTaskCompletedOnTime === true && (
                      <div
                        className={styles.completed}
                        style={{
                          backgroundColor: '#EDF8F2',
                          border: '1px solid #4FB47E',
                        }}
                      >
                        {t('calendar.completedEarlier')}
                      </div>
                    )}
                    {isTaskCompletedOnTime === false && (
                      <div
                        className={styles.completed}
                        style={{
                          backgroundColor: '#F8EDED',
                          border: '1px solid #B44F4F',
                        }}
                      >
                        {taskData.status !== 'incompleted'
                          ? t('calendar.completedLate')
                          : t('calendar.incomplete')}
                      </div>
                    )}
                    {isTaskCompletedOnTime !== null && <Divider />}
                    <p className={styles.description_task}>{t('modals.comments')}:</p>
                    {taskData.comment ? (
                      <p className={styles.comments}>{taskData.comment}</p>
                    ) : (
                      <p className={styles.comments}>N/A</p>
                    )}
                    {taskData?.filename ? (
                      <Link
                        to={
                          taskData.maintenanceName !== 'Report a repair'
                            ? process.env.REACT_APP_BASE_URL +
                              `machines/filename/${taskData.filename}`
                            : process.env.REACT_APP_BASE_URL +
                              `machines/report/${taskData.filename}`
                        }
                        style={{ alignSelf: 'flex-end', color: '#000', wordBreak: 'break-word' }}
                        target="_blank"
                      >
                        {taskData.original_filename}
                      </Link>
                    ) : (
                      <p style={{ alignSelf: 'flex-end' }}>{t('modals.noFileAttached')}</p>
                    )}
                    <Divider />
                    <p style={{ alignSelf: 'flex-end' }}>
                      {t('modals.performedBy')} {taskData.responsibleFirstname}{' '}
                      {taskData.responsibleLastname} on {formatDateForReport(taskData.completedAt)}
                    </p>
                  </div>
                ) : (
                  <div className={styles.fields}>
                    <TextField
                      label={t('modals.comments')}
                      placeholder={
                        formatDateTask(taskData?.updatedAt) < today
                          ? t('modals.expalainDelay')
                          : t('modals.provideSpecific')
                      }
                      variant="outlined"
                      value={form.comments}
                      name="comments"
                      onChange={handleChange}
                      multiline
                      rows={5}
                      fullWidth
                    />
                    <TextField
                      select
                      value={form.status}
                      variant="outlined"
                      label={t('generic.status')}
                      name="status"
                      fullWidth
                      onChange={handleChange}
                    >
                      {statusesTask.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <FormControl>
                      <Button
                        component="label"
                        variant="outlined"
                        sx={{
                          color: 'rgba(0, 0, 0, 0.6)',
                          borderColor: 'rgba(0, 0, 0, 0.3)',
                          fontSize: '16px',
                          height: '56px',
                          textTransform: 'none',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          '&:hover': {
                            borderColor: 'rgba(0, 0, 0, 1)',
                            background: 'none',
                          },
                        }}
                      >
                        <input
                          accept={INPUT_FILE_ACCEPT_DOC}
                          id="fileInput"
                          type="file"
                          style={{ display: 'none' }}
                          onChange={handleFileChange}
                        />
                        {selectedFile ? selectedFile.name : t('modals.uploadFile')}
                      </Button>
                      <FormHelperText>{t('generic.uploadFileMessage')}</FormHelperText>
                    </FormControl>
                    {errorResponse &&
                      (Array.isArray(errorResponse) ? (
                        errorResponse.map((item, index) => (
                          <Typography key={index} color="error">
                            {item}
                          </Typography>
                        ))
                      ) : (
                        <Typography color="error">{errorResponse}</Typography>
                      ))}
                  </div>
                )}
              </div>

              <div className={styles.button_group}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleClose}
                  style={{ padding: '5px 22px' }}
                >
                  {t('modals.close')}
                </Button>
                {taskData.status !== 'completed' && taskData.status !== 'incompleted' && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    style={{ padding: '5px 22px' }}
                  >
                    {t('modals.done')}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </ModalComponent>
      )}
    </div>
  );
}
