import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ACTIONS } from '../../constants/actionTables';
import dayjs from 'dayjs';
import { formatDateForReport } from '../../helpers/formatDate';
import { requestSearch } from '../../helpers/search';
import ModalMaintenanceTask from '../../components/ModalMaintenanceTask';
import TablePaginationActions from '../../components/TablePaginationActions';

import { InputBase, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableHead } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import styles from './style.module.css';

import { useDatePickerLocalValue } from '../../hooks';
import 'dayjs/locale/de';

function CalendarList({ taskData, setStartDate, setEndDate, fetchData, startDate, endDate }) {
  const [rowsData, setRowsData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { t } = useTranslation();

  useEffect(() => {
    if (rowsData.length <= rowsPerPage) {
      setPage(0);
    }
  }, [rowsData, rowsPerPage]);

  useEffect(() => {
    const sortedTaskData = [...taskData].sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);

      return dateA - dateB;
    });
    setRowsData(sortedTaskData);
  }, [taskData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const titles = [
    t('generic.number'),
    t('generic.name'),
    t('generic.task'),
    t('generic.maintenanceType'),
    t('generic.status'),
    t('generic.scheduledDate'),
    t('generic.action'),
  ];

  const { value: localStartDate, setValue: setLocalStartDate } = useDatePickerLocalValue(
    startDate,
    setStartDate
  );

  const { value: localEndDate, setValue: setLocalEndDate } = useDatePickerLocalValue(
    endDate,
    setEndDate
  );

  const handleStartDateChange = (date) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DDTHH:mm:ssZ');
    setStartDate(formattedDate);
    setLocalStartDate(date);
  };

  const handleEndDateChange = (date) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DDTHH:mm:ssZ');
    setEndDate(formattedDate);
    setLocalEndDate(date);
  };

  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    const searchFields = ['machineNumber', 'machineName', 'status', 'title'];
    requestSearch(searchValue, taskData, setRowsData, searchFields);
  }, [searchValue, taskData, setRowsData]);

  const tableContainerRef = useRef(null);
  useEffect(() => {
    tableContainerRef.current?.scrollIntoView();
  }, [page]);

  return (
    <div>
      <div className={styles.header}>
        <div className={styles.options}>
          <Paper
            component="form"
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: 300,
              height: 52,
              border: '1px solid #ddd',
            }}
          >
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder={t('generic.search')}
              onChange={(e) => {
                // requestSearch(e.target.value, taskData, setRowsData, searchFields);
                setSearchValue(e.target.value);
              }}
              value={searchValue}
            />
          </Paper>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
            <DatePicker
              label={t('generic.from')}
              onChange={handleStartDateChange}
              fullWidth
              sx={{
                '& .MuiInputBase-root': {
                  width: '300px',
                },
              }}
              value={localStartDate}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
            <DatePicker
              label={t('generic.to')}
              onChange={handleEndDateChange}
              fullWidth
              sx={{
                '& .MuiInputBase-root': {
                  width: '300px',
                },
              }}
              value={localEndDate}
            />
          </LocalizationProvider>
        </div>
      </div>
      {taskData.length ? (
        <TableContainer component={Paper} ref={tableContainerRef}>
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                {titles.map((title, index) => (
                  <TableCell key={index}>{title}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            {rowsData && (
              <TableBody>
                {(rowsPerPage > 0
                  ? rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : rowsData
                ).map((row) => (
                  <TableRow key={row.id}>
                    <TableCell align="left">{row.machineNumber}</TableCell>
                    <TableCell align="left">{row.machineName}</TableCell>
                    <TableCell align="left">
                      {row.maintenanceName === 'Report a repair'
                        ? t('modals.reportRepair')
                        : row.maintenanceName}
                    </TableCell>
                    <TableCell align="left">
                      {row.maintenanceType === 'Report a repair'
                        ? t('modals.reportRepair')
                        : row.maintenanceType}
                    </TableCell>

                    <TableCell align="left">{t(`calendar.${row.status}`)}</TableCell>
                    <TableCell align="left">{formatDateForReport(row.createdAt, 'date')}</TableCell>
                    <TableCell align="left" style={{ padding: 0 }}>
                      <ModalMaintenanceTask
                        task={row}
                        view={false}
                        type={ACTIONS.CALENDAR}
                        fetchdata={fetchData}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}

            <TableFooter>
              <TableRow></TableRow>
            </TableFooter>
          </Table>
          <div
            style={{
              display: 'flex',
              boxSizing: 'border-box',
              width: '100%',
              justifyContent: 'flex-end',
              paddingRight: '15px',
            }}
          >
            {rowsData && (
              <TablePagination
                rowsPerPageOptions={[5, 10, 15, 25, { label: 'All', value: -1 }]}
                colSpan={3}
                count={rowsData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                align="right"
                labelRowsPerPage={t('calendar.rowsPerPage')}
                slotProps={{
                  select: {
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  },
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            )}
          </div>
        </TableContainer>
      ) : (
        <Typography
          variant="h5"
          fontWeight="bold"
          style={{ color: 'black', textTransform: 'none' }}
        >
          NO DATA
        </Typography>
      )}
    </div>
  );
}

export default CalendarList;
