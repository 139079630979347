import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";

export const groupsApi = createApi({
  reducerPath: "groupsApi",
  baseQuery,
  endpoints: (builder) => ({
    getGroups: builder.query({
      query: () => "machine-groups",
    }),
    register: builder.mutation({
      query: (groupsData) => ({
        url: "machine-groups",
        method: "POST",
        body: groupsData,
      }),
    }),
    update: builder.mutation({
      query: ({ id, formData }) => ({
        url: `machine-groups/${id}`,
        method: "PUT",
        body: formData,
      }),
    }),
  }),
});

export const { useGetGroupsQuery, useRegisterMutation, useUpdateMutation } =
  groupsApi;
