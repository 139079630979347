const disabledTextFieldOverride = {
  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: '#000000',
  },
  '& .MuiInputLabel-root.Mui-disabled': {
    color: '#000000',
  },
};

export const sx = {
  disabledTextFieldOverride,
};
