import { useMemo } from 'react'
import { useSelector } from 'react-redux'

export const useGroups = () => {
  const groups = useSelector(state => state.groups.groups);
  const error = useSelector(state => state.groups.error);
  const isLoading = useSelector(state => state.groups.isLoading);

  return useMemo(() => ({ groups, error, isLoading }), [groups, error, isLoading]);
}

