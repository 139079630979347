import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

export const fetchUsers = createAsyncThunk('users/fetchUsers', async () => {
  try {
    const token = Cookies.get('token');
    const response = await fetch(process.env.REACT_APP_BASE_URL + 'users', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});
export const fetchUsersList = createAsyncThunk('users/fetchUsersList', async () => {
  try {
    const token = Cookies.get('token');
    const response = await fetch(process.env.REACT_APP_BASE_URL + 'users/user-list', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const fetchUser = createAsyncThunk('users/fetchUser', async (id) => {
  try {
    const token = Cookies.get('token');
    const response = await fetch(process.env.REACT_APP_BASE_URL + `users/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

const usersSlice = createSlice({
  name: 'users',
  initialState: {
    users: [],
    isLoading: false,
    error: null,
  },
  reducers: {
    removeUsers: (state) => {
      state.users = null;
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.users = action.payload;
      state.error = null;
    });
    builder.addCase(fetchUsers.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export const { removeUsers } = usersSlice.actions;

export default usersSlice.reducer;

export const selectUsers = (state) => state.users.users;
export const selectUserById = (state, id) => {
  if (!id) return null;
  const users = selectUsers(state);
  const { activeUsers, archivedUsers } = users;
  const allUsers = activeUsers.concat(archivedUsers);
  return allUsers.find((user) => user.id === id);
};
