// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__67vqw {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  position: relative;
}

.style_container_email__lIjFC {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 80%;

  @media (min-width: 1024px) {
    display: flex;
    width: 50%;
  }
}

.style_link__qzjgk {
  cursor: pointer;
  text-decoration: none;
  color: #333;
  font-size: 20px;
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/ResetPassword/style.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;EACtB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;EACb,UAAU;;EAEV;IACE,aAAa;IACb,UAAU;EACZ;AACF;;AAEA;EACE,eAAe;EACf,qBAAqB;EACrB,WAAW;EACX,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".container {\n  box-sizing: border-box;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n  width: 100%;\n  position: relative;\n}\n\n.container_email {\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 20px;\n  width: 80%;\n\n  @media (min-width: 1024px) {\n    display: flex;\n    width: 50%;\n  }\n}\n\n.link {\n  cursor: pointer;\n  text-decoration: none;\n  color: #333;\n  font-size: 20px;\n  margin-top: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__67vqw`,
	"container_email": `style_container_email__lIjFC`,
	"link": `style_link__qzjgk`
};
export default ___CSS_LOADER_EXPORT___;
