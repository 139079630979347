import { configureStore } from '@reduxjs/toolkit';
import { api as authApi } from './services/auth';
import authReducer from '../features/auth/authSlice';
import usersReducer from '../features/users/usersSlice';
import machinesReducer from '../features/machines/machinesSlice';
import groupsReducer from '../features/groups/groupsSlice';
import locationsReducer from '../features/locations/locationsSlice';
import suppliesReducer from '../features/supplies/suppliesSlice';
import maintenanceTypesReducer from '../features/maintenanceTypes/maintenanceTypesSlice';
import validDocumentsReducer from '../features/validDocuments/validDocumentsSlice';
import notificationsReducer from '../features/notifications/notificationsSlice';
import auditLogsReducer from '../features/auditLogs/auditLogsSlice';
import filtersReducer from '../features/filters/filtersSlice';
import { usersApi } from './services/users';
import { machinesApi } from './services/machines';
import { groupsApi } from './services/groups';
import { locationsApi } from './services/locations';
import { maintenancesApi } from './services/maintenances';
import { suppliesApi } from './services/supplies';
import { maintenanceTypesApi } from './services/maintenanceTypes';
import { notificationsApi } from './services/notifications';
import { manualsApi } from './services/manuals';
import { validDocumentsApi } from './services/validDocuments';

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [machinesApi.reducerPath]: machinesApi.reducer,
    [groupsApi.reducerPath]: groupsApi.reducer,
    [locationsApi.reducerPath]: locationsApi.reducer,
    [maintenancesApi.reducerPath]: maintenancesApi.reducer,
    [suppliesApi.reducerPath]: suppliesApi.reducer,
    [maintenanceTypesApi.reducerPath]: maintenanceTypesApi.reducer,
    [notificationsApi.reducerPath]: notificationsApi.reducer,
    [manualsApi.reducerPath]: manualsApi.reducer,
    [validDocumentsApi.reducerPath]: validDocumentsApi.reducer,
    auth: authReducer,
    users: usersReducer,
    machines: machinesReducer,
    groups: groupsReducer,
    locations: locationsReducer,
    supplies: suppliesReducer,
    maintenanceTypes: maintenanceTypesReducer,
    validDocuments: validDocumentsReducer,
    notifications: notificationsReducer,
    auditLogs: auditLogsReducer,
    filters: filtersReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      usersApi.middleware,
      machinesApi.middleware,
      groupsApi.middleware,
      locationsApi.middleware,
      maintenancesApi.middleware,
      suppliesApi.middleware,
      maintenanceTypesApi.middleware,
      notificationsApi.middleware,
      manualsApi.middleware,
      validDocumentsApi.middleware
    ),
});

export const AppDispatch = typeof store.dispatch;
