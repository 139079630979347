// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box;
}

.style_container__YYxtZ {
  height: 100vh;
  width: 100%;
  position: relative;
}

.style_container_logo__8xFSI {
  display: none;
  background-color: #2196f3;
  padding: 20px;

  @media (min-width: 1024px) {
    display: flex;
    width: 50%;
  }
}

.style_container_login__6A-\\+S {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 100%;

  @media (min-width: 1024px) {
    width: 50%;
  }
}

.style_link__ULU48 {
  text-decoration: none;
  color: #2196f3;
  align-self: flex-end;
  margin: 10px 0px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/SignIn/style.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,aAAa;;EAEb;IACE,aAAa;IACb,UAAU;EACZ;AACF;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,WAAW;;EAEX;IACE,UAAU;EACZ;AACF;;AAEA;EACE,qBAAqB;EACrB,cAAc;EACd,oBAAoB;EACpB,gBAAgB;AAClB","sourcesContent":["* {\n  box-sizing: border-box;\n}\n\n.container {\n  height: 100vh;\n  width: 100%;\n  position: relative;\n}\n\n.container_logo {\n  display: none;\n  background-color: #2196f3;\n  padding: 20px;\n\n  @media (min-width: 1024px) {\n    display: flex;\n    width: 50%;\n  }\n}\n\n.container_login {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 20px;\n  width: 100%;\n\n  @media (min-width: 1024px) {\n    width: 50%;\n  }\n}\n\n.link {\n  text-decoration: none;\n  color: #2196f3;\n  align-self: flex-end;\n  margin: 10px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__YYxtZ`,
	"container_logo": `style_container_logo__8xFSI`,
	"container_login": `style_container_login__6A-+S`,
	"link": `style_link__ULU48`
};
export default ___CSS_LOADER_EXPORT___;
