import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRegisterMutation, useUpdateMutation } from '../../app/services/supplies';
import { fetchSupplie, fetchSupplies } from '../../features/supplies/suppliesSlice';
import ModalComponent from '../ModalComponent';

import { Button, FormControl, FormHelperText, TextField, Typography } from '@mui/material';

import styles from '../ModalComponent/modalStyles.module.css';
import { useTranslation } from 'react-i18next';
import { validateFormData, getValidationErrorMessage } from '../../helpers';
import { postSupplySchema, putSupplySchema } from '../../schemas';
import { INPUT_FILE_ACCEPT_DOC } from '../../constants';

const initialForm = {
  number: '',
  name: '',
  description: '',
  manufacture: '',
};

export default function ModalAddSupplie({ id, type }) {
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState(initialForm);
  const [errorResponse, setErrorResponse] = useState('');
  const [registerSupplies] = useRegisterMutation();
  const [selectedFile, setSelectedFile] = useState(null);

  const [formValidationErrors, setFormValidationErrors] = useState(null);
  const supplyNumError = getValidationErrorMessage(formValidationErrors, 'number');
  const supplyNameError = getValidationErrorMessage(formValidationErrors, 'name');
  const supplyFileError = getValidationErrorMessage(formValidationErrors, 'file');

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [updateSupplie] = useUpdateMutation();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setForm(initialForm);
    setSelectedFile(null);
    setErrorResponse('');
    setFormValidationErrors(null);
  };

  useEffect(() => {
    if (id && open) {
      async function fetchData() {
        try {
          const response = await dispatch(fetchSupplie(id));
          setForm({
            number: response.payload.number,
            name: response.payload.name,
            description: response.payload.description,
            manufacture: response.payload.manufacture,
          });
        } catch (error) {
          console.error('Error fetching supplie:', error);
        }
      }
      fetchData();
    }
  }, [id, open, dispatch]);

  const handleChange = (e) => {
    setErrorResponse('');
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    if (id) {
      const validationErrors = validateFormData(t, putSupplySchema, form);
      if (!!validationErrors) {
        setFormValidationErrors(validationErrors);
        return;
      }

      try {
        const formData = new FormData();
        formData.append('number', form.number);
        formData.append('name', form.name);
        formData.append('description', form.description);
        formData.append('manufacture', form.manufacture);
        if (selectedFile) {
          formData.append('file', selectedFile);
        }
        const resp = await updateSupplie({ id, formData });
        if (resp.error) {
          setErrorResponse(resp.error.data?.message);
        } else {
          handleClose();
          dispatch(fetchSupplies());
        }
      } catch (err) {
        console.error('Error registering supplie:', err);
      }
    } else {
      const validationErrors = validateFormData(t, postSupplySchema, {
        ...form,
        file: selectedFile,
      });
      if (!!validationErrors) {
        setFormValidationErrors(validationErrors);
        return;
      }
      try {
        const formData = new FormData();
        formData.append('number', form.number);
        formData.append('name', form.name);
        formData.append('description', form.description);
        formData.append('manufacture', form.manufacture);
        formData.append('file', selectedFile);
        const resp = await registerSupplies(formData);
        if (resp.error) {
          setErrorResponse(resp.error.data?.message);
        } else {
          handleClose();
          dispatch(fetchSupplies());
        }
      } catch (err) {
        console.error('Error registering supplie:', err);
      }
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  return (
    <div>
      {type === 'add' ? (
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpen}
          style={{ padding: '8px 22px', textTransform: 'none' }}
        >
          {t('modals.add+')}
        </Button>
      ) : (
        <Button
          variant="outlined"
          color="primary"
          onClick={handleOpen}
          style={{ padding: '5px 15px' }}
        >
          {t('generic.edit')}
        </Button>
      )}
      <ModalComponent open={open} handleClose={handleClose}>
        <div className={styles.modal}>
          <div className={styles.container}>
            <Typography variant="h5" fontWeight="bold">
              {type === 'add' ? t('modals.createSupplie') : t('modals.updateSupplie')}
            </Typography>
            <TextField
              label={t('generic.number')}
              variant="outlined"
              value={form.number}
              name="number"
              onChange={handleChange}
              fullWidth
              error={!!supplyNumError}
              helperText={supplyNumError ?? ' '}
            />
            <TextField
              label={t('generic.name')}
              variant="outlined"
              value={form.name}
              name="name"
              onChange={handleChange}
              fullWidth
              error={!!supplyNameError}
              helperText={supplyNameError ?? ' '}
            />
            <TextField
              label={t('generic.description')}
              variant="outlined"
              value={form.description}
              name="description"
              onChange={handleChange}
              fullWidth
              helperText=" "
            />
            <TextField
              label={t('generic.manufacture')}
              variant="outlined"
              value={form.manufacture}
              name="manufacture"
              onChange={handleChange}
              fullWidth
              helperText=" "
            />
            <FormControl>
              <Button
                component="label"
                variant="outlined"
                sx={(theme) => ({
                  color: supplyFileError ? theme.palette.error.main : 'rgba(0, 0, 0, 0.6)',
                  borderColor: supplyFileError ? theme.palette.error.main : 'rgba(0, 0, 0, 0.3)',
                  fontSize: '16px',
                  height: '56px',
                  textTransform: 'none',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  '&:hover': {
                    borderColor: 'rgba(0, 0, 0, 1)',
                    background: 'none',
                  },
                })}
              >
                <input
                  accept={INPUT_FILE_ACCEPT_DOC}
                  id="fileInput"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
                {selectedFile ? selectedFile.name : t('modals.uploadDocument')}
              </Button>
              <FormHelperText error={!!supplyFileError}>
                {supplyFileError ?? t('generic.uploadFileSupplyRecord')}
              </FormHelperText>
            </FormControl>

            {errorResponse &&
              (Array.isArray(errorResponse) ? (
                errorResponse.map((item, index) => (
                  <Typography key={index} color="error">
                    {item}
                  </Typography>
                ))
              ) : (
                <Typography color="error">{errorResponse}</Typography>
              ))}

            <div className={styles.button_group}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClose}
                style={{ padding: '5px 22px' }}
              >
                {t('modals.cancel')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                style={{ padding: '5px 22px' }}
              >
                {type === 'add' ? t('modals.create') : t('modals.update')}
              </Button>
            </div>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
}
