import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useRegisterMutation } from '../../app/services/machines';
import { fetchMachines } from '../../features/machines/machinesSlice';
import MachineProfileInputs from '../MachineProfileInputs';
import ModalComponent from '../ModalComponent';

import { Button, Typography } from '@mui/material';

import styles from '../ModalComponent/modalStyles.module.css';

export default function ModalAddMachine() {
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState();
  const [errorResponse, setErrorResponse] = useState('');
  const [registerMachine, { isLoading, error }] = useRegisterMutation();

  const initialForm = {
    tag: '1',
    number: '',
    name: '',
    description: '',
    serialNumber: '',
    responsiblePerson: '',
    deputy: '',
    yearOfManufacture: Number(),
    location: '',
    machineGroup: '',
    status: 'active',
    isArchived: 'non-archived',
  };

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isInputsDisabled = true;

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setForm(initialForm);
  };

  useEffect(() => {
    if (error) {
      console.log(error);
      setErrorResponse(error.data.message);
    }
  }, [error]);

  useEffect(() => {
    if (!isLoading && !error) {
      handleClose();
      setErrorResponse('');
      dispatch(fetchMachines());
    }
  }, [isLoading, error, dispatch]);

  const handleSubmit = async () => {
    try {
      const obj = {
        ...form,
        activatedAt: form.activated_at ? form.activated_at : dayjs(new Date()).format('YYYY-MM-DD'),
      };
      await registerMachine(obj);
    } catch (err) {
      console.error('Error registering machine:', err);
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        style={{ padding: '8px 22px', textTransform: 'none' }}
      >
        {t('modals.add+')}
      </Button>
      <ModalComponent open={open} handleClose={handleClose}>
        <div className={styles.modal}>
          <div className={styles.container}>
            <Typography variant="h5" fontWeight="bold">
              {t('modals.createMachine')}
            </Typography>

            <MachineProfileInputs
              form={form}
              setForm={setForm}
              isInputsDisabled={isInputsDisabled}
            />
            {errorResponse &&
              (Array.isArray(errorResponse) ? (
                <Typography color="error">{errorResponse.join(', ')}</Typography>
              ) : (
                <Typography color="error" variant="h6">
                  {errorResponse}
                </Typography>
              ))}
            <div className={styles.button_group}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClose}
                style={{ padding: '5px 22px' }}
              >
                {t('modals.cancel')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                style={{ padding: '5px 22px' }}
              >
                {t('modals.create')}
              </Button>
            </div>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
}
