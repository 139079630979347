import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableHead from '@mui/material/TableHead';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import TablePaginationActions from '../../components/TablePaginationActions';
import ModalDiffViewer from '../../components/ModalDiffViewer';

export default function LogsTable({ titles, data, logsModel }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [maxPage, setMaxPage] = useState(0);

  const { t } = useTranslation();

  useEffect(() => {
    const totalPages = Math.ceil(data.length / rowsPerPage);
    setMaxPage(totalPages > 0 ? totalPages - 1 : 0);

    if (page === maxPage && maxPage !== 0) {
      setPage(totalPages - 1);
    }
  }, [data, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setPage(0);
  }, [logsModel]);

  return (
    <Paper sx={{ width: '100%' }}>
      <TableContainer>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              {titles.map((title, index) => (
                <TableCell key={index}>{title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          {data && (
            <TableBody>
              {(rowsPerPage > 0
                ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : data
              ).map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {Object.keys(row).map(
                    (key, cellIndex) =>
                      key !== 'created_at' && (
                        <TableCell
                          key={cellIndex}
                          align="left"
                          style={{
                            maxWidth: '200px',
                            overflowWrap: 'break-word',
                          }}
                        >
                          {key !== 'editor_description' && row[key]}
                          {key === 'description' && row['editor_description'] && (
                            <ModalDiffViewer content={row['editor_description']} />
                          )}
                        </TableCell>
                      )
                  )}
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {data.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 25, { label: 'All', value: -1 }]}
          colSpan={3}
          component="div"
          sx={{ '& .MuiToolbar-root': { paddingRight: '15px' } }}
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          align="right"
          labelRowsPerPage={t('calendar.rowsPerPage')}
          slotProps={{
            select: {
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            },
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      )}
    </Paper>
  );
}
