import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

export const fetchValidDocuments = createAsyncThunk(
  'validDocuments/fetchValidDocuments',
  async () => {
    try {
      const token = Cookies.get('token');
      const response = await fetch(process.env.REACT_APP_BASE_URL + 'validation-documents', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();

      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const fetchValidDocument = createAsyncThunk(
  'validDocument/fetchValidDocument',
  async (id) => {
    try {
      const token = Cookies.get('token');
      const response = await fetch(process.env.REACT_APP_BASE_URL + `validation-documents/${id}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();

      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const deleteValidDocument = createAsyncThunk(
  'validation-documents/deleteValidDocument',
  async (id) => {
    try {
      const token = Cookies.get('token');
      await fetch(process.env.REACT_APP_BASE_URL + `validation-documents/${id}`, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

const validDocumentsSlice = createSlice({
  name: 'validDocuments',
  initialState: {
    validDocuments: [],
    isLoading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchValidDocuments.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchValidDocuments.fulfilled, (state, action) => {
      state.isLoading = false;
      state.validDocuments = action.payload;
      state.error = null;
    });
    builder.addCase(fetchValidDocuments.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export default validDocumentsSlice.reducer;

export const selectValidDocuments = (state) => state.validDocuments.validDocuments;
