import { useRef, useState, useEffect } from 'react';
import dayjs from 'dayjs';

export const useDatePickerLocalValue = (incomingValue, updateIncomingValue) => {
  const initialValue = useRef(incomingValue);
  const [value, setValue] = useState(dayjs(incomingValue));

  useEffect(() => {
    try {
      value.isValid();
    } catch {
      updateIncomingValue(initialValue.current);
      setValue(dayjs(new Date(initialValue.current)));
    }
  }, [value, updateIncomingValue]);

  return {
    value,
    setValue,
  };
};
