import { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  deleteSupplie,
  fetchSupplies,
} from "../../features/supplies/suppliesSlice";
import {
  deleteLocation,
  fetchLocations,
} from "../../features/locations/locationsSlice";
import { deleteManual } from "../../features/manuals/manualsSlice";
import { deleteGroupSupplie } from "../../features/groups/groupsSlice";
import { deleteValidDocument } from "../../features/validDocuments/validDocumentsSlice";
import { deleteMaintenance } from "../../features/maintenances/maintenancesSlice";
import { ACTIONS } from "../../constants/actionTables";
import ModalComponent from "../ModalComponent";

import { Button, Tooltip, Typography } from "@mui/material";

import styles from "../ModalComponent/modalStyles.module.css";

export default function ModalDelete({
  id,
  name,
  type,
  fetchData,
  flagForDelete = true,
}) {
  const [open, setOpen] = useState(false);
  const [errorResponse, setErrorResponse] = useState("");

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
      if (type === ACTIONS.SUPPLIES) {
        await dispatch(deleteSupplie(id));
        setOpen(false);
        dispatch(fetchSupplies());
      }
      if (type === ACTIONS.LOCATIONS) {
        await dispatch(deleteLocation(id));
        setOpen(false);
        dispatch(fetchLocations());
      }
      if (type === ACTIONS.MANUAL) {
        await dispatch(deleteManual(id));
        setOpen(false);
        fetchData();
      }
      if (type === ACTIONS.SUPPLY) {
        await dispatch(deleteGroupSupplie(id));
        setOpen(false);
        fetchData();
      }
      if (type === ACTIONS.DOCUMENT) {
        await dispatch(deleteValidDocument(id));
        setOpen(false);
        fetchData();
      }
      if (type === ACTIONS.TASK) {
        await dispatch(deleteMaintenance(id));
        setOpen(false);
        fetchData();
      }
    } catch (error) {
      setErrorResponse(error);
      console.error("Error delete:", error);
    }
  };

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        {flagForDelete ? (
          <Button
            variant="outlined"
            color="primary"
            disabled={!flagForDelete}
            onClick={handleOpen}
            style={{ padding: "5px 15px" }}
          >
            {type === ACTIONS.SUPPLY
              ? t("generic.remove")
              : t("generic.delete")}
          </Button>
        ) : (
          <Tooltip title={t("generic.cannotDelete")} placement="top" arrow>
            <div
              style={{
                display: "flex",
                boxSizing: "border-box",
                alignItems: "center",
                justifyContent: "center",
                height: "36.5px",
                width: "88.88px",
                textTransform: "uppercase",
                backgroundColor: "transparent",
                color: "rgba(0, 0, 0, 0.26)",
                border: "1px solid rgba(0, 0, 0, 0.12)",
                borderRadius: "4px",
                cursor: "not-allowed",
              }}
            >
              {t("generic.delete")}
            </div>
          </Tooltip>
        )}
      </div>
      <ModalComponent open={open} handleClose={handleClose}>
        <div className={styles.modal}>
          <div className={styles.container}>
            <Typography variant="h5" fontWeight="bold">
              {type === ACTIONS.SUPPLY
                ? t("modals.areSureWantRemove")
                : t("modals.areSureWantDelete")}
              {type === ACTIONS.LOCATIONS && t("generic.locations")}
              {type === ACTIONS.MANUAL && t("generic.manuals")}
              {type === ACTIONS.SUPPLY && t("generic.supply")}
              {type === ACTIONS.DOCUMENT && t("generic.document")}
              {type === ACTIONS.TASK && t("generic.task")}
              {type === ACTIONS.SUPPLIES && t("generic.supplies")}: {name}?
            </Typography>

            {errorResponse &&
              (Array.isArray(errorResponse) ? (
                errorResponse.map((item, index) => (
                  <Typography key={index} color="error">
                    {item}
                  </Typography>
                ))
              ) : (
                <Typography color="error">{errorResponse}</Typography>
              ))}

            <div className={styles.button_group}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClose}
                style={{ padding: "5px 22px" }}
              >
                {t("modals.cancel")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                style={{ padding: "5px 22px" }}
              >
                {type === ACTIONS.SUPPLY
                  ? t("generic.remove")
                  : t("generic.delete")}
              </Button>
            </div>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
}
