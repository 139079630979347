import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { ACTIONS } from '../../constants/actionTables';
import ModalMaintenanceTask from '../../components/ModalMaintenanceTask';

import { Box, Button, Typography, ToggleButtonGroup, ToggleButton } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import styles from './style.module.css';

function CalendarGrid({ taskData, setStartDate, setEndDate, fetchData }) {
  const [currentView, setCurrentView] = useState('dayGridMonth');
  const [calendarTitle, setCalendarTitle] = useState('');
  const [isTodayInRange, setIsTodayInRange] = useState(true);

  const calendarRef = useRef();
  const { t, i18n } = useTranslation();

  const ref = calendarRef?.current?.getApi();

  function renderEventContent(eventInfo) {
    return (
      <ModalMaintenanceTask
        task={eventInfo.event}
        type={ACTIONS.CALENDAR}
        view={true}
        fetchdata={fetchData}
      />
    );
  }

  const handleViewClick = (period) => {
    ref?.changeView(period);
    setCurrentView(period);
  };

  const handleChooseDay = (dateArg) => {
    setCalendarTitle(dateArg.dateStr);
    handleViewClick('timeGridDay');
    ref?.changeView('timeGridDay', dateArg.dateStr);
  };

  const checkIsTodayInRange = (startDate, endDate) => {
    const today = new Date();
    return today >= startDate && today <= endDate;
  };

  const dateSet = (dateInfo) => {
    const isTodayInRange = checkIsTodayInRange(dateInfo.start, dateInfo.end);
    setIsTodayInRange(isTodayInRange);
    setCalendarTitle(dateInfo.view.title);
    setStartDate(dateInfo.startStr);
    setEndDate(dateInfo.endStr);
  };

  return (
    <div>
      <div className={styles.header}>
        <Box>
          <Button color="inherit" onClick={() => ref?.prev()} style={{ height: '42px' }}>
            <KeyboardArrowLeftIcon />
          </Button>
          <Button color="inherit" onClick={() => ref?.next()} style={{ height: '42px' }}>
            <KeyboardArrowRightIcon />
          </Button>
          {/* <Button
            variant="contained"
            color="inherit"
            onClick={() => ref?.today()}
            style={{ padding: '5px 22px', height: '42px' }}
          >
            {t('calendar.today')}
          </Button> */}
          <ToggleButton
            sx={{ ml: '16px' }}
            color="primary"
            value="TODAY"
            onClick={() => ref?.today()}
            selected={isTodayInRange}
          >
            {t('calendar.today')}
          </ToggleButton>
        </Box>
        <Typography variant="h5" fontWeight="bold">
          {calendarTitle}
        </Typography>
        {/* <Box>
          <Button
            color={currentView === 'dayGridMonth' ? 'primary' : 'inherit'}
            onClick={() => handleViewClick('dayGridMonth')}
            style={{ padding: '5px 22px', height: '42px' }}
          >
            {t('calendar.month')}
          </Button>
          <Button
            color={currentView === 'timeGridWeek' ? 'primary' : 'inherit'}
            onClick={() => handleViewClick('timeGridWeek')}
            style={{ padding: '5px 22px', height: '42px' }}
          >
            {t('calendar.week')}
          </Button>
          <Button
            color={currentView === 'timeGridDay' ? 'primary' : 'inherit'}
            onClick={() => handleViewClick('timeGridDay')}
            style={{ padding: '5px 22px', height: '42px' }}
          >
            {t('calendar.day')}
          </Button>
        </Box> */}
        <ToggleButtonGroup
          color="primary"
          value={currentView}
          onChange={(_, newView) => {
            if (!newView) return;

            ref?.changeView(newView);
            setCurrentView(newView);
          }}
          exclusive
        >
          <ToggleButton value="dayGridMonth">{t('calendar.month')}</ToggleButton>
          <ToggleButton value="timeGridWeek">{t('calendar.week')}</ToggleButton>
          <ToggleButton value="timeGridDay">{t('calendar.day')}</ToggleButton>
        </ToggleButtonGroup>
      </div>

      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={false}
        firstDay={1}
        initialView="dayGridMonth"
        weekends={true}
        events={taskData}
        eventContent={renderEventContent}
        datesSet={(dateInfo) => dateSet(dateInfo)}
        locale={i18n.language}
        height={'auto'}
        views={{
          timeGridWeek: {
            slotMinTime: '00:00',
            slotMaxTime: '00:00',
            dayHeaderFormat: { weekday: 'long' },
            allDaySlot: true,
            expandRows: true,
            height: '100%',
          },
          timeGridDay: {
            slotMinTime: '00:00',
            slotMaxTime: '00:00',
            dayHeaderFormat: { weekday: 'long' },
            allDaySlot: true,
            expandRows: true,
            height: '100%',
          },
        }}
        dateClick={(dateArg) => handleChooseDay(dateArg)}
        hiddenDays={[0, 6]}
      />
    </div>
  );
}

export default CalendarGrid;
