import { useMemo } from 'react'
import { useSelector } from 'react-redux'

export const useSupplies = () => {
  const supplies = useSelector(state => state.supplies.supplies);
  const error = useSelector(state => state.supplies.error);
  const isLoading = useSelector(state => state.supplies.isLoading);

  return useMemo(() => ({ supplies, error, isLoading }), [supplies, error, isLoading]);
}
