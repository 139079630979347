import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";

export const maintenanceTypesApi = createApi({
  reducerPath: "maintenanceTypes",
  baseQuery,
  endpoints: (builder) => ({
    getGroups: builder.query({
      query: () => "maintenance-types",
    }),
    register: builder.mutation({
      query: (form) => ({
        url: "maintenance-types",
        method: "POST",
        body: form,
      }),
    }),
    update: builder.mutation({
      query: ({ id, form }) => ({
        url: `maintenance-types/${id}`,
        method: "PUT",
        body: form,
      }),
    }),
  }),
});

export const { useGetMaintenanceTypesQuery, useRegisterMutation, useUpdateMutation } =
maintenanceTypesApi;
