import { useEffect, useState } from 'react';
import { ACTIONS } from '../../constants/actionTables';
import TableComponent from '../../components/Table';

import { Typography } from '@mui/material';

import styles from './style.module.css';
import { useSupplies } from '../../hooks/useSupplies';
import ModalAddSupplie from '../../components/ModalAddSupplie';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../hooks/useAuth';

function Supplies() {
  const [data, setData] = useState(null);

  const { user } = useAuth();
  const { supplies } = useSupplies();
  const { t } = useTranslation();

  useEffect(() => {
    if (supplies) {
      const preparedData = supplies.map((supplie) => ({
        id: supplie.id,
        number: supplie.number,
        name: supplie.name,
        description: supplie.description,
        manufacture: supplie.manufacture,
        safetySheet: supplie.original_filename,
        link: supplie.filename,
        flagForDelete: supplie.is_able_to_remove,
      }));
      setData(preparedData);
    }
  }, [supplies]);

  const titles = [
    t('generic.number'),
    t('generic.name'),
    t('generic.description'),
    t('generic.manufacture'),
    t('generic.safetySheet'),
  ];

  if (user && user.role === 'admin') {
    titles.push(t('generic.action'));
  }

  return (
    <section className={styles.container}>
      <div className={styles.header}>
        <Typography variant="h5" fontWeight="bold">
          {t('generic.supplies')}
        </Typography>
        {user && user.role === 'admin' && <ModalAddSupplie type={'add'} />}
      </div>
      {data && (
        <TableComponent
          titles={titles}
          data={data}
          action={user && user.role === 'admin' && ACTIONS.SUPPLIES}
        />
      )}
    </section>
  );
}

export default Supplies;
