import { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useUpdateMutation } from "../../app/services/machines";
import { fetchMachines } from "../../features/machines/machinesSlice";
import ModalComponent from "../ModalComponent";

import { Button, Typography } from "@mui/material";

import styles from "../ModalComponent/modalStyles.module.css";

export default function ModalConfirmStatusChange({
  isStatusChange,
  setIsStatusChange,
  setIsInputsDisabled,
  fetchData,
  id,
  form,
}) {
  const [errorResponse, setErrorResponse] = useState("");

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClose = async () => {
    setIsStatusChange(false);
    setIsInputsDisabled(false);
    await fetchData();
  };

  const [updateMachine] = useUpdateMutation();
  const handleSubmit = async () => {
    try {
      const { ...machineData } = form;
      await updateMachine({ id, machineData });
      await fetchData();
      await dispatch(fetchMachines());
      setIsInputsDisabled(false);
      setIsStatusChange(false);
    } catch (error) {
      setErrorResponse(error);
      console.error("Error updating user:", error);
    }
  };

  return (
    <div>
      <ModalComponent open={isStatusChange} handleClose={handleClose}>
        <div className={styles.modal}>
          <div className={styles.container}>
            <Typography variant="h6" fontWeight="bold">
              {t("modals.areSureWant")} {t("modals.changeStatus")} {form.status}
            </Typography>

            <div className={styles.button_group}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClose}
                style={{ padding: "5px 22px" }}
              >
                {t("modals.cancel")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                style={{ padding: "5px 22px" }}
              >
                {t("modals.submit")}
              </Button>
            </div>
            {errorResponse &&
              (Array.isArray(errorResponse) ? (
                errorResponse.map((item, index) => (
                  <Typography key={index} color="error">
                    {item}
                  </Typography>
                ))
              ) : (
                <Typography color="error">{errorResponse}</Typography>
              ))}
          </div>
        </div>
      </ModalComponent>
    </div>
  );
}
