import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../hooks/useAuth';
import { useUsers } from '../../hooks/useUsers';
import { ACTIONS } from '../../constants/actionTables';
import NotFoundPage from '../NotFoundPage';
import ModalAddUser from '../../components/ModalAddUser';
import TableComponent from '../../components/Table';
import { Box, Tab, Tabs, Typography } from '@mui/material';

import styles from './style.module.css';

function UsersList() {
  const [value, setValue] = useState(0);
  const [dataActive, setDataActive] = useState(0);
  const [dataAchived, setDataAchived] = useState(0);
  const { user } = useAuth();

  const { users } = useUsers();
  const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const titles = [
    t('generic.number'),
    t('generic.firstName'),
    t('generic.lastName'),
    t('generic.email'),
    t('userList.userGroup'),
    t('generic.action'),
  ];

  useEffect(() => {
    if (users?.activeUsers) {
      const data = users.activeUsers.map((item) => ({
        id: item.id,
        number: item.id,
        firstname: item.firstname,
        lastname: item.lastname,
        email: item.email,
        role: item.role,
      }));
      setDataActive(data);
    }
    if (users?.archivedUsers) {
      const data = users.archivedUsers.map((item) => ({
        id: item.id,
        number: item.id,
        firstname: item.firstname,
        lastname: item.lastname,
        email: item.email,
        role: item.role,
      }));
      setDataAchived(data);
    }
  }, [users]);

  if (user?.role === 'admin') {
    return (
      <section className={styles.container}>
        <div className={styles.header}>
          <Typography variant="h5" fontWeight="bold">
            {t('userList.userList')}
          </Typography>
          <ModalAddUser />
        </div>
        <div className={styles.tabs}>
          <Box
            sx={{
              borderBottom: 1,
              borderTop: 1,
              borderColor: 'divider',
              marginBottom: '20px',
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label={t('userList.activeUsers')} />
              <Tab label={t('userList.archivedUsers')} />
            </Tabs>
          </Box>
          {value === 0 && (
            <TableComponent titles={titles} data={dataActive} action={ACTIONS.USERS_ACTIVE} />
          )}
          {value === 1 && (
            <TableComponent titles={titles} data={dataAchived} action={ACTIONS.USERS_ARCHIVED} />
          )}
        </div>
      </section>
    );
  } else {
    return <NotFoundPage />;
  }
}

export default UsersList;
