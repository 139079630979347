import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateMutation } from '../../app/services/users';
import { fetchUsers } from '../../features/users/usersSlice';
import ModalComponent from '../ModalComponent';
import { useTranslation } from 'react-i18next';

import { Button, Typography } from '@mui/material';

import styles from '../ModalComponent/modalStyles.module.css';
import { selectUserById } from '../../features/users/usersSlice';

export default function ModalStatusUser({ id, action }) {
  const [open, setOpen] = useState(false);
  const [errorResponse, setErrorResponse] = useState('');

  const selectedUser = useSelector((state) => selectUserById(state, id));

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [updateUser] = useUpdateMutation();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = async () => {
    if (!selectedUser) return;
    try {
      const { id, ...userData } = selectedUser;
      userData.status = action;
      await updateUser({ id, userData });
      dispatch(fetchUsers());
      setOpen(false);
    } catch (error) {
      setErrorResponse(error);
      console.error('Error updating user:', error);
    }
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleOpen}>
        {action === 'archived' ? t('modals.archive') : t('modals.reactivate')}
      </Button>
      <ModalComponent open={open} handleClose={handleClose}>
        <div className={styles.modal}>
          <div className={styles.container}>
            <Typography variant="h6" fontWeight="bold">
              {t('modals.areSureWant')}{' '}
              {action === 'archived' ? t('modals.archive') : t('modals.reactivate')}{' '}
              {t('modals.thisUser')}?
            </Typography>

            <Typography variant="h6" style={{ color: '#9747FF' }}>
              {`${selectedUser?.firstname} ${selectedUser?.lastname}`}
            </Typography>

            <div className={styles.button_group}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClose}
                style={{ padding: '5px 22px' }}
              >
                {t('modals.cancel')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                style={{ padding: '5px 22px' }}
              >
                {action === 'archived' ? t('modals.archive') : t('modals.reactivate')}
              </Button>
            </div>
            {errorResponse &&
              (Array.isArray(errorResponse) ? (
                errorResponse.map((item, index) => (
                  <Typography key={index} color="error">
                    {item}
                  </Typography>
                ))
              ) : (
                <Typography color="error">{errorResponse}</Typography>
              ))}
          </div>
        </div>
      </ModalComponent>
    </div>
  );
}
