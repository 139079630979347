import { useMemo } from 'react'
import { useSelector } from 'react-redux'

export const useMachines = () => {
  const machines = useSelector(state => state.machines.machines);
  const error = useSelector(state => state.machines.error);
  const isLoading = useSelector(state => state.machines.isLoading);

  return useMemo(() => ({ machines, error, isLoading }), [machines, error, isLoading]);
}

