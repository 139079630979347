import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

export const fetchGroups = createAsyncThunk('groups/fetchGroups', async () => {
  try {
    const token = Cookies.get('token');
    const response = await fetch(process.env.REACT_APP_BASE_URL + 'machine-groups', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const fetchGroup = createAsyncThunk('groups/fetchGroup', async (id) => {
  try {
    const token = Cookies.get('token');
    const response = await fetch(process.env.REACT_APP_BASE_URL + `machine-groups/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const fetchGroupSupplie = createAsyncThunk('groups/fetchGroupSupplie', async (formData) => {
  try {
    const token = Cookies.get('token');
    const response = await fetch(process.env.REACT_APP_BASE_URL + `machine-groups/supplies`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });
    const data = await response.json();

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const deleteGroupSupplie = createAsyncThunk('groups/deleteGroupSupplie', async (id) => {
  try {
    const token = Cookies.get('token');
    const response = await fetch(process.env.REACT_APP_BASE_URL + `machine-groups/supplies/${id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

const groupsSlice = createSlice({
  name: 'groups',
  initialState: {
    groups: [],
    isLoading: false,
    error: null,
  },
  reducers: {
    removeGroups: (state) => {
      state.groups = null;
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGroups.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchGroups.fulfilled, (state, action) => {
      state.isLoading = false;
      state.groups = action.payload;
      state.error = null;
    });
    builder.addCase(fetchGroups.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export const { removeGroups } = groupsSlice.actions;

export default groupsSlice.reducer;

export const selectGroups = (state) => state.groups.groups;
