import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL + 'auth',
    prepareHeaders: (headers, { getState }) => {
      const token = Cookies.get('token');
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: 'login',
        method: 'POST',
        body: credentials,
      }),
    }),
    loginWithAzure: builder.mutation({
      query: (accessToken) => {
        return {
          url: 'loginWithAzure',
          method: 'POST',
          body: { token: accessToken },
        };
      },
    }),
    protected: builder.mutation({
      query: () => 'protected',
    }),
    forgotPassword: builder.mutation({
      query: (credentials) => ({
        url: 'forgot-password',
        method: 'POST',
        body: credentials,
      }),
    }),
    logout: builder.query({
      query: () => 'logout',
    }),
  }),
});

export const {
  useLoginMutation,
  useLoginWithAzureMutation,
  useProtectedMutation,
  useForgotPasswordMutation,
  useLazyLogoutQuery,
} = api;
