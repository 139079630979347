import { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../hooks/useAuth';
import removeData from '../../helpers/removeData';

import { Avatar, Button, Divider, Popover } from '@mui/material';
import styles from './style.module.css';
import { useLazyLogoutQuery } from '../../app/services/auth';

function PopoverProfile() {
  const [anchorEl, setAnchorEl] = useState(null);

  const { t } = useTranslation();

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { user } = useAuth();

  function getInitials(user) {
    const firstNameInitial = user.firstname.charAt(0).toUpperCase();
    const lastNameInitial = user.lastname.charAt(0).toUpperCase();
    return firstNameInitial + lastNameInitial;
  }

  const [logout] = useLazyLogoutQuery();

  const handleLogout = async () => {
    handleClose();
    try {
      await logout();
      Cookies.remove('token');
      sessionStorage.clear();
      removeData(dispatch);
      navigate('/sign-in');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <button onClick={handleClick} className={styles.avatar_button}>
        <Avatar sx={{ bgcolor: 'rgba(2, 136, 209, 0.50)' }}>{user && getInitials(user)}</Avatar>
      </button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        style={{ marginTop: '25px' }}
      >
        <div className={styles.menu}>
          <NavLink to="/user-profile" className={styles.link_menu} onClick={handleClose}>
            {t('generic.profile')}
          </NavLink>
          <Divider />
          <Button
            variant="text"
            style={{
              color: 'black',
              padding: '0 10px',
              textTransform: 'none',
              width: 'auto',
              justifyContent: 'left',
              fontFamily: 'inherit',
              fontSize: '16px',
            }}
            onClick={handleLogout}
          >
            {t('header.Logout')}
          </Button>
        </div>
      </Popover>
    </div>
  );
}

export default PopoverProfile;
