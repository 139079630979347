import { useEffect, useState } from 'react';
import ModalAddMachine from '../../components/ModalAddMachine';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import styles from './style.module.css';
import TableComponent from '../../components/Table';
import { ACTIONS } from '../../constants/actionTables';
import { useMachines } from '../../hooks/useMachines';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../hooks/useAuth';

function MachinesList() {
  const [value, setValue] = useState(0);
  const [dataActive, setDataActive] = useState(0);
  const [dataAchived, setDataAchived] = useState(0);

  const { user } = useAuth();
  const { machines } = useMachines();
  const { t } = useTranslation();

  useEffect(() => {
    if (machines?.activeMachines) {
      const data = machines.activeMachines.map((machine) => ({
        id: machine.id,
        number: machine.number,
        name: machine.name,
        description: machine.description,
        serial_number: machine.serial_number,
        responsible_person: `${machine.responsible_person.firstname} ${machine.responsible_person.lastname}`,
        deputy: `${machine.deputy.firstname} ${machine.deputy.lastname}`,
        year_of_manufacture: machine.year_of_manufacture,
        machine_group: machine.machine_group.name,
        status: machine.status,
      }));
      setDataActive(data);
    }
    if (machines?.archivedMachines) {
      const data = machines.archivedMachines.map((machine) => ({
        id: machine.id,
        number: machine.number,
        name: machine.name,
        description: machine.description,
        serial_number: machine.serial_number,
        responsible_person: `${machine.responsible_person.firstname} ${machine.responsible_person.lastname}`,
        deputy: `${machine.deputy.firstname} ${machine.deputy.lastname}`,
        year_of_manufacture: machine.year_of_manufacture,
        machine_group: machine.machine_group.name,
        status: machine.status,
      }));
      setDataAchived(data);
    }
  }, [machines]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const titles = [
    t('generic.number'),
    t('generic.name'),
    t('generic.description'),
    t('generic.serialNumber'),
    t('generic.responsiblePerson'),
    t('generic.deputy'),
    t('generic.yearOfManufacture'),
    t('generic.machineGroups'),
    t('generic.status'),
    t('generic.action'),
  ];

  return (
    <section className={styles.container}>
      <div className={styles.header}>
        <Typography variant="h5" fontWeight="bold">
          {t('generic.machinesList')}
        </Typography>
        {user?.role === 'admin' && <ModalAddMachine />}
      </div>
      <div className={styles.tabs}>
        <Box sx={{ borderBottom: 1, borderTop: 1, borderColor: 'divider', marginBottom: 4 }}>
          <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary">
            <Tab label={t('machineList.activeMachines')} />
            <Tab label={t('machineList.archivedMachines')} />
          </Tabs>
        </Box>
        {value === 0 && (
          <TableComponent titles={titles} data={dataActive} action={ACTIONS.ACTIVE_MACHINES} />
        )}
        {value === 1 && (
          <TableComponent titles={titles} data={dataAchived} action={ACTIONS.ARCHIVED_MACHINES} />
        )}
      </div>
    </section>
  );
}

export default MachinesList;
