import { z } from 'zod';
import { firstNameSchema, lastNameSchema, emailFieldSchema, roleSchema } from './fields';

export const postUserSchema = (t) =>
  z.object({
    firstname: firstNameSchema(t),
    lastname: lastNameSchema(t),
    email: emailFieldSchema(t),
    role: roleSchema(t),
  });
