import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { fetchUsersList } from '../../features/users/usersSlice';
import { useUsers } from '../../hooks/useUsers';
import { useGroups } from '../../hooks/useGroups';
import { useLocations } from '../../hooks/useLocations';
import { useAuth } from '../../hooks/useAuth';
import { getStatusesMachine } from '../../constants/options';

import { MenuItem, TextField } from '@mui/material';
import styles from './style.module.css';
import 'dayjs/locale/de';

function MachineProfileInputs({ form, setForm, isInputsDisabled }) {
  const [responsable, setResponsable] = useState(null);
  const [group, setGroup] = useState(null);
  const [location, setLocation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activatedDay, setActivatedDay] = useState(dayjs());

  const { t } = useTranslation();
  const statusesMachine = getStatusesMachine(t);

  const { groups } = useGroups();
  const { locations } = useLocations();
  const { users } = useUsers();
  const { user } = useAuth();
  const dispatch = useDispatch();

  const usersData = async () => {
    try {
      const data = await dispatch(fetchUsersList());
      setResponsable(data.payload);
      return data;
    } catch (error) {
      console.error('Error fetching users data:', error);
      throw error;
    }
  };

  useEffect(() => {
    if (user.role === 'admin') {
      setResponsable(users.activeUsers);
    } else {
      usersData();
    }
    setGroup(groups.activeMachineGroups);
    setLocation(locations);
  }, [users, groups, locations]);

  useEffect(() => {
    if (responsable && group && location) {
      setLoading(false);
    }
  }, [responsable, group, location]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const numericValue = parseInt(value, 10);

    switch (name) {
      case 'responsiblePerson':
        setForm({
          ...form,
          responsiblePerson: {
            id: numericValue,
          },
        });
        break;
      case 'deputy':
        setForm({
          ...form,
          deputy: {
            id: numericValue,
          },
        });
        break;
      case 'location':
        setForm({
          ...form,
          location: {
            id: numericValue,
          },
        });
        break;
      case 'machineGroup':
        setForm({
          ...form,
          machineGroup: {
            id: numericValue,
          },
        });
        break;
      case 'yearOfManufacture':
        setForm({
          ...form,
          yearOfManufacture: numericValue,
        });
        break;
      default:
        setForm({
          ...form,
          [name]: value,
        });
    }
  };

  useEffect(() => {
    if (form.activated_at) {
      const formattedDate = dayjs(form.activated_at);
      setActivatedDay(formattedDate);
    }
  }, [form]);

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DD');
    setActivatedDay(date);
    setForm((prevForm) => ({
      ...prevForm,
      activated_at: formattedDate,
    }));
  };

  const disabledStyle = {
    '& .MuiInputBase-input.Mui-disabled': {
      WebkitTextFillColor: '#000000',
    },
    '& .MuiInputLabel-root.Mui-disabled': {
      color: '#000000',
    },
  };

  return (
    <div className={styles.fields}>
      {!loading && (form.id || form.tag) ? (
        <>
          <TextField
            label={t('generic.number')}
            variant="outlined"
            name="number"
            value={form.number}
            onChange={handleChange}
            disabled={!isInputsDisabled}
            sx={disabledStyle}
          />
          <TextField
            label={t('generic.name')}
            variant="outlined"
            name="name"
            value={form.name}
            onChange={handleChange}
            disabled={!isInputsDisabled}
            sx={disabledStyle}
          />
          <TextField
            label={t('generic.description')}
            variant="outlined"
            name="description"
            value={form.description}
            onChange={handleChange}
            disabled={!isInputsDisabled}
            sx={disabledStyle}
          />
          <TextField
            label={t('generic.serialNumber')}
            variant="outlined"
            name="serialNumber"
            value={form.serialNumber}
            onChange={handleChange}
            disabled={!isInputsDisabled}
            sx={disabledStyle}
          />
          {responsable && (
            <TextField
              value={form.responsiblePerson.id}
              select
              label={t('generic.responsiblePerson')}
              name="responsiblePerson"
              onChange={handleChange}
              disabled={!isInputsDisabled}
              sx={disabledStyle}
            >
              {responsable
                ?.filter((user) => user.id != form.deputy.id)
                .map((user) => (
                  <MenuItem key={user.id} value={user.id}>
                    {`${user.firstname} ${user.lastname}`}
                  </MenuItem>
                ))}
            </TextField>
          )}
          {responsable && (
            <TextField
              value={form.deputy.id}
              select
              label={t('generic.deputy')}
              name="deputy"
              onChange={handleChange}
              disabled={!isInputsDisabled}
              sx={disabledStyle}
            >
              {responsable
                ?.filter((user) => user.id != form.responsiblePerson.id)
                .map((user) => (
                  <MenuItem key={user.id} value={user.id}>
                    {`${user.firstname} ${user.lastname}`}
                  </MenuItem>
                ))}
            </TextField>
          )}
          <TextField
            label={t('generic.yearOfManufacture')}
            variant="outlined"
            name="yearOfManufacture"
            type="number"
            value={form.yearOfManufacture}
            onChange={handleChange}
            disabled={!isInputsDisabled}
            sx={disabledStyle}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
            <DatePicker
              label={t('generic.activatedDay')}
              onChange={handleDateChange}
              fullWidth
              disabled={!isInputsDisabled || form.status === 'inactive'}
              sx={disabledStyle}
              value={activatedDay}
            />
          </LocalizationProvider>
          {location && (
            <TextField
              value={form.location.id}
              select
              label={t('generic.location')}
              name="location"
              onChange={handleChange}
              disabled={!isInputsDisabled}
              sx={disabledStyle}
            >
              {location?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          )}
          {group && (
            <TextField
              value={form.machineGroup.id}
              select
              label={t('generic.machineGroup')}
              name="machineGroup"
              onChange={handleChange}
              disabled={!isInputsDisabled}
              sx={disabledStyle}
            >
              {group?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          )}

          <TextField
            value={form.status}
            select
            label={t('generic.status')}
            name="status"
            onChange={handleChange}
            disabled={!isInputsDisabled}
            sx={disabledStyle}
          >
            {statusesMachine.map((status) => (
              <MenuItem key={status.value} value={status.value}>
                {status.name}
              </MenuItem>
            ))}
          </TextField>
        </>
      ) : (
        <div>loading</div>
      )}
    </div>
  );
}

export default MachineProfileInputs;
