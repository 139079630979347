import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../hooks/useAuth';
import { fetchUser } from '../../features/auth/authSlice';
import {
  useDisableProviderChangedMutation,
  useUpdateMutation,
  useUpdatePasswordMutation,
} from '../../app/services/users';
import { getLanguages, getPermissions } from '../../constants/options';
import UserProfilNotification from './UserProfileNotification';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import styles from './style.module.css';

import { validateFormData, getValidationErrorMessage, sx } from '../../helpers';
import { putUserSchema, postNewPasswordSchema } from '../../schemas';
import Cookies from 'js-cookie';
import removeData from '../../helpers/removeData';
import { useNavigate } from 'react-router-dom';
import Toast from '../../components/Toast';

const buttonSx = {
  padding: '5px 22px',
  height: '42px',
  '&.Mui-disabled': {
    pointerEvents: 'auto',
  },
};

function ConfirmReauthorization({ open, handleClose, handleConfirm }) {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm">
      <DialogContent>
        <Typography>{t('message.changeEmailConfirmation')}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('generic.cancel')}</Button>
        <Button onClick={handleConfirm}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}

function AccountSecurity({ userId, provider, providerChanged }) {
  const { t } = useTranslation();
  const [editModeOn, setEditModeOn] = useState(false);
  const inputDisabled = !editModeOn;

  const getFields = (t) => [
    {
      name: 'oldPassword',
      label: t('generic.oldPasswordFieldLabel'),
    },
    {
      name: 'newPassword',
      label: t('generic.newPasswordFieldLabel'),
    },
  ];

  const initialValue = { oldPassword: '', newPassword: '' };
  const [value, setValue] = useState(initialValue);
  const handleChange = (e) => {
    setValue((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const [passwordVisible, setPasswordVisible] = useState({ oldPassword: '', newPassword: '' });
  const handleChangeVisibility = (fieldName) => {
    setPasswordVisible((prev) => ({ ...prev, [fieldName]: !prev[fieldName] }));
  };

  const [formValidationErrors, setFormValidationErrors] = useState(null);
  const oldPasswordError = getValidationErrorMessage(formValidationErrors, 'oldPassword');
  const newPasswordError = getValidationErrorMessage(formValidationErrors, 'newPassword');

  const [updatePassword] = useUpdatePasswordMutation();

  const handleSubmit = async () => {
    if (!userId) return;

    const validationErrors = validateFormData(t, postNewPasswordSchema, value);
    if (!!validationErrors) {
      setFormValidationErrors(validationErrors);
      return;
    }

    setFormValidationErrors(null);

    try {
      await updatePassword({
        id: userId,
        passwords: { currentPassword: value.oldPassword, newPassword: value.newPassword },
      });

      setValue(initialValue);
      setEditModeOn(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box sx={{ marginTop: '30px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box className={styles.account_info}>
          <Typography sx={{ fontWeight: 600 }} variant="h6">
            {t('generic.passwordUpdateTitle')}
          </Typography>
          <Tooltip title={t('userProfile.information')} placement="top" arrow>
            <InfoOutlinedIcon color="primary" />
          </Tooltip>
        </Box>

        {!editModeOn ? (
          <Tooltip
            title={
              provider === 'microsoft' && providerChanged
                ? t('provider.authMethodChanged')
                : provider === 'microsoft' && !providerChanged
                ? t('provider.cantChangePassword')
                : ''
            }
          >
            <span>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setEditModeOn(true);
                }}
                sx={buttonSx}
                disabled={provider === 'microsoft'}
              >
                {t('generic.edit')}
              </Button>
            </span>
          </Tooltip>
        ) : (
          <Box className={styles.buttons}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setEditModeOn(false);
              }}
              sx={buttonSx}
            >
              {t('generic.cancel')}
            </Button>
            <Button variant="contained" color="primary" onClick={handleSubmit} sx={buttonSx}>
              {t('generic.save')}
            </Button>
          </Box>
        )}
      </Box>

      <Box className={styles.inputs_area}>
        {getFields(t).map((field) => (
          <TextField
            key={field.name}
            label={field.label}
            variant="outlined"
            name={field.name}
            type={passwordVisible[field.name] ? 'text' : 'password'}
            value={value[field.name]}
            onChange={handleChange}
            className={styles.text_field}
            sx={sx.disabledTextFieldOverride}
            disabled={inputDisabled}
            InputProps={{
              endAdornment: inputDisabled ? null : (
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {
                    handleChangeVisibility(field.name);
                  }}
                  edge="end"
                >
                  {passwordVisible[field.name] ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              ),
            }}
            InputLabelProps={{
              shrink: true,
            }}
            error={field.name === 'oldPassword' ? !!oldPasswordError : !!newPasswordError}
            helperText={
              field.name === 'oldPassword' ? oldPasswordError ?? ' ' : newPasswordError ?? ' '
            }
            placeholder="**********"
          />
        ))}
      </Box>
    </Box>
  );
}

function UserProfile() {
  const { t } = useTranslation();
  const permissions = getPermissions(t);
  const languages = getLanguages(t);

  const initialForm = {
    firstname: t('generic.firstName'),
    lastname: t('generic.lastname'),
    email: t('generic.email'),
    password: t('generic.password'),
    language: t('generic.language'),
    role: t('generic.role'),
    status: t('generic.status'),
  };
  const [form, setForm] = useState(initialForm);
  const [isInputsDisabled, setIsInputsDisabled] = useState(false);

  const dispatch = useDispatch();
  const { user } = useAuth();
  const [updateUser] = useUpdateMutation();
  const [disableProviderChanged] = useDisableProviderChangedMutation();

  const handleCloseToast = async () => {
    await disableProviderChanged()
      .unwrap()
      .then(() => {
        dispatch(fetchUser());
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (user) {
      setForm(user);
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case 'role':
        setForm({
          ...form,
          role: value,
        });
        break;
      case 'language':
        setForm({
          ...form,
          language: value,
        });
        break;
      default:
        setForm({
          ...form,
          [name]: value,
        });
    }
  };

  const [formValidationErrors, setFormValidationErrors] = useState(null);
  const firstNameError = getValidationErrorMessage(formValidationErrors, 'firstname');
  const lastNameError = getValidationErrorMessage(formValidationErrors, 'lastname');
  const emailError = getValidationErrorMessage(formValidationErrors, 'email');
  const languageError = getValidationErrorMessage(formValidationErrors, 'language');
  const roleError = getValidationErrorMessage(formValidationErrors, 'role');

  const [open, setOpen] = useState(false);
  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const navigate = useNavigate();
  const handleLogout = () => {
    Cookies.remove('token');
    removeData(dispatch);
    navigate('/sign-in');
  };

  const handleUpdateUser = async (requiresReauthorization = false) => {
    const { id, ...userData } = form;
    try {
      await updateUser({ id, userData });
      if (requiresReauthorization) {
        return handleLogout();
      }

      dispatch(fetchUser());
      setIsInputsDisabled(false);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const handleSubmit = () => {
    const noChanges = JSON.stringify(form) === JSON.stringify(user);
    if (noChanges) {
      setIsInputsDisabled(false);
      return;
    }

    const { id, ...userData } = form;
    const validationErrors = validateFormData(t, putUserSchema, userData);
    if (!!validationErrors) {
      setFormValidationErrors(validationErrors);
      return;
    }

    if (form.email === user.email) {
      handleUpdateUser();
    } else {
      handleOpen();
    }
  };

  const handleCancel = () => {
    setIsInputsDisabled(false);
    setForm(user);
  };

  return (
    <>
      <section className={styles.container}>
        <div className={styles.header}>
          <Typography variant="h5" fontWeight="bold">
            {t('generic.profile')}
          </Typography>
        </div>
        <Divider variant="fullWidth " flexItem />
        <div className={styles.account}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div className={styles.account_info}>
              <Typography variant="h6" fontWeight="bold">
                {t('generic.account')}
              </Typography>
              <Tooltip title={t('userProfile.information')} placement="top" arrow>
                <InfoOutlinedIcon color="primary" />
              </Tooltip>
            </div>
            {!isInputsDisabled ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setIsInputsDisabled(true)}
                sx={buttonSx}
              >
                {t('generic.edit')}
              </Button>
            ) : (
              <div className={styles.buttons}>
                <Button variant="outlined" color="primary" onClick={handleCancel} sx={buttonSx}>
                  {t('generic.cancel')}
                </Button>
                <Button variant="contained" color="primary" onClick={handleSubmit} sx={buttonSx}>
                  {t('generic.save')}
                </Button>
              </div>
            )}
          </Box>
          <div className={styles.inputs_area}>
            <TextField
              label={t('generic.firstName')}
              variant="outlined"
              name="firstname"
              value={form.firstname}
              onChange={handleChange}
              className={styles.text_field}
              sx={sx.disabledTextFieldOverride}
              disabled={!isInputsDisabled}
              error={!!firstNameError}
              helperText={!!firstNameError && isInputsDisabled ? firstNameError : ' '}
            />
            <TextField
              label={t('generic.lastName')}
              variant="outlined"
              name="lastname"
              value={form.lastname}
              onChange={handleChange}
              className={styles.text_field}
              sx={sx.disabledTextFieldOverride}
              disabled={!isInputsDisabled}
              error={!!lastNameError}
              helperText={!!lastNameError && isInputsDisabled ? lastNameError : ' '}
            />
            <TextField
              label={t('generic.email')}
              variant="outlined"
              name="email"
              value={form.email}
              onChange={handleChange}
              className={styles.text_field}
              sx={sx.disabledTextFieldOverride}
              disabled={!isInputsDisabled}
              error={!!emailError}
              helperText={!!emailError && isInputsDisabled ? emailError : ' '}
            />
            <FormControl>
              <TextField
                value={form.language}
                select
                label={t('userProfile.languagePreferences')}
                name="language"
                onChange={handleChange}
                className={styles.text_field}
                sx={sx.disabledTextFieldOverride}
                disabled={!isInputsDisabled}
                error={!!languageError}
              >
                {languages.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <FormHelperText error>
                {!!languageError && isInputsDisabled ? languageError : ' '}
              </FormHelperText>
            </FormControl>
            {user?.role === 'admin' && (
              <FormControl>
                <TextField
                  value={form.role}
                  select
                  label={t('userProfile.permission')}
                  name="role"
                  onChange={handleChange}
                  className={styles.text_field}
                  sx={sx.disabledTextFieldOverride}
                  disabled={!isInputsDisabled}
                  error={!!roleError}
                >
                  {permissions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <FormHelperText error>
                  {!!roleError && isInputsDisabled ? roleError : ' '}
                </FormHelperText>
              </FormControl>
            )}
          </div>
          <AccountSecurity
            userId={user?.id}
            provider={user?.provider}
            providerChanged={user?.provider_changed}
          />
        </div>
        <UserProfilNotification id={user?.id} />
      </section>

      <ConfirmReauthorization
        open={open}
        handleClose={handleClose}
        handleConfirm={() => {
          handleUpdateUser(true);
        }}
      />

      <Toast
        open={!!user?.provider_changed}
        handleClose={handleCloseToast}
        severity="info"
        message={t('provider.authMethodChanged')}
        autoHideDuration={null}
      />
    </>
  );
}

export default UserProfile;
