import { useEffect, useState } from 'react';
import TableComponent from '../../components/Table';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { fetchGroup } from '../../features/groups/groupsSlice';
import { fetchMaintenancesGroup } from '../../features/maintenances/maintenancesSlice';
import { ACTIONS } from '../../constants/actionTables';
import { getIntervals, getIntervalsNotify } from '../../constants/options';
import ModalAddMaintanenceTask from '../../components/ModalAddMaintanenceTask';
import ModalStatusGroup from '../../components/ModalStatusGroup';
import ModalAddManual from '../../components/ModalAddManual';
import ModalAddSupplyGroup from '../../components/ModalAddSupplyGroup';

import { Divider, Typography } from '@mui/material';

import styles from './style.module.css';
import { useAuth } from '../../hooks/useAuth';

function SpecificGroup() {
  const [dataMaintanence, setDataMaintanence] = useState(null);
  const [dataManuals, setDataManuals] = useState(null);
  const [suppliesData, setSuppliesData] = useState(null);
  const [suppliesList, setSuppliesList] = useState([]);
  const [group, setGroup] = useState(null);

  const { id } = useParams();
  const { user } = useAuth();
  const { t } = useTranslation();
  const intervals = getIntervals(t);
  const intervalsNotify = getIntervalsNotify(t);

  const dispatch = useDispatch();

  const titlesTask = [
    t('generic.number'),
    t('generic.name'),
    t('generic.interval'),
    t('specificGroup.NotificationLeadTime'),
    t('generic.maintenanceType'),
    t('generic.machineGroups'),
  ];

  if (user && user.role === 'admin') {
    titlesTask.push(t('generic.action'));
  }

  const titlesSupplies = [
    t('generic.number'),
    t('generic.name'),
    t('generic.description'),
    t('generic.manufacture'),
    t('generic.safetySheet'),
    t('generic.action'),
  ];
  const titlesManuals = [
    t('generic.number'),
    t('generic.name'),
    t('generic.description'),
    t('generic.safetySheet'),
    t('generic.action'),
  ];

  const fetchGroupId = async () => {
    try {
      const response = await dispatch(fetchGroup(id));
      setGroup(response.payload);
    } catch (error) {
      console.error('Error fetching maintenance data:', error);
    }
  };

  useEffect(() => {
    fetchGroupId();
  }, [id]);

  const fetchMaintenanceData = async () => {
    try {
      const response = await dispatch(fetchMaintenancesGroup(group.name));
      const data = response.payload.map((maintenance) => ({
        id: maintenance.id,
        number: maintenance.id,
        name: maintenance.name,
        interval: findIntervalDescription(JSON.parse(maintenance.interval), intervals),
        notificationLeadTime: findIntervalNotifyDescription(
          maintenance.notification_lead_time,
          intervalsNotify
        ),
        maintenanceType: maintenance.maintenance_type?.name,
        machineGroup: maintenance.machine_group?.name,
      }));
      setDataMaintanence(data);
    } catch (error) {
      console.error('Error fetching maintenance data:', error);
    }
  };

  useEffect(() => {
    if (group) {
      fetchMaintenanceData();

      const data = group.manuals.map((manual) => ({
        id: manual.id,
        number: manual.id,
        name: manual.name,
        description: manual.description,
        safetySheet: manual.original_filename,
        link: manual.filename,
      }));
      setDataManuals(data);

      setSuppliesData(
        group.machine_supplies.map((supply) => ({
          id: supply.id,
          number: supply.supply.id,
          name: supply.supply.name,
          description: supply.supply.description,
          manufacture: supply.supply.manufacture,
          link: supply.supply.filename,
          safetySheet: supply.supply.original_filename,
        }))
      );
      const suppliesIds = group.machine_supplies.map((supply) => supply.supply.id);

      setSuppliesList(suppliesIds);
    }
  }, [group]);

  const findIntervalDescription = (value, intervalData) => {
    const interval = intervalData.find(
      (item) => item.value.type === value.type && item.value.value === value.value
    );
    return interval ? interval.period : '';
  };

  const findIntervalNotifyDescription = (value, intervalData) => {
    const interval = intervalData.find((item) => item.value === value);
    return interval ? interval.period : '';
  };

  return (
    <section className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>
          <Link to={`/machines-groups`} style={{ textDecoration: 'none', color: 'black' }}>
            <Typography variant="h5" fontWeight="bold" sx={{ opacity: 0.5 }}>
              {t('specificGroup.machineGroups')}
            </Typography>
          </Link>
          <Typography variant="h5" fontWeight="bold">
            {group && group.name} {t('generic.group')}
          </Typography>
        </div>
        {user && user.role === 'admin' && <ModalStatusGroup id={id} action={'archived'} />}
      </div>
      <Divider variant="fullWidth " flexItem />

      <div className={styles.header} style={{ margin: '20px 0' }}>
        <Typography variant="h6" fontWeight="bold">
          {t('specificGroup.maintenanceTasks')}
        </Typography>
        {user && user.role === 'admin' && (
          <ModalAddMaintanenceTask
            type={'add'}
            fetchData={fetchMaintenanceData}
            groupId={group?.id}
          />
        )}
      </div>
      <TableComponent
        titles={titlesTask}
        data={dataMaintanence}
        fetchData={fetchMaintenanceData}
        action={ACTIONS.SPECIFIC_GROUP_MAINTENANCE_TASK}
      />

      <div className={styles.header} style={{ marginTop: '40px' }}>
        <Typography variant="h6" fontWeight="bold">
          {t('generic.manuals')}
        </Typography>

        {user && user.role === 'admin' && <ModalAddManual id={id} fetchData={fetchGroupId} />}
      </div>
      <TableComponent
        titles={titlesManuals}
        data={dataManuals}
        fetchData={fetchGroupId}
        action={ACTIONS.SPECIFIC_GROUP_MANUALS}
      />

      <div className={styles.header} style={{ marginTop: '40px' }}>
        <Typography variant="h6" fontWeight="bold">
          {t('generic.supplies')}
        </Typography>

        {user && user.role === 'admin' && (
          <ModalAddSupplyGroup
            id={id}
            fetchData={fetchGroupId}
            selectedSuppliesList={suppliesList}
          />
        )}
      </div>
      <TableComponent
        titles={titlesSupplies}
        data={suppliesData}
        fetchData={fetchGroupId}
        action={ACTIONS.SPECIFIC_GROUP_SUPPLIES}
      />
    </section>
  );
}

export default SpecificGroup;
