// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__CBl7t {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 25px 80px;

  @media (max-width: 1024px) {
    padding: 20px;
  }
}

.style_header__pE7Yq {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 25px;
}

.style_options__Nrj-a {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 30px;
  margin-bottom: 35px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/AuditLogs/style.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,YAAY;EACZ,WAAW;EACX,kBAAkB;;EAElB;IACE,aAAa;EACf;AACF;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,WAAW;EACX,SAAS;EACT,mBAAmB;;EAEnB;IACE,sBAAsB;EACxB;AACF","sourcesContent":[".container {\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  height: 100%;\n  width: 100%;\n  padding: 25px 80px;\n\n  @media (max-width: 1024px) {\n    padding: 20px;\n  }\n}\n\n.header {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n  margin-bottom: 25px;\n}\n\n.options {\n  display: flex;\n  align-items: center;\n  flex-wrap: wrap;\n  width: 100%;\n  gap: 30px;\n  margin-bottom: 35px;\n\n  @media (max-width: 1024px) {\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__CBl7t`,
	"header": `style_header__pE7Yq`,
	"options": `style_options__Nrj-a`
};
export default ___CSS_LOADER_EXPORT___;
