import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useGroups } from '../../hooks/useGroups';
import { useMaintenanceTypes } from '../../hooks/useMaintenanceTypes';
import { useRegisterMutation, useUpdateMutation } from '../../app/services/maintenances';
import { fetchMaintenance } from '../../features/maintenances/maintenancesSlice';
import ModalComponent from '../ModalComponent';
import ModalEditor from '../ModalEditor';
import { getIntervals, getIntervalsNotify, daysPerInterval } from '../../constants/options';
import { ACTIONS } from '../../constants/actionTables';

import {
  Button,
  FormControl,
  FormHelperText,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import styles from '../ModalComponent/modalStyles.module.css';
import { validateFormData, getValidationErrorMessage } from '../../helpers';
import { postMaintenanceTaskSchema } from '../../schemas/postMaintenanceTaskSchema';

const initialForm = {
  id: '',
  name: '',
  interval: '',
  notificationLeadTime: '',
  maintenanceType: '',
  machineGroup: '',
};

export default function ModalAddMaintanenceTask({ id, type, fetchData, groupId }) {
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState(initialForm);
  const [errorResponse, setErrorResponse] = useState('');
  const [group, setGroup] = useState(null);
  const [types, setTypes] = useState(null);
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState(null);

  const [formValidationErrors, setFormValidationErrors] = useState(null);
  const nameError = getValidationErrorMessage(formValidationErrors, 'name');
  const descrError = getValidationErrorMessage(formValidationErrors, 'description');
  const intervalError = getValidationErrorMessage(formValidationErrors, 'interval');
  const notificationLeadTimeError = getValidationErrorMessage(
    formValidationErrors,
    'notificationLeadTime'
  );
  const maintenanceTypeError = getValidationErrorMessage(formValidationErrors, 'maintenanceType');

  const { t } = useTranslation();
  const intervals = getIntervals(t);
  const dispatch = useDispatch();
  const { groups } = useGroups();
  const [registerMaintenance, { error }] = useRegisterMutation();
  const { maintenanceTypes } = useMaintenanceTypes();
  const [updateMaintenance] = useUpdateMutation();

  const computedLeadTimeOptions = useMemo(() => {
    const leadTimeOptions = getIntervalsNotify(t);
    const interval = form.interval;
    if (!interval) {
      return leadTimeOptions;
    }

    const days = daysPerInterval[interval.id];
    if (!days) {
      return leadTimeOptions;
    }

    return leadTimeOptions.filter((leadTimeOption) => leadTimeOption.value <= days);
  }, [t, form.interval]);

  useEffect(() => {
    if (id && open) {
      async function fetchData() {
        try {
          const response = await dispatch(fetchMaintenance(id));
          const intervalResponse = JSON.parse(response.payload.interval);
          const fetchedInterval = intervals.find(
            (interval) =>
              interval.value.type === intervalResponse.type &&
              interval.value.value === intervalResponse.value
          );
          setForm({
            id: response.payload.id,
            name: response.payload.name,
            interval: fetchedInterval,
            notificationLeadTime: response.payload.notification_lead_time,
            maintenanceType: response.payload.maintenance_type,
            machineGroup: response.payload.machine_group,
          });
          let description;

          if (typeof response.payload.description === 'string') {
            description = JSON.parse(response.payload.description);
          } else {
            description = response.payload.description;
          }
          setContent(description);
        } catch (error) {
          console.error('Error fetching maintenance:', error);
        }
      }
      fetchData();
    }
  }, [id, open, dispatch]);

  useEffect(() => {
    if (error) {
      setErrorResponse(error.data.message);
    }
  }, [error]);

  useEffect(() => {
    if (groups) {
      setGroup(groups?.activeMachineGroups);
    }
    if (maintenanceTypes) {
      setTypes(maintenanceTypes);
    }
  }, [groups, maintenanceTypes]);

  useEffect(() => {
    if (group && types) {
      setLoading(false);
    }
  }, [group, types]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setForm(initialForm);
    setContent(null);
    setFormValidationErrors(null);
  };

  const handleChange = (e) => {
    setErrorResponse('');
    const { name, value } = e.target;
    const numericValue = parseInt(value, 10);

    switch (name) {
      case 'maintenanceType':
        setForm({
          ...form,
          maintenanceType: {
            id: numericValue,
          },
        });
        break;
      case 'interval':
        setForm({
          ...form,
          notificationLeadTime: '',
          interval: { id: numericValue },
        });
        break;
      case 'notificationLeadTime':
        setForm({
          ...form,
          notificationLeadTime: numericValue,
        });
        break;
      default:
        setForm({ ...form, [name]: value });
    }
  };

  const handleSubmit = async () => {
    const validationErrors = validateFormData(t, postMaintenanceTaskSchema, {
      ...form,
      description: content,
    });
    if (!!validationErrors) {
      setFormValidationErrors(validationErrors);
      return;
    }

    if (id) {
      try {
        const formWithDescription = { ...form };
        formWithDescription.description = JSON.stringify(content);
        const selectedInterval = intervals.find((interval) => interval.id === form.interval.id);
        formWithDescription.interval = selectedInterval.value;
        await updateMaintenance({ id, form: formWithDescription });
        if (!error) {
          await fetchData();
          handleClose();
        }
      } catch (err) {
        setErrorResponse(err.message);
        console.error('Error update task:', err);
      }
    } else {
      try {
        const formWithoutId = { ...form };
        delete formWithoutId.id;
        formWithoutId.description = content;
        formWithoutId.machineGroup = { id: +groupId };
        const selectedInterval = intervals.find((interval) => interval.id === form.interval.id);
        formWithoutId.interval = selectedInterval.value;
        await registerMaintenance(formWithoutId);
        if (!error) {
          await fetchData();
          handleClose();
        }
      } catch (err) {
        setErrorResponse(err.message);
        console.error('Error registering task:', err);
      }
    }
  };

  return (
    <div>
      {type === 'add' ? (
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpen}
          style={{ padding: '8px 22px', textTransform: 'none' }}
        >
          {t('modals.add+')}
        </Button>
      ) : (
        <Button
          variant="contained"
          color="primary"
          style={{ padding: '5px 15px' }}
          onClick={handleOpen}
        >
          {t('generic.edit')}
        </Button>
      )}

      <ModalComponent open={open} handleClose={handleClose}>
        <div className={styles.modal}>
          {(form.id || type === 'add') && !loading && (
            <div className={styles.container}>
              <Typography variant="h5" fontWeight="bold">
                {type === 'add' ? t('modals.create') : t('modals.update')}{' '}
                {t('modals.maintenanceTask')}
              </Typography>
              <TextField
                value={form.name}
                label={t('generic.name')}
                variant="outlined"
                name="name"
                onChange={handleChange}
                fullWidth
                error={!!nameError}
                helperText={nameError ?? ' '}
              />

              <FormControl>
                <ModalEditor content={content} setContent={setContent} type={ACTIONS.EDIT} />
                <FormHelperText error>{descrError ?? ' '}</FormHelperText>
              </FormControl>

              <TextField
                value={form.interval?.id}
                select
                label={t('generic.interval')}
                name="interval"
                onChange={handleChange}
                error={!!intervalError}
                helperText={intervalError ?? ' '}
              >
                {intervals.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.period}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                value={form.notificationLeadTime}
                select
                label={t('specificGroup.NotificationLeadTime')}
                name="notificationLeadTime"
                onChange={handleChange}
                error={!!notificationLeadTimeError}
                helperText={notificationLeadTimeError ?? ' '}
              >
                {computedLeadTimeOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.period}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                value={form.maintenanceType.id}
                select
                label={t('generic.maintenanceType')}
                name="maintenanceType"
                onChange={handleChange}
                error={!!maintenanceTypeError}
                helperText={maintenanceTypeError ?? ' '}
              >
                {types.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>

              {errorResponse &&
                (Array.isArray(errorResponse) ? (
                  errorResponse.map((item, index) => (
                    <Typography key={index} color="error">
                      {item}
                    </Typography>
                  ))
                ) : (
                  <Typography color="error">{errorResponse}</Typography>
                ))}

              <div className={styles.button_group}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleClose}
                  style={{ padding: '5px 22px' }}
                >
                  {t('modals.cancel')}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  style={{ padding: '5px 22px' }}
                >
                  {type === 'add' ? t('modals.create') : t('modals.update')}
                </Button>
              </div>
            </div>
          )}
        </div>
      </ModalComponent>
    </div>
  );
}
