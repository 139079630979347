import { z } from 'zod';

export const emailFieldSchema = (t) =>
  z
    .string()
    .email({ message: t('error.invalidEmail') })
    .trim();

export const firstNameSchema = (t) =>
  z
    .string()
    .min(1, { message: t('error.requiredFirstName') })
    .trim();

export const lastNameSchema = (t) =>
  z
    .string()
    .min(1, { message: t('error.requiredLastName') })
    .trim();

export const roleSchema = (t) =>
  z.enum(['user', 'admin'], { message: t('error.invalidPermission') });

export const supplyNumSchema = (t) =>
  z
    .string()
    .min(1, { message: t('error.supplyNumberRequired') })
    .trim();

export const supplyNameSchema = (t) =>
  z
    .string()
    .min(1, { message: t('error.supplyNameRequired') })
    .trim();

export const supplyFileSchema = (t) =>
  z.any().refine((file) => !!file, t('error.supplyFileRequired'));

export const requiredFieldSchema = (message) => z.string().min(1, { message }).trim();

export const passwordFieldSchema = (t) =>
  z
    .string()
    .min(3, { message: t('error.passwordTooShort') })
    .regex(/[A-Z]/, {
      message: t('error.passwordUppercase'),
    })
    .regex(/[a-z]/, {
      message: t('error.passwordLowercase'),
    })
    .regex(/\d/, {
      message: t('error.passwordRequiresDigit'),
    })
    .regex(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/, {
      message: t('error.passwordSpecialChar'),
    });
