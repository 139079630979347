import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";

export const maintenancesApi = createApi({
  reducerPath: "maintenancesApi",
  baseQuery,
  endpoints: (builder) => ({
    getGroups: builder.query({
      query: () => "maintenances",
    }),
    register: builder.mutation({
      query: (form) => ({
        url: "maintenances",
        method: "POST",
        body: form,
      }),
    }),
    update: builder.mutation({
      query: ({ id, form }) => ({
        url: `maintenances/${id}`,
        method: "PUT",
        body: form,
      }),
    }),
  }),
});

export const { useGetMaintenanceQuery, useRegisterMutation, useUpdateMutation } =
  maintenancesApi;
