import { z } from 'zod';
import { firstNameSchema, lastNameSchema, roleSchema, emailFieldSchema } from './fields';

export const putUserSchema = (t) =>
  z.object({
    firstname: firstNameSchema(t),
    lastname: lastNameSchema(t),
    language: z.enum(['english', 'german'], { message: t('error.invalidLanguagePreference') }),
    role: roleSchema(t),
    email: emailFieldSchema(t),
  });
