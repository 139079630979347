import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import SignIn from "../pages/SignIn";
import UserProfile from "../pages/UserProfile";
import UsersList from "../pages/UsersList";
import ResetPassword from "../pages/ResetPassword";
import MachinesList from "../pages/MachinesList";
import Calendar from "../pages/Calendar";
import MachineProfile from "../pages/MachineProfile";
import MachinesGroup from "../pages/MachinesGroup";
import Locations from "../pages/Locations";
import SpecificGroup from "../pages/SpecificGroup";
import Supplies from "../pages/Supplies";
import NotFoundPage from "../pages/NotFoundPage";
import AuditLogs from "../pages/AuditLogs";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/sign-in" element={<SignIn />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route element={<ProtectedRoute />}>
        <Route path="/" element={<Calendar />} />
        <Route path="/users" element={<UsersList />} />
        <Route path="/machines" element={<MachinesList />} />
        <Route path="/user-profile" element={<UserProfile />} />
        <Route path="/machines/:id" element={<MachineProfile />} />
        <Route path="/machines-groups" element={<MachinesGroup />} />
        <Route path="/machines-groups/:id" element={<SpecificGroup />} />
        <Route path="/locations" element={<Locations />} />
        <Route path="/supplies" element={<Supplies />} />
        <Route path="/audit-logs" element={<AuditLogs />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};
