import { useMemo } from 'react'
import { useSelector } from 'react-redux'

export const useLocations = () => {
  const locations = useSelector(state => state.locations.locations);
  const error = useSelector(state => state.locations.error);
  const isLoading = useSelector(state => state.locations.isLoading);

  return useMemo(() => ({ locations, error, isLoading }), [locations, error, isLoading]);
}
