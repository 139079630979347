import { useEffect, useState } from 'react';
import { useGroups } from '../../hooks/useGroups';
import { useTranslation } from 'react-i18next';
import { ACTIONS } from '../../constants/actionTables';
import ModalAddGroup from '../../components/ModalAddGroup';
import TableComponent from '../../components/Table';

import { Box, Tab, Tabs, Typography } from '@mui/material';

import styles from './style.module.css';
import { useAuth } from '../../hooks/useAuth';

function MachinesGroup() {
  const [value, setValue] = useState(0);
  const [dataActive, setDataActive] = useState(0);
  const [dataAchived, setDataAchived] = useState(0);

  const { groups } = useGroups();
  const { user } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    if (groups?.activeMachineGroups) {
      const preparedData = groups.activeMachineGroups.map((group) => ({
        id: group.id,
        image: `${process.env.REACT_APP_BASE_URL}machine-groups/image/${group.image}`,
        number: group.id,
        name: group.name,
        description: group.description,
      }));
      setDataActive(preparedData);
    }
    if (groups?.archivedMachineGroups) {
      const preparedData = groups.archivedMachineGroups.map((group) => ({
        id: group.id,
        image: `${process.env.REACT_APP_BASE_URL}machine-groups/image/${group.image}`,
        number: group.id,
        name: group.name,
        description: group.description,
      }));
      setDataAchived(preparedData);
    }
  }, [groups]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const titles = [
    t('generic.image'),
    t('generic.number'),
    t('generic.name'),
    t('generic.description'),
    t('generic.action'),
  ];

  return (
    <section className={styles.container}>
      <div className={styles.header}>
        <Typography variant="h5" fontWeight="bold">
          {t('generic.machineGroup')}
        </Typography>
        {user && user.role === 'admin' && <ModalAddGroup type={'add'} />}
      </div>
      <div className={styles.tabs}>
        <Box
          sx={{
            borderBottom: 1,
            borderTop: 1,
            borderColor: 'divider',
            marginBottom: 4,
          }}
        >
          <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary">
            <Tab label={t('machineGroups.activeGroups')} />
            <Tab label={t('machineGroups.archivedGroups')} />
          </Tabs>
        </Box>
        {value === 0 && (
          <TableComponent
            titles={titles}
            data={dataActive}
            action={ACTIONS.ACTIVE_MACHINES_GROUPS}
          />
        )}
        {value === 1 && (
          <TableComponent
            titles={titles}
            data={dataAchived}
            action={ACTIONS.ARCHIVED_MACHINES_GROUPS}
          />
        )}
      </div>
    </section>
  );
}

export default MachinesGroup;
