import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../hooks/useAuth';
import PopoverProfile from './PopoverProfile';
import PopoverMachines from './PopoverMachines';

import styles from './style.module.css';
import { ReactComponent as Logo } from '../../icons/logo.svg';

import { styled } from '@mui/material';
const StyledNavLink = styled(NavLink)(({ theme }) => ({
  ...theme.typography.h6,
  fontWeight: 400,
  textDecoration: 'none',
  color: theme.palette.text.primary,
  '&.active': {
    color: theme.palette.primary.main,
  },
  '&:hover, &:focus': {
    color: theme.palette.primary.dark,
  },
}));

function Header() {
  const { t } = useTranslation();
  const { user } = useAuth();

  return (
    <div className={styles.container}>
      <NavLink to="/" className={styles.logoLink}>
        <Logo height="64px" />
      </NavLink>
      <div className={styles.navigation}>
        {user?.role === 'admin' && (
          <StyledNavLink to="/users" className={({ isActive }) => (isActive ? 'active' : '')}>
            {t('generic.users')}
          </StyledNavLink>
        )}
        {user?.role === 'admin' && (
          <StyledNavLink to="/audit-logs" className={({ isActive }) => (isActive ? 'active' : '')}>
            {t('generic.auditLogs')}
          </StyledNavLink>
        )}
        <PopoverMachines />
        <StyledNavLink to="/" className={({ isActive }) => (isActive ? 'active' : '')}>
          {t('header.Calendar')}
        </StyledNavLink>
        <PopoverProfile />
      </div>
    </div>
  );
}

export default Header;
