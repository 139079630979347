// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_fields__Lblxu {
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 305px;

  @media (max-width: 1024px) {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/MachineProfileInputs/style.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,gBAAgB;;EAEhB;IACE,WAAW;EACb;AACF","sourcesContent":[".fields {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  min-width: 305px;\n\n  @media (max-width: 1024px) {\n    width: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fields": `style_fields__Lblxu`
};
export default ___CSS_LOADER_EXPORT___;
