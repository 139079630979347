import Cookies from 'js-cookie';

import { fetchGroups } from "../features/groups/groupsSlice";
import { fetchLocations } from "../features/locations/locationsSlice";
import { fetchMachines } from "../features/machines/machinesSlice";
import { fetchMaintenanceTypes } from "../features/maintenanceTypes/maintenanceTypesSlice";
import { fetchSupplies } from "../features/supplies/suppliesSlice";
import { fetchUsers } from "../features/users/usersSlice";

function fetchData(dispatch) {
  const token = Cookies.get('token');
  if (!token) return;

  dispatch(fetchUsers());
  dispatch(fetchMachines());
  dispatch(fetchGroups());
  dispatch(fetchLocations());
  dispatch(fetchSupplies());
  dispatch(fetchMaintenanceTypes());
}

export default fetchData;
