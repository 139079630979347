import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";

export const notificationsApi = createApi({
  reducerPath: "notificationsApi",
  baseQuery,
  endpoints: (builder) => ({
    getNotifications: builder.query({
      query: () => "notifications",
    }),
    register: builder.mutation({
      query: (notificationsData) => ({
        url: "notifications",
        method: "POST",
        body: notificationsData,
      }),
    }),
    update: builder.mutation({
      query: ({ id, notificationsData }) => ({
        url: `notifications/${id}`,
        method: "PUT",
        body: notificationsData,
      }),
    }),
  }),
});

export const { useGetNotificationsQuery, useRegisterMutation, useUpdateMutation  } = notificationsApi;
