import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchMaintenancesMonth } from '../../features/maintenances/maintenancesSlice';
import CalendarGrid from './CalendarGrid';

import { Box, Tab, Tabs, Typography, CircularProgress } from '@mui/material';

import styles from './style.module.css';
import CalendarList from './CalendarList';
import MultiSelectNotify from '../../components/MultiSelectNotify';
import {
  fetchFilters,
  filterMachines,
  toggleGroup,
  toggleLocation,
  toggleMachine,
  toggleType,
  setActiveCalendarView,
  selectActiveCalendarView,
} from '../../features/filters/filtersSlice';
import { INVALID_DATE_STRING } from '../../helpers';

function Calendar() {
  const [taskData, setTaskData] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [fetchedTasks, setFetchedTasks] = useState(null);
  const [filteredTasks, setFilteredTasks] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const filters = useSelector((state) => state.filters);

  const fetchData = async () => {
    if (
      startDate &&
      endDate &&
      startDate !== INVALID_DATE_STRING &&
      endDate !== INVALID_DATE_STRING
    ) {
      setIsLoading(true);
      setFetchedTasks([]);
      const startDateObj = new Date(startDate);
      startDateObj.setDate(startDateObj.getDate() - 1);
      const param = {
        startDate: startDateObj.toISOString().split('T')[0],
        endDate: endDate.split('T')[0],
      };
      const response = await dispatch(fetchMaintenancesMonth(param));
      if (response.payload) {
        setFetchedTasks(response.payload);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [startDate, endDate]);

  const [showReportRepair, setShowReportRepair] = useState(true);

  useEffect(() => {
    if (fetchedTasks && Array.isArray(fetchedTasks)) {
      const filteredTasks = fetchedTasks.filter((task) => {
        if (!filters.selectedFilters.machines.includes(+task.machine?.id)) {
          return false;
        }
        if (!filters.selectedFilters.machineGroups.includes(+task.machine?.machine_group?.id)) {
          return false;
        }
        if (!filters.selectedFilters.locations.includes(+task.machine?.location?.id)) {
          return false;
        }
        if (
          task.maintenance &&
          !filters.selectedFilters.maintenanceTypes.includes(
            +task.maintenance?.maintenance_type?.id
          )
        ) {
          return false;
        }
        if (!task.maintenance && !showReportRepair) {
          return false;
        }
        return true;
      });

      setFilteredTasks(filteredTasks);
    }
  }, [fetchedTasks, filters.selectedFilters, showReportRepair]);

  useEffect(() => {
    if (filteredTasks && Array.isArray(filteredTasks)) {
      const items = filteredTasks.map((item, index) => {
        const startDate = item.created_at ? item.created_at.split('T')[0] : '';
        const interval = item.maintenance?.interval ? JSON.parse(item.maintenance?.interval) : '';
        const description = item.maintenance?.description
          ? JSON.parse(item.maintenance?.description)
          : '';

        return {
          id: index,
          machineId: item.machine?.id || '',
          machineName: item.machine?.name || '',
          machineNumber: item.machine?.number || '',
          groupName: item.machine?.machine_group?.name || '',
          groupImage: item.machine?.machine_group?.image || '',
          description: description,
          maintenanceName: item.maintenance ? item.maintenance.name : 'Report a repair',
          maintenanceType: item.maintenance
            ? item.maintenance.maintenance_type.name
            : 'Report a repair',
          start: startDate,
          status: item.status,
          filename: item.filename,
          original_filename: item.original_filename,
          comment: item.comment,
          maintanenceId: item.maintenance?.id,
          createdAt: item.created_at,
          updatedAt: item.updated_at,
          completedAt: item.completed_at,
          intervalType: interval.type,
          intervalValue: interval.value,
          notifyTime: item.maintenance?.notification_lead_time,
          responsibleFirstname: item.user?.firstname,
          responsibleLastname: item.user?.lastname,
          title: item.maintenance?.name || '',
        };
      });

      setTaskData(items);
    }
  }, [filteredTasks]);

  useEffect(() => {
    dispatch(fetchFilters());
  }, []);

  useEffect(() => {
    dispatch(filterMachines());
  }, [filters.selectedFilters]);

  const value = useSelector(selectActiveCalendarView);
  const setValue = (activeView) => {
    dispatch(setActiveCalendarView(activeView));
  };

  return (
    <div className={styles.container}>
      {isLoading && (
        <div
          style={{
            display: 'flex',
            position: 'fixed',
            boxSizing: 'border-box',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 5,
            width: '100%',
            height: '100vh',
            backdropFilter: 'blur(5px)',
            left: '0',
            top: '0',
          }}
        >
          <CircularProgress />
        </div>
      )}
      <Box
        sx={{
          borderBottom: 1,
          borderTop: 1,
          borderColor: 'divider',
          marginBottom: 4,
        }}
      >
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={() => {
            setIsLoading(true);
          }}
          onAnimationEnd={() => {
            setIsLoading(false);
          }}
        >
          <Tab
            onClick={() => {
              setValue(0);
            }}
            label={
              <Typography
                variant="h5"
                fontWeight="bold"
                style={{ color: 'black', textTransform: 'none' }}
              >
                {t('generic.calendar')}
              </Typography>
            }
          />
          <Tab
            onClick={() => {
              setValue(1);
            }}
            label={
              <Typography
                variant="h5"
                fontWeight="bold"
                style={{ color: 'black', textTransform: 'none' }}
              >
                {t('generic.list')}
              </Typography>
            }
          />
        </Tabs>
      </Box>
      <div className={styles.options}>
        <MultiSelectNotify
          name={t('generic.machineGroup')}
          options={filters.filters.machineGroups}
          selectedOptions={filters.selectedFilters.machineGroups}
          availableOptions={filters.activeFilters.machineGroups}
          toggleOption={(id) => dispatch(toggleGroup(id))}
          disabled={true}
        />
        <MultiSelectNotify
          name={t('generic.machine')}
          options={filters.filters.machines}
          selectedOptions={filters.selectedFilters.machines}
          availableOptions={filters.activeFilters.machines}
          toggleOption={(id) => dispatch(toggleMachine(id))}
          disabled={true}
        />
        <MultiSelectNotify
          name={t('generic.locations')}
          options={filters.filters.locations}
          selectedOptions={filters.selectedFilters.locations}
          availableOptions={filters.activeFilters.locations}
          toggleOption={(id) => dispatch(toggleLocation(id))}
          disabled={true}
        />
        <MultiSelectNotify
          name={t('generic.maintenanceType')}
          options={filters.filters.maintenanceTypes}
          selectedOptions={filters.selectedFilters.maintenanceTypes}
          availableOptions={filters.activeFilters.maintenanceTypes}
          toggleOption={(id) => dispatch(toggleType(id))}
          disabled={true}
          showReportRepair={showReportRepair}
          setShowReportRepair={setShowReportRepair}
          type={'calendar'}
        />
      </div>

      {value === 0 && (
        <CalendarGrid
          taskData={taskData}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          fetchData={fetchData}
        />
      )}
      {value === 1 && (
        <CalendarList
          taskData={taskData}
          setStartDate={setStartDate}
          startDate={startDate}
          setEndDate={setEndDate}
          endDate={endDate}
          fetchData={fetchData}
        />
      )}
    </div>
  );
}

export default Calendar;
