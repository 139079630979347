import { z } from 'zod';

export const postMaintenanceTaskSchema = (t) =>
  z.object({
    id: z.string().optional(),
    name: z
      .string()
      .min(1, { message: t('error.maintenanceTaskNameRequired') })
      .trim(),
    description: z.any().refine((descr) => !!descr, t('error.maintenanceTaskDescriptionRequired')),
    interval: z.object(
      {
        id: z.number(),
      },
      { message: t('error.maintenanceTaskIntervalRequired') }
    ),
    notificationLeadTime: z.number({ message: t('error.maintenanceTaskIntervalRequired') }),
    maintenanceType: z.object(
      {
        id: z.any(),
      },
      { message: t('error.maintenanceTypeRequired') }
    ),
  });
