import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

export const fetchMaintenanceTypes = createAsyncThunk(
  'maintenanceTypes/fetchMaintenanceTypes',
  async () => {
    try {
      const token = Cookies.get('token');
      const response = await fetch(process.env.REACT_APP_BASE_URL + 'maintenance-types', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();

      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const fetchMaintenanceType = createAsyncThunk(
  'maintenanceType/fetchMaintenanceType',
  async (id) => {
    try {
      const token = Cookies.get('token');
      const response = await fetch(process.env.REACT_APP_BASE_URL + `maintenance-types/${id}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();

      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

const maintenanceTypesSlice = createSlice({
  name: 'maintenanceTypes',
  initialState: {
    maintenanceTypes: [],
    isLoading: false,
    error: null,
  },
  reducers: {
    removeMaintenanceTypes: (state) => {
      state.maintenanceTypes = null;
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMaintenanceTypes.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchMaintenanceTypes.fulfilled, (state, action) => {
      state.isLoading = false;
      state.maintenanceTypes = action.payload;
      state.error = null;
    });
    builder.addCase(fetchMaintenanceTypes.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export const { removeMaintenanceTypes } = maintenanceTypesSlice.actions;

export default maintenanceTypesSlice.reducer;

export const selectMaintenanceTypes = (state) => state.maintenanceTypes.maintenanceTypes;
