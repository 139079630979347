import { z } from 'zod';
import { requiredFieldSchema } from './fields';

export const postValidationDocSchema = (t) =>
  z.object({
    name: requiredFieldSchema(t('error.validationDocumentNameRequired')),
    description: requiredFieldSchema(t('error.validationDocumentDescriptionRequired')),
    date: z.any().refine((date) => !!date, t('error.validationDocumentDateRequired')),
    file: z.any().refine((file) => !!file, t('error.validationDocumentFileRequired')),
  });
