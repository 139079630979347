export function requestSearch(
  searchedVal,
  data,
  setRowsData,
  searchFields,
  startDate,
  endDate,
  choosenModel
) {
  let filteredRows = [];

  const searchTerms = searchedVal
    .trim()
    .toLowerCase()
    .split(/\s+/)
    .filter((term) => term.length > 0);

  if (searchTerms.length === 0) {
    filteredRows = data;
  } else {
    for (const row of data) {
      let found = false;
      for (const term of searchTerms) {
        if (!searchFields || searchFields.length === 0) {
          if (
            Object.values(row).some(
              (value) => value && value.toString().toLowerCase().includes(term)
            )
          ) {
            found = true;
            break;
          }
        } else {
          for (const field of searchFields) {
            const value = row[field];
            if (value && value.toString().toLowerCase().includes(term)) {
              found = true;
              break;
            }
          }
          if (found) break;
        }
      }
      if (found) {
        filteredRows.push(row);
      }
    }
  }

  if (startDate || endDate) {
    filteredRows = filteredRows.filter((log) => {
      const logDate = new Date(log.created_at);
      const startDateObj = new Date(startDate);
      const endDateObj = new Date(endDate);
      return (
        (!startDate || logDate >= startDateObj) &&
        (!endDate || logDate <= endDateObj)
      );
    });
  }

  if (choosenModel) {
    filteredRows = filteredRows.filter((item) => item.model === choosenModel);
  }

  setRowsData(filteredRows);
}
