import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

export const fetchMaintenancesGroup = createAsyncThunk(
  'maintenancesGroup/fetchMaintenancesGroup',
  async (payload) => {
    try {
      const token = Cookies.get('token');
      const response = await fetch(
        process.env.REACT_APP_BASE_URL + `maintenances?machineGroup=${payload}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();

      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const fetchMaintenance = createAsyncThunk('maintenances/fetchMaintenance', async (id) => {
  try {
    const token = Cookies.get('token');
    const response = await fetch(process.env.REACT_APP_BASE_URL + `maintenances/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const fetchMaintenancesMonth = createAsyncThunk(
  'maintenances/fetchMaintenance',
  async ({ startDate, endDate }) => {
    try {
      const token = Cookies.get('token');
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}maintenances/task-by-interval?startDate=${startDate}&endDate=${endDate}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();

      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const deleteMaintenance = createAsyncThunk('maintenances/deleteMaintenance', async (id) => {
  try {
    const token = Cookies.get('token');
    await fetch(process.env.REACT_APP_BASE_URL + `maintenances/${id}/action-is-over`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
});

const maintenancesSlice = createSlice({
  name: 'maintenances',
  initialState: {
    maintenances: [],
    isLoading: false,
    error: null,
  },
  reducers: {
    removeMaintenances: (state) => {
      state.maintenances = null;
      state.isLoading = false;
      state.error = null;
    },
  },
});

export const { removeMaintenances } = maintenancesSlice.actions;

export default maintenancesSlice.reducer;

export const selectMaintenances = (state) => state.maintenances.maintenances;
